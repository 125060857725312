import DefaultConfig from './default-config.json';
import SprintRayConfig from './sprintray-config.json';
import VyneDentalConfig from './vyne-config.json';

const getConfig = () => {
  const { hostname } = window.location;

  switch (hostname) {
    case 'dev-pace.subscribili.com':
    case 'pace.subscribili.com':
      return SprintRayConfig;
    case 'pace.qa.subscribili.com':
    case 'staging-pace.subscribili.com':
    case 'app.qa.vynedentalplans.com':
    case 'staging-app.vynedentalplans.com':
    case 'app.vynedentalplans.com':
      return VyneDentalConfig;
    default:
      return DefaultConfig;
  }
};

export default getConfig;
