import React, { useContext } from 'react';
import Helmet from "react-helmet";
import { Context } from 'context';

const DocumentTitle = (props) => {
  const {
    state: { domainConfig: { favIcon, description, appleTouchIcon } }
  } = useContext(Context);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      <link rel="icon" type="image/png" href={favIcon} sizes="16x16" data-testid="layoutview-logo" />
      <meta name="description" content={description} />

      <link rel="apple-touch-icon" href={appleTouchIcon} />
    </Helmet>
  )
}

const LayoutView = {
  DocumentTitle
}

export default LayoutView;
