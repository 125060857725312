import React, { useState, useContext } from "react";
import ConfirmModal from "Views/Common/ConfirmModal";
import { GROUP_SERVICES } from "Services";
import { Context } from "context";
import { ADD_NOTIFICATION } from "context/actions";

const DeleteMaterialModal = (props) => {
  const {
    handleClose,
    group_id,
    pdfKey,
    onUpdate
  } = props;

  const [loading, setLoading] = useState(false);
  const { dispatch: globalDispatch } = useContext(Context);

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } }
    });

  const handleConfirmDelete = async () => {
    if(!pdfKey) return;

    setLoading(true);
    try {
      const payload = { key: pdfKey };
      const response = await GROUP_SERVICES.deleteMarketingMaterial(group_id, payload);

      if (response?.type === "success") {
        dispatchGlobalNotification('success', "Marketing material deleted successfully");
        handleClose();
        onUpdate();
      } else {
        throw response?.message || response?.error;
      }
    } catch(err) {
      dispatchGlobalNotification('Error', err || "Error in deleting marketing material");
    }
    setLoading(false);
  };

  return (
    <ConfirmModal
      isOpen={true}
      closeModal={handleClose}
      emitConfirmEvent={handleConfirmDelete}
      isLoading={loading}
      options={{
        title: `Delete Marketing Material`,
        description: <>Are you sure you want to delete the marketing material?</>,
        confirmText: "Confirm",
        cancelText: "Cancel"
      }}
    />
  );
}

export default DeleteMaterialModal;


