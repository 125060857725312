import React, { useState, useContext } from 'react';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { FileUploader } from 'react-drag-drop-files';

// Components
import { CommonDialog } from 'components';
import DragAndDropFiles from './DragAndDrop';
import FilePreview from './FilePreview';

// Context
import { Context } from 'context';
import { ADD_NOTIFICATION } from 'context/actions';

// Services
import { USER_SERVICES } from 'Services';

// Utils
import { getFileExtension } from 'utils';

const maxFileSize = 5;
const fileTypes = ['XLSX', 'XLS', 'CSV'];

const UploadUcrFeesSchedule = (props) => {
  const {
    isOpen,
    onClose,
    levelData: { partner_id, group_id, region_id, location_id } = {},
    onUpdate,
  } = props;

  const { dispatch: globalDispatch } = useContext(Context);

  const [feesSchedule, setFeesSchedule] = useState({
    category: 'ucr_fees_schedule',
    fileName: '',
    file: null,
    sizeError: false,
    typeError: false,
  });
  const [uploading, setUploading] = useState(false);
  
  const { category, fileName, file, sizeError, typeError } = feesSchedule;
  const isValid =
    Boolean(fileName) &&
    Boolean(category) &&
    Boolean(file) &&
    !sizeError &&
    !typeError;

  const handleFileChange = (document) => {
    const documentArr = document?.name.split('.');
    const documentType = documentArr[1].toUpperCase();
    const documentName = documentArr[0];
    const fileSize = (document?.size / 1024 / 1024).toFixed(3);

    if (fileTypes.includes(documentType) && fileSize <= maxFileSize)
      setFeesSchedule({
        ...feesSchedule,
        file: document,
        fileName: fileName || documentName,
        typeError: false,
        sizeError: false,
      });
  };

  const onReplaceFile = () => {
    setFeesSchedule({
      ...feesSchedule,
      file: null,
      typeError: false,
      sizeError: false,
    });

    setTimeout(() => {
      const inputElement = document.querySelectorAll(
        '[name="import-fees-schedule"]',
      )?.[0];
  
      inputElement?.click();
    }, 100)

  };

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } },
    });

  const clearUpload = () => {
    setFeesSchedule({
      category: 'ucr_fees_schedule',
      fileName: '',
      file: null,
      sizeError: false,
      typeError: false,
    });
  };

  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);

    try {
      const fdata = new FormData();
      const payload = {
        location_id,
        region_id,
        group_id,
        partner_id,
      };
      fdata.append('location', JSON.stringify(payload));
      const renamedFile = new File(
        [file],
        `${fileName}${getFileExtension(file?.name)}`,
        {
          type: file.type,
          lastModified: file.lastModified,
        },
      );
      fdata.append(category, renamedFile);

      const response = await USER_SERVICES.uploadFile(fdata, group_id);

      if (response?.type === 'success') {
        dispatchGlobalNotification('success', 'File uploaded successfully');
        clearUpload();
        onClose();
        onUpdate?.('Settings');
      } else {
        throw response?.message || response?.error;
      }
    } catch (error) {
      dispatchGlobalNotification(
        'error',
        error?.[`${fileName}${getFileExtension(file?.name)}`] ||
          'Failed to upload the file',
      );
    } finally {
      setUploading(false);
    }
  };

  return (
    <CommonDialog
      open={isOpen}
      dialogTitle="Add Fee Schedule"
      primaryBtnTxt="Upload"
      primaryButtonStyles={{ minWidth: 125 }}
      secondaryBtnTxt="Cancel"
      isLoading={uploading}
      disabledPrimaryButton={!isValid || uploading}
      handleClose={onClose}
      handlePrimaryButtonClick={handleUpload}
      handleSecondaryButtonClick={onClose}
      maxWidth="md"
    >
      <Grid container spacing={4}>
        <Grid item lg={6} md={6} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-Group-native-simple">
              Select Category
            </InputLabel>
            <Select
              id="pms-select-vendor"
              label="Select Category"
              name="category"
              value={category}
            >
              <MenuItem value="">Select Category</MenuItem>
              <MenuItem value="ucr_fees_schedule">UCR Fee Schedule</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <TextField
            fullWidth
            required={true}
            name="File Name"
            label="File Name"
            variant="outlined"
            value={fileName}
            onChange={(event) => {
              setFeesSchedule({
                ...feesSchedule,
                fileName: event?.target?.value.replace(/\./g, ''),
              });
            }}
          />
        </Grid>
        {!file && (
          <Grid item xs={12}>
            <FileUploader
              fileOrFiles={file}
              handleChange={handleFileChange}
              name="import-fees-schedule"
              types={fileTypes}
              maxSize={maxFileSize}
              minSize={0}
              onSizeError={() =>
                setFeesSchedule({ ...feesSchedule, sizeError: true })
              }
              onTypeError={() =>
                setFeesSchedule({ ...feesSchedule, typeError: true })
              }
            >
              <DragAndDropFiles
                fileTypes={fileTypes}
                maxFileSize={maxFileSize}
              />
              <Grid item xs={12}>
                {sizeError && (
                  <Typography className="color_error">
                    Upload Failed. Please upload a file less than {maxFileSize}
                    MB.
                  </Typography>
                )}

                {typeError && (
                  <Typography className="color_error">
                    Upload Failed. Please upload files of type XLXS or XLS.
                  </Typography>
                )}
              </Grid>
            </FileUploader>
          </Grid>
        )}
      </Grid>
      {file && (
        <FilePreview
          fileName={file?.name}
          size={file?.size}
          onReplaceFile={onReplaceFile}
        />
      )}
    </CommonDialog>
  );
};

UploadUcrFeesSchedule.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  levelData: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default UploadUcrFeesSchedule;
