import httpService from "../Api/http.service";

const fetchAllPartners = () =>
  httpService({
    url: `/partners`,
    method: "get",
  });

const createPartner = (payload) =>
  httpService({
    url: `/partner`,
    method: "post",
    data: payload,
  });

const deletePartner = (payload) => 
  httpService({
    url: `/partner`,
    method: "delete",
    data: payload,
  });

const partnerService = {
    createPartner,
    deletePartner,
    fetchAllPartners
};

export default partnerService;
