import React from "react";
import { Grid } from "@material-ui/core";

import { TextField } from "components";

const BankDetailsInfo =(props)=> {
  const { checkErrors, bankDetails, handleInputChange } = props;

  return (
    <Grid container spacing={3} alignItems="stretch" id="bank-details">
      <Grid item xs={12} sm={4}>
        <TextField
          required
          fullWidth
          name="accountNumber"
          value={bankDetails?.accountNumber}
          label="Account Number"
          onChange={(e) =>
            handleInputChange(e.target.value, e.target.name)
          }
          variant="outlined"
          error={checkErrors && !bankDetails?.accountNumber}
          helperText={
            checkErrors &&
            !bankDetails?.accountNumber &&
            "Account number is required"
          }
          inputProps={{
            'data-testid': 'account-number'
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          required
          fullWidth
          name="routingNumber"
          value={bankDetails?.routingNumber}
          label="Routing Number"
          onChange={(e) =>
            handleInputChange(e.target.value, e.target.name)
          }
          variant="outlined"
          error={checkErrors && !bankDetails?.routingNumber}
          helperText={
            checkErrors &&
            !bankDetails?.routingNumber &&
            "Routing number is required"
          }
          inputProps={{
            'data-testid': 'routing-number'
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          required
          fullWidth
          name="name"
          value={bankDetails?.accountHolderName}
          label="Name"
          onChange={(e) =>
            handleInputChange(e.target.value, 'accountHolderName')
          }
          variant="outlined"
          error={checkErrors && !bankDetails?.accountHolderName}
          helperText={
            checkErrors && !bankDetails?.accountHolderName && "Name is required"
          }
          inputProps={{
            'data-testid': 'account-holder-name'
          }}
        />
      </Grid>
    </Grid>
  );
}

export default BankDetailsInfo;
