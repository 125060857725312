// Authentication
const AUTHENTICATION_COMPLETE = "AUTHENTICATION_COMPLETE";
const LOGOUT = "LOGOUT";
const RESET_SITE_DATA = "RESET_SITE_DATA";
const FRESHCHAT_INIT = "FRESHCHAT_INIT";

// Menu
const MENU_CONFIG_UPDATE = "MENU_CONFIG_UPDATE";

// Dashboard
const DASHBOARD_VIEWS_UPDATED = "DASHBOARD_VIEWS_UPDATED";
const DASHBOARD_FILTER_UPDATED = "DASHBOARD_FILTER_UPDATED";

// Groups
const GROUPS_DATA_RECEIVED = "GROUPS_DATA_RECEIVED";

// Subscribers List Filters
const SUBSCRIBERS_VIEW_UPDATED = "SUBSCRIBERS_VIEW_UPDATED";
const SUBSCRIBERS_COMPLETE_UPDATED = "SUBSCRIBERS_COMPLETE_UPDATED";
const SUBSCRIBERS_FILTERS_UPDATED = "SUBSCRIBERS_FILTERS_UPDATED";
const SUBSCRIBERS_FILTERS_RESET = "SUBSCRIBERS_FILTERS_RESET";
const LEVEL_LIST_DATA_LOADING = "LEVEL_LIST_DATA_LOADING";
const LEVEL_LIST_DATA_UPDATED = "LEVEL_LIST_DATA_UPDATED";

// Subscribers List Filters
const PATIENTS_FILTERS_UPDATED = "PATIENTS_FILTERS_UPDATED";
const PATIENTS_FILTERS_RESET = "PATIENTS_FILTERS_RESET";

// Trends Report Filter
const TRENDS_FILTERS_UPDATED = "TRENDS_FILTERS_UPDATED";
const TRENDS_FILTERS_RESET = "TRENDS_FILTERS_RESET";
const TRENDS_FILTERS_STATUS_UPDATED = "TRENDS_FILTERS_STATUS_UPDATED";

// Global Notifications
const ADD_NOTIFICATION = "ADD_NOTIFICATION";
const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

// Theme
const THEME_UPDATED = "THEME_UPDATED";

// Domain Config
const DOMAIN_CONFIG_UPDATED = "DOMAIN_CONFIG_UPDATED";

// Payment Types
const UPDATE_PAYMENT_TYPES = "UPDATE_PAYMENT_TYPES";

// Payment for Subscriber and Invoice
const UPDATE_PAYMENT_DETAILS = "UPDATE_PAYMENT_DETAILS";
const UPDATE_PAYMENT_ID = "UPDATE_PAYMENT_ID";
const RESET_PAYMENT_DETAILS = "RESET_PAYMENT_DETAILS";

// Coupon code
const UPDATE_COUPON_CODE = "UPDATE_COUPON_CODE";
const RESET_COUPON_CODE = "RESET_COUPON_CODE";
const VALIDATING_COUPON_CODE = "VALIDATING_COUPON_CODE";
const SET_COUPON_CODE_DETAIL = "SET_COUPON_CODE_DETAIL";
const SET_COUPON_CODE_ERROR = "SET_COUPON_CODE_ERROR";

// Subscriber Terms
const CONDITIONS_APPLY_UPDATE = "CONDITIONS_APPLY_UPDATE";
const CONDITIONS_APPLY_RESET = "CONDITIONS_APPLY_RESET";

//Stripe onboard
const UPDATE_STRIPE_ONB_STATUS = "UPDATE_STRIPE_ONB_STATUS";

// Level onboard
const UPDATE_ONBOARD_LOCATION = "UPDATE_ONBOARD_LOCATION";
const REFRESH_ONBOARD_LOCATION = "REFRESH_ONBOARD_LOCATION";
const REFRESH_USER_DETAILS = "REFRESH_USER_DETAILS";
const ADD_ONBOARD_GROUP_PLANS = "ADD_ONBOARD_GROUP_PLANS";
const ADD_ONBOARD_LOCS_PLANS = "ADD_ONBOARD_LOCS_PLANS";
const RESET_ONBOARD_LOCS_PLANS = "RESET_ONBOARD_LOCS_PLANS";
const REFRESH_ONBOARD_LOCS_PLANS = "REFRESH_ONBOARD_LOCS_PLANS";
const ADD_ONBOARD_LOCS_PLAN_DISCOUNTS = "ADD_ONBOARD_LOCS_PLAN_DISCOUNTS";
const ADD_ONBOARD_DEFAULT_PLAN_DISCOUNTS = "ADD_ONBOARD_DEFAULT_PLAN_DISCOUNTS";
const SAVINGS_SUMMARY_UPDATED = "SAVINGS_SUMMARY_UPDATED";
const REFRESH_SAVINGS_SUMMARY = "REFRESH_SAVINGS_SUMMARY";
const SAVINGS_SUMMARY_LOADING = "SAVINGS_SUMMARY_LOADING";
const UPDATE_ONBOARD_AGREEMENT_VALUES = "UPDATE_ONBOARD_AGREEMENT_VALUES";
const REFRESH_ONBOARD_AGREEMENT_STATUS = "REFRESH_ONBOARD_AGREEMENT_STATUS";
const RESET_ONBOARD_VALUES = "RESET_ONBOARD_VALUES";

// EHR patient link
const OPEN_EHR_LINK_POPUP = "OPEN_EHR_LINK_POPUP";
const CLOSE_EHR_LINK_POPUP = "CLOSE_EHR_LINK_POPUP";

export {
  AUTHENTICATION_COMPLETE,
  LOGOUT,
  RESET_SITE_DATA,
  FRESHCHAT_INIT,
  MENU_CONFIG_UPDATE,
  DASHBOARD_VIEWS_UPDATED,
  DASHBOARD_FILTER_UPDATED,
  GROUPS_DATA_RECEIVED,
  SUBSCRIBERS_VIEW_UPDATED,
  SUBSCRIBERS_COMPLETE_UPDATED,
  SUBSCRIBERS_FILTERS_UPDATED,
  SUBSCRIBERS_FILTERS_RESET,
  LEVEL_LIST_DATA_LOADING,
  LEVEL_LIST_DATA_UPDATED,
  PATIENTS_FILTERS_UPDATED,
  PATIENTS_FILTERS_RESET,
  TRENDS_FILTERS_UPDATED,
  TRENDS_FILTERS_RESET,
  TRENDS_FILTERS_STATUS_UPDATED,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  THEME_UPDATED,
  DOMAIN_CONFIG_UPDATED,
  UPDATE_PAYMENT_TYPES,
  UPDATE_PAYMENT_DETAILS,
  UPDATE_PAYMENT_ID,
  RESET_PAYMENT_DETAILS,
  UPDATE_COUPON_CODE,
  RESET_COUPON_CODE,
  VALIDATING_COUPON_CODE,
  SET_COUPON_CODE_DETAIL,
  SET_COUPON_CODE_ERROR,
  CONDITIONS_APPLY_UPDATE,
  CONDITIONS_APPLY_RESET,

  UPDATE_STRIPE_ONB_STATUS,
  UPDATE_ONBOARD_LOCATION,
  REFRESH_ONBOARD_LOCATION,
  REFRESH_USER_DETAILS,
  ADD_ONBOARD_GROUP_PLANS,
  ADD_ONBOARD_LOCS_PLANS,
  RESET_ONBOARD_LOCS_PLANS,
  REFRESH_ONBOARD_LOCS_PLANS,
  ADD_ONBOARD_LOCS_PLAN_DISCOUNTS,
  ADD_ONBOARD_DEFAULT_PLAN_DISCOUNTS,
  SAVINGS_SUMMARY_UPDATED,
  REFRESH_SAVINGS_SUMMARY,
  SAVINGS_SUMMARY_LOADING,
  UPDATE_ONBOARD_AGREEMENT_VALUES,
  REFRESH_ONBOARD_AGREEMENT_STATUS,
  RESET_ONBOARD_VALUES,

  OPEN_EHR_LINK_POPUP,
  CLOSE_EHR_LINK_POPUP,
};
