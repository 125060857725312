import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { ReactComponent as PreviewPdf } from "assets/images/preview-pdf.svg";
import style from "./FilePreview.module.scss"

const FilePreview = (props) => {
  const {
    name,
    size,
    onRemove
  } = props;

  return (
    <Grid container spacing={0} className={style.FilePreviewSection}>
      <Grid item xs={8}>
        <Grid container >
          <Grid item xs={3}>
            <PreviewPdf />
          </Grid>
          <Grid item xs={9}>
            <Typography>
              { name.replace(".pdf", "") }
            </Typography>
            <Typography variant="subtitle1">
              { ((size / 1024) / 1024).toFixed(3)} MB
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} className={style.removeWrapper} >
        <Typography variant="body1" className={style.removeText} onClick={onRemove}>
          Remove
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FilePreview;