import React from "react";
import { Grid } from "@material-ui/core";
import { SearchTextField } from "components";

const SearchBarSection = (props) => {
  const {
    actionBtn,
    handleFilterClick,
    handleSearchInputChange,
  } = props;

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs>
        <SearchTextField
          placeholder={"Search"}
          handleFilterClick={handleFilterClick}
          handleSearchInputChange={handleSearchInputChange}
        />
      </Grid>

      {actionBtn && (
        <Grid item xs={12} sm={5} md={3} lg={2}>
          {actionBtn}
        </Grid>
      )}
    </Grid>
  );
};
export default SearchBarSection;
