import React from "react";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const ToggleButtons = ({
  options,
  ...props
}) => {
  return (
    <ToggleButtonGroup {...props}>
      {
        options?.map(({ title, value, disabled }, index) => (
          <ToggleButton
            key={index}
            value={value}
            aria-label={title}
            disabled={disabled}
          >
            {title}
          </ToggleButton>
        ))
      }
    </ToggleButtonGroup>
  );
};

export default ToggleButtons;