import React from "react";
import clsx from "clsx";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import styles from "./CardIcon.module.scss";

const CardIcon = (props) => {
  const {
    card_number,
    exp_month = "",
    exp_year,
    brand,
    bank_name,
    account_number,
    showTooltip = false,
    classes = "",
  } = props;

  const expMonth =
    exp_month.toString().length === 1 ? "0" + exp_month : exp_month;

  const renderCardContent = () => {
    if (brand && card_number)
      return (
        <>
          <Grid item>
            <img
              className={styles.cardImage}
              src={`/images/credit-card-icons/${brand}.svg`}
              alt={brand}
            />
          </Grid>

          <Grid item className="ml-10">
            <Typography variant="body2">
              **** **** **** {card_number}
            </Typography>

            <Typography variant="body2">
              Expires {expMonth}/{exp_year}
            </Typography>
          </Grid>
        </>
      );
    else if (bank_name && account_number) {
      return (
        <>
        <Grid item>
          <AccountBalanceIcon className={styles.bankImage} fontSize="large" />
        </Grid>
        <Grid item className={clsx(styles.achCard, 'ml-10')}>
          <Typography variant="body2">{bank_name}</Typography>

          <Typography variant="body2">
            **** **** **** {account_number}
          </Typography>
        </Grid>
        </>
      );
    }

    return "-";
  };

  if (!(card_number || bank_name)) return <></>;

  return (
    <Tooltip title="Default card on file" disableHoverListener={!showTooltip}>
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        className={clsx(styles.cardContainer, classes, "ml-10", {
          [styles.achContainer]: bank_name && account_number,
        })}
      >
        {renderCardContent()}
      </Grid>
    </Tooltip>
  );
};

export default CardIcon;
