import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import clsx from "clsx";
import styles from "./TransactionCard.module.scss";
import PropTypes from "prop-types";

const TransactionCard = (props) => {
  const { image, imageTxt, heading, value, change, changeTrend, positiveTrend = "up", isError = false } = props;
  return (
    <Paper
      className={clsx("widget-wrapper", "material-wrapper", "flexColumn")}
      elevation={0}
    >
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <img src={image} alt={imageTxt} className={styles.infoImg} />
        </Grid>
        <Grid item>
          <Typography variant="h6" component="h3" color="textPrimary">
            {heading}
          </Typography>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="h5" component="p" color="textPrimary" className={isError && 'color_error'}>
                {value}
              </Typography>
            </Grid>
            {
              (typeof (change) === 'number') &&
              <Grid item>
                <Typography
                  className={clsx(
                    styles.transactionTrend,
                    changeTrend === positiveTrend ? 'color_success' : 'color_error'
                  )}
                >
                  {
                    changeTrend === "up" ?
                      <ArrowUpwardIcon fontSize="inherit" />
                      :
                      <ArrowDownwardIcon fontSize="inherit" />
                  }
                  {change}%
                </Typography>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

TransactionCard.propTypes = {
  image: PropTypes.string.isRequired,
  imageTxt: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  change: PropTypes.number,
  changeTrend: PropTypes.string,
};

export default TransactionCard;
