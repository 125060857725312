import httpService from "../Api/http.service";

const { REACT_APP_API_BASE_URL } = process.env;
const getAppointmentBaseUrl = () => `${REACT_APP_API_BASE_URL}/apt`;
const getSubV2ApiBaseUrl = () => `${REACT_APP_API_BASE_URL}/subv2`;

const getUtilisation = (params) =>
  httpService({
    url:
      getAppointmentBaseUrl() + `/utilisation${params}`,
    method: "get",
  });

const createAppointment = (payload) =>
  httpService({
    url: getAppointmentBaseUrl() + `/appointment`,
    method: "post",
    data: payload,
  });

const getAppointments = (params) =>
  httpService({
    url: getAppointmentBaseUrl() + `/appointments?${params}`,
    method: "get",
  });

const updateAppointment = (appointment_id, payload) =>
  httpService({
    url: getAppointmentBaseUrl() + `/appointment/${appointment_id}`,
    method: "put",
    data: payload
  });

const getMonetaryData = (subscription_id) =>
  httpService({
    url: getSubV2ApiBaseUrl() + `/subscriptions/${subscription_id}/monetary`,
    method: "get",
  });

  const appointmentService = {
    getUtilisation,
    createAppointment,
    getAppointments,
    updateAppointment,
    getMonetaryData
  };

  export default appointmentService;
