import React from "react";
import _ from 'lodash';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  FormControlLabel,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { PhoneInput, TextField, CheckBox } from "components";

const ContactFields = (props) => {
  const {
    indContact = {},
    index,
    blurred,
    setBlurred,
    enableCommission,
    groupList = [],
    locationDetail,
    onContactDetailsChange,
    contactDeleteEvent,
  } = props;

  const {
    first_name = "",
    last_name = "",
    title = "",
    contact_email = "",
    phone = "",
    is_internal = true,
    errorCheck = {},
  } = indContact;

  const selectedGroup = groupList.find((group) => {
    return group.group_id === locationDetail.group_id;
  }) || {};

  const vet_only = selectedGroup?.industry === "Veterinary";

  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={12} md={4} lg={2}>
        <TextField
          required
          name="first_name"
          value={first_name}
          label="First Name"
          onChange={(e) =>
            onContactDetailsChange(e, index, "first_name", "")
          }
          variant="outlined"
          error={errorCheck["first_name"].error}
          helperText={`${errorCheck["first_name"].text}`}
          inputProps={{
            'data-testid': 'contact-fields-first-name'
          }}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={4} lg={2}>
        <TextField
          required
          name="last_name"
          value={last_name}
          label="Last Name"
          onChange={(e) =>
            onContactDetailsChange(e, index, "last_name", "")
          }
          variant="outlined"
          error={errorCheck["last_name"].error}
          helperText={`${errorCheck["last_name"].text}`}
          inputProps={{
            'data-testid': 'contact-fields-last-name'
          }}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={4} lg={vet_only ? 1 : 2}>
        <TextField
          required
          name="title"
          value={title}
          label="Title"
          onChange={(e) => onContactDetailsChange(e, index, "title", "")}
          variant="outlined"
          error={errorCheck["title"].error}
          helperText={`${errorCheck["title"].text}`}
          inputProps={{
            'data-testid': 'contact-fields-title'
          }}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={4} lg={2}>
        <TextField
          required
          name="contact_email"
          value={contact_email}
          label="Email"
          onChange={(e) => {
            let blurredObj = _.cloneDeep(blurred);
            blurredObj[index].email = false;
            setBlurred(blurredObj);

            onContactDetailsChange(e, index, "contact_email", "");
          }}
          onBlur={ () => {
            let blurredObj = _.cloneDeep(blurred);
            blurredObj[index].email = true;
            setBlurred(blurredObj);
          }}
          variant="outlined"
          error={blurred[index]?.email && errorCheck["contact_email"].error}
          helperText={blurred[index]?.email && `${errorCheck["contact_email"].text}`}
          inputProps={{
            'data-testid': 'contact-fields-email'
          }}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={4} lg={2}>
        <FormControl variant="outlined" fullWidth>
          <PhoneInput
            id="company-fields-phone-number"
            isValid={blurred[index]?.phone && errorCheck["phone"].error ? false : true}
            error={blurred[index]?.phone && errorCheck["phone"].error}
            defaultErrorMessage={
              errorCheck["phone"].error
                ? errorCheck["phone"].text
                : ""
            }
            value={phone}
            onChange={(e, country) => {
              let blurredObj = _.cloneDeep(blurred);
              blurredObj[index].phone = false;
              setBlurred(blurredObj);

              onContactDetailsChange(e, index, "phone", country);
            }}
            onBlur={ () => {
              let blurredObj = _.cloneDeep(blurred);
              blurredObj[index].phone = true;
              setBlurred(blurredObj);
            }}
            fullWidth
          />
          <FormHelperText className="color_error">
            {blurred[index]?.phone && errorCheck["phone"].text && errorCheck["phone"].error
              ? errorCheck["phone"].text
              : ""
            }
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4} lg={2} className="d-flex align-center justify-content-between">
        <FormControlLabel
          control={
            <CheckBox
              checked={!is_internal}
              name="is_internal"
              inputProps={{ "data-testid": `stripe-contact-checkbox` }}
              onChange={() =>
                onContactDetailsChange(!is_internal, index, "is_internal", "")
              }
            />
          }
          label="Stripe Contact"
        />

        {vet_only && (
          <FormControlLabel
            control={
              <CheckBox
                checked={enableCommission}
                name="enableCommission"
                inputProps={{ "data-testid": `enable-commission-checkbox` }}
              />
            }
            label="Enable Commission"
          />
        )}

        <IconButton
          className="theme_bg_color_orange mg_left_8"
          onClick={() => contactDeleteEvent(index)}
          data-testid="delete-contact-fields-button"
        >
          <DeleteIcon className="color_white" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ContactFields;
