import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import {
  CloudUpload as CloudUploadIcon,
  Close as CloseIcon,
  Save as SaveIcon,
} from "@material-ui/icons";

import { getFileExtension, getImageProperties } from 'utils';
import styles from "./ImageUploader.module.scss";
import { Context } from "context";
import { ADD_NOTIFICATION } from 'context/actions';

const ImageUploader = (props) => {
  const {
    primaryText,
    secondaryText,
    onFileUpload,
    keyName,
    placeholderImage,
    name,
    acceptedFormats = [],
    maxFileSize,
    showSaveButton = false,
    onSaveFile,
    isLoading,
    refreshData = false,
  } = props;

  const [uploadedFile, setUploadedFile] = useState();
  const { dispatch: globalDispatch } = useContext(Context);

  const setGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          severity,
          message
        }
      }
    });

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    try {
      const { width, height } = await getImageProperties(file);
      const fileExtension = getFileExtension(file.name);
      if (
        acceptedFormats.includes(fileExtension) &&
        file.size <= maxFileSize &&
        width === 200 &&
        height === 64
      ) {
        setUploadedFile(file);
        onFileUpload(file, keyName);
      } else {
        throw new Error(
          'Please upload a logo that meets the specified criteria before proceeding to the group creation',
        );
      }
    } catch (error) {
      setGlobalNotification('error', error);
    }
  };

  const removeImage = () => {
    setUploadedFile(null);
    onFileUpload(null, keyName);
  };

  const isPDF = Boolean(uploadedFile && (getFileExtension(uploadedFile.name) === '.pdf'));
  let imageSrc = isPDF ? "/images/pdf.svg" : '';

  if (!isPDF && uploadedFile)
    imageSrc = URL.createObjectURL(uploadedFile);

  useEffect(() => {
    refreshData && removeImage();
  }, [refreshData]);

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12} sm='auto'>
        <Paper
          variant="outlined"
          elevation={0}
          classes={{ root: styles.imageContainer }}
        >
          <img
            src={imageSrc || placeholderImage || "/images/upload.svg"}
            alt="Upload"
            className={styles.uploadImage}
          />

          {
            uploadedFile &&
            <IconButton
              color="primary"
              size="small"
              className={clsx(styles.closeBtn, 'contained')}
              onClick={removeImage}
              data-testid="remove-image-button"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        </Paper>
      </Grid>

      <Grid item xs={12} sm={8}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12}>
            <Typography variant="h5">{uploadedFile ? uploadedFile.name : primaryText}</Typography>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Typography variant="body1" color="textSecondary">
              {secondaryText}
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ paddingLeft: 0 }}>
            {!(uploadedFile && showSaveButton) && (
              <Button
                color="secondary"
                component="label"
                data-testid='upload-image'
                disabled={isLoading}
                style={{
                  textDecoration: 'underline',
                  padding: 0
                }}
              >
                Upload {name}
                <input
                  type="file"
                  hidden
                  onChange={handleUpload}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  accept={acceptedFormats}
                />
              </Button>
            )}

            {(uploadedFile && showSaveButton) && (
              <Button
                variant="contained"
                color="secondary"
                onClick={onSaveFile}
                disabled={isLoading}
                data-testid="save-uploaded-image"
                startIcon={
                  <SaveIcon disabled={isLoading} />
                }
                style={{
                  minWidth: 142
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    thickness={5}
                    size={25}
                    color="inherit"
                    className="button-spinner"
                    data-testid="image-uploader-button-spinner"
                  />
                ) : (
                  `Save ${name}`
                )}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ImageUploader;
