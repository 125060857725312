import React, { useContext, useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import FeedbackRoundedIcon from '@material-ui/icons/FeedbackRounded';
import parse from "html-react-parser";

import { Context } from 'context';
import { DELETE_NOTIFICATION } from 'context/actions';

const GlobalNotification = () => {
  const { state: { notifications = [] }, dispatch } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState({});

  const { severity, message } = notification;

  useEffect(() => {
    if (notifications.length) {
      let { severity, message } = notifications?.[0] || {};

      setNotification({ severity, message: String(message) });
      setOpen(true);
    }
  }, [notifications]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);

    dispatch({ type: DELETE_NOTIFICATION });
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={severity === 'success' ? 3000 : undefined}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        iconMapping={{
          success: <CheckCircleRoundedIcon />,
          error: <FeedbackRoundedIcon />
        }}
        severity={severity}
        elevation={6}
        variant="outlined"
      >
        {parse(message || '')}

        {
          severity === 'error' && <span className="border-bottom"></span>
        }
      </Alert>
    </Snackbar>
  )
}

export default GlobalNotification;
