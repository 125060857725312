import httpService from '../Api/http.service';
import { LEVELS } from 'global-constants/constants';

const { REACT_APP_API_BASE_URL } = process.env;
const getManagementBaseUrl = () => `${REACT_APP_API_BASE_URL}/mgt`;

const fetchPartnerUsers = (partner_id, params) => {
  const qParams = params ? `?${params}` : '';
  const url = `/users/${partner_id}${qParams}`;

  return httpService({
    url,
    method: 'get',
  });
};

const fetchAllUsers = (level, partner_id, level_id, role) => {
  let url;
  if (partner_id && level === LEVELS.PARTNER) {
    url = `/users/${partner_id}`;
  } else if (partner_id && level === 'revshare') {
    url = `/users/revshare`;
  } else if (partner_id && level && level_id) {
    url = `/users/${partner_id}?${level + '_id'}=${level_id}`;
  } else if (role) {
    url = `/users/subscribili?role=${role}`;
  } else {
    url = `/users/subscribili`;
  }

  return httpService({
    url,
    method: 'get',
  });
};

const createUser = (payload) =>
  httpService({
    url: `/user`,
    method: 'post',
    data: payload,
  });

const deleteUser = (payload) =>
  httpService({
    url: `/user`,
    method: 'delete',
    data: payload,
  });

const updateUser = (payload) =>
  httpService({
    url: `/user`,
    method: 'put',
    data: payload,
  });

const getUser = () =>
  httpService({
    url: `/user`,
    method: 'get',
  });

const fetchUsers = () =>
  httpService({
    url: `/users`,
    method: 'get',
  });

const resetPassword = (payload) =>
  httpService({
    url: getManagementBaseUrl() + `/set-user-password`,
    method: 'post',
    data: payload,
  });

const resetDefaultPassword = (username = '') => {
  const payload = {
    username,
  };

  return httpService({
    url: getManagementBaseUrl() + `/login/reset`,
    method: 'put',
    data: payload,
  });
};

const getMenuInfo = () =>
  httpService({
    url: `/settings/menu`,
    method: 'get',
  });

const getDocumentList = (level, levelId) =>
  httpService({
    url: `${getManagementBaseUrl()}/${level}/${levelId}/supported-documents`,
    method: 'get',
  });

const getSettings = (settingLevel, levelId, params) => {
  let api = params
    ? `/settings/${settingLevel}/${levelId}?${params}`
    : `/settings/${settingLevel}/${levelId}`;

  return httpService({
    url: api,
    method: 'get',
  });
};

const getRoles = () =>
  httpService({
    url: '/settings/roles',
    method: 'get',
  });

const getAWSAccessURL = (key) =>
  httpService({
    url: getManagementBaseUrl() + `/get_access_url?key=` + key,
    method: 'get',
  });

const getAWSAccessURLs = (keys) =>
  httpService({
    url: getManagementBaseUrl() + '/get_access_urls',
    method: 'post',
    data: { keys },
  });

const getAccountLink = (params = '') =>
  httpService({
    url: getManagementBaseUrl() + `/get-account-link${params}`,
    method: 'get',
  });

const updateLevels = (payload, level, formData = false) => {
  const levelName = level === 'revshare' ? 'revshares' : level;
  const serviceData = {
    url: `/${levelName}`,
    method: 'put',
    data: payload,
  };
  formData && (serviceData.headers = { 'Content-Type': 'multipart/form-data' });

  return httpService(serviceData);
};

const accountSetup = (payload, type, id, method = 'post') =>
  httpService({
    url: `/${type}/${id}/setup`,
    method,
    data: payload,
  });

const uploadFile = (payload, group_id) => {
  const serviceData = {
    url: `/group/${group_id}/file`,
    method: 'put',
    data: payload,
  };
  serviceData.headers = { 'Content-Type': 'multipart/form-data' };

  return httpService(serviceData);
};

const deleteFile = (group_id, file_name, payload = {}) => {
  file_name = file_name.replace(/_/g, '-');
  const serviceData = {
    url: `/groups/${group_id}/files/${file_name}`,
    method: 'delete',
    data: payload,
  };
  return httpService(serviceData);
};

const getFileFromStripe = (fileId) => {
  const serviceData = {
    url: `/file/${fileId}`,
    method: 'get',
  };

  return httpService(serviceData);
};

const uploadFileToStripe = (payload) => {
  const serviceData = {
    url: `/file`,
    method: 'post',
    data: payload,
  };
  serviceData.headers = { 'Content-Type': 'multipart/form-data' };

  return httpService(serviceData);
};

const getManagementUrl = (path, queryParameters = null) => {
  let queryString = '';

  if (queryParameters && typeof queryParameters === 'object') {
    for (const [key, value] of Object.entries(queryParameters)) {
      queryString += `${queryString ? '&' : '?'}${key}=${value}`;
    }
  }
  return `${getManagementBaseUrl()}${path}${queryString}`;
};

const getTemplate = (path, queryParameters = null) =>
  httpService({
    url: getManagementUrl(path, queryParameters),
    method: 'get',
    headers: {
      Accept: 'application/ms-excel',
    },
    responseType: 'arraybuffer',
  });

const getPaymentTypes = (params = '') =>
  httpService({
    url: getManagementBaseUrl() + `/payment-types`,
    method: 'get',
  });

const getProcedureCategories = (level = '', levelId = '', params = '') =>
  httpService({
    url: getManagementBaseUrl() + `/${level}/${levelId}/fees-summary${params}`,
    method: 'get',
  });

const postProcedureCategories = (
  level = '',
  levelId = '',
  params = '',
  payload = {},
) =>
  httpService({
    url: getManagementBaseUrl() + `/${level}/${levelId}/fees-summary${params}`,
    method: 'post',
    data: payload,
  });

const getUserFeatures = () =>
  httpService({
    url: `/users/features`,
    method: 'get',
  });

const userService = {
  createUser,
  deleteUser,
  updateUser,
  getUser,
  fetchPartnerUsers,
  fetchAllUsers,
  fetchUsers,
  getRoles,
  resetPassword,
  resetDefaultPassword,
  getMenuInfo,
  getDocumentList,
  getSettings,
  getAWSAccessURL,
  getAWSAccessURLs,
  getAccountLink,
  updateLevels,
  accountSetup,
  uploadFile,
  deleteFile,
  getFileFromStripe,
  uploadFileToStripe,
  getPaymentTypes,
  getTemplate,
  getProcedureCategories,
  postProcedureCategories,
  getUserFeatures,
};

export default userService;
