import httpService from "../Api/http.service";
import Utils from "Shared/Utils";

const { REACT_APP_API_BASE_URL } = process.env;
const getPmsBaseUrl = () => `${REACT_APP_API_BASE_URL}/pms`;

const findPmsPatientDetails = (group_id, location_id, patient_id) => 
  httpService({
    url: getPmsBaseUrl() + `/patient/find?group_id=${group_id}&location_id=${location_id}&patient_id=${patient_id}`,
    method: "get",
  });

const postPmsPatientDetails = (payload) => 
  httpService({
    url: getPmsBaseUrl() + '/patient/find',
    method: "post",
    data: payload
  });

const getPmsPatientDetails = (group_id, location_id, pms_id, patient_id) => {
  const queryParams = Utils.getQueryString({
    group_id,
    location_id,
    pms_id,
    patient_id,
  });
  let endpoint = `/patient?${queryParams}`

  return httpService({
    url: getPmsBaseUrl() + endpoint,
    method: "get",
  });
};

const getPmsPatSubscriptions = (location_id, pms_id, pms_display_id) => {
  const queryStringParams = Utils.getQueryString({
    location_id,
    pms_id,
    pms_display_id,
  });
  let endpoint = `/patients/subscriptions?${queryStringParams}`

  return httpService({
    url: getPmsBaseUrl() + endpoint,
    method: "get",
  });
};

const createPmsDetails = (payload) => 
  httpService({
    url: getPmsBaseUrl() + `/patient`,
    method: "post",
    data: payload
  });

const linkPmsDetails = (payload) => 
  httpService({
    url: getPmsBaseUrl() + `/patient`,
    method: "put",
    data: payload
  });

const unlinkPms = (patientId) => 
  httpService({
    url: getPmsBaseUrl() + `/patients/${patientId}/unlink`,
    method: "put",
  });

const getVendorsList = () =>
  httpService({
    url: getPmsBaseUrl() + `/vendors`,
    method: "get"
  });

const getConsentVendorsList = () =>
  httpService({
    url: getPmsBaseUrl() + `/patients/form-vendors`,
    method: "get"
  });

const validatePms = (payload) =>
  httpService({
    url: getPmsBaseUrl() + `/validate`,
    method: "put",
    data: payload
  });

const getAppointmentServices = (locationId, params) =>
  httpService({
    url: getPmsBaseUrl() + `/location/${locationId}/service?${params}`,
    method: "get",
  });

const getAppointmentSlots = (locationId, params) =>
  httpService({
    url: getPmsBaseUrl() + `/location/${locationId}/slot?${params}`,
    method: "get",
  });

const confirmAppointment = (patientId, data) =>
  httpService({
    url: getPmsBaseUrl() + `/patient/${patientId}/appointment`,
    method: "post",
    data
  });

const getPmsAppointmentDetails = (params) => 
  httpService({
    url: getPmsBaseUrl() + `/patients/appointments?${params}`,
    method: "get",
  });

const getUpcomingAppointments = (patientId) =>
  httpService({
    url: getPmsBaseUrl() + `/patients/${patientId}/appointments`,
    method: "get",
  });

const syncPlanPackage = (planPackageId, partnerId, level, levelId) => {
  const params = Utils.getQueryString({
    partner_id: partnerId,
    level: level,
    level_id: levelId,
  });
  
  return httpService({
    url: getPmsBaseUrl() + `/plan-package/${planPackageId}/discount-plans?${params}`,
    method: "post"
  });
};

const getTreatmentPlans = (location_id, pms_id) => {
  const queryParams = Utils.getQueryString({
    location_id,
    pms_id,
  });
  let endpoint = `/patients/treatment-plans?${queryParams}`

  return httpService({
    url: getPmsBaseUrl() + endpoint,
    method: "get",
  });
};

const getTreatmentProcedures = (payload) => {
  let endpoint = '/patients/treatment-plans-procedures'

  return httpService({
    url: getPmsBaseUrl() + endpoint,
    method: "put",
    data: payload
  });
};

const pmsService = {
  findPmsPatientDetails,
  postPmsPatientDetails,
  getPmsPatientDetails,
  getPmsPatSubscriptions,
  createPmsDetails,
  linkPmsDetails,
  getVendorsList,
  getConsentVendorsList,
  validatePms,
  getAppointmentServices,
  getAppointmentSlots,
  confirmAppointment,
  getPmsAppointmentDetails,
  getUpcomingAppointments,
  syncPlanPackage,
  unlinkPms,
  getTreatmentPlans,
  getTreatmentProcedures
};

export default pmsService;
