import Paper from "@material-ui/core/Paper";
import SectionHeader from "components/SectionHeader/SectionHeader";
import React from "react";
import { Typography } from "@material-ui/core";
import styles from "./SectionWrapper.module.scss";
import PropTypes from "prop-types";

const SectionWrapper = (props) => {
  const { title, rightSection, children, subTitle, chipStatus = '', chipLabel = '' } = props;
  return (
    <Paper elevation={0} className={styles.settings_wrapper_container}>
      {title && (
        <div className={styles.settings_wrapper_header_container}>
          <SectionHeader
            title={title}
            chipStatus={chipStatus}
            chipLabel={chipLabel}
            titleVariant="h5"
            titleComponent="h5"
            rightSection={rightSection}
          />
          {Boolean(subTitle) && <Typography variant="body1">{subTitle}</Typography>}
        </div>
      )}
      <div className={styles.setting_wrapper_main_container}>{children}</div>
    </Paper>
  );
};

SectionWrapper.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  rightSection: PropTypes.node,
  children:PropTypes.node,
  chipStatus: PropTypes.string,
  chipLabel: PropTypes.string
};

export default SectionWrapper;
