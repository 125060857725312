import React, { useContext } from 'react';
import { Context } from 'context';

const RoleValidator = ({ children, roles }) => {
  const { state: { user } } = useContext(Context);

  const isContentValid =
    roles.includes("all") ||
    roles?.some((role) => user["custom:role"].includes(role.key));

  return (
    <>
      {
        isContentValid && <>
          {children}
        </>
      }
    </>
  );
}

export default RoleValidator;