import React, { useState, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import TextField from "components/Textfield/TextField";
import CommonDialog from "components/CommonDialog/CommonDialog";
import { USER_SERVICES } from "Services";
import CommonLoader from "Views/Common/CommonLoader";
import { Context } from "context";
import { ADD_NOTIFICATION } from "context/actions";

const ResetPasswordModal = (props) => {
  const { handleClose, user, resetModalOpen } = props;
  const [password, setPassword] = useState("");
  const [checkErrors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { dispatch: globalDispatch } = useContext(Context);

  const checkErrorsInForm = () => {
    let regex = new RegExp("/^[ A-Za-z0-9_@./#&+-]*$/");
    setErrors(true);

    if (!password) {
      setError("Please enter a password.");
      return true;
    }

    if (password.length < 8 && !regex.exec(password)) {
      setError(
        "Password must be 8 characters long. It must have atleast 1 Upper-case alphabet,1 Lower-case alphabet and 1 special character."
      );
      return true;
    }

    return false;
  };

  const handleResetClick = async () => {
    setLoading(true);
    if (checkErrorsInForm()) {
      setLoading(false);
      return;
    }

    try {
      let data = {
        username: user.cognito_username,
        password: password,
      };
      await USER_SERVICES.resetPassword(data);
      setLoading(false);
      setErrors(false);
      handleClose();
    } catch (e) {
      console.error(e);
      setLoading(false);
      setErrors(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  if (loading)
    return <CommonLoader />;

  return (
    <CommonDialog
      open={resetModalOpen}
      handleClose={handleClose}
      primaryBtnTxt="Reset"
      secondaryBtnTxt="Cancel"
      dialogTitle="Reset Password"
      handlePrimaryButtonClick={handleResetClick}
      maxWidth='sm'
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Reset password for {user && user.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            color='secondary'
            variant="outlined"
            autoFocus
            margin="dense"
            id="password"
            label="Reset Password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            error={checkErrors && !password}
            inputProps={{
              'data-testid': 'reset-password'
            }}
          />
        </Grid>
      </Grid>

      {error && (
        globalDispatch({
          type: ADD_NOTIFICATION,
          payload: { error }
        })
      )}
    </CommonDialog>
  );
}

export default ResetPasswordModal;
