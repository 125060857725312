import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";

import CommonDialog from "components/CommonDialog";
import { ContactDetailsForm } from "components";
import { USER_SERVICES } from "Services";
import { Context } from "context";
import { ADD_NOTIFICATION } from "context/actions";
import ConfirmModal from "Views/Common/ConfirmModal";
import Utils from "Shared/Utils";
import UserUtils from "Shared/UsersUtil";

const ContactDetailsModal = (props) => {
  const {
    type,
    closePopup,
    data,
    settingsData,
    levelData,
    memberId,
    onUpdate,
  } = props;

  const { partner_id, group_id, region_id, location_id } = levelData;
  const { setting_level: level } = settingsData;
  
  const { dispatch: globalDispatch } = useContext(Context);

  const [contactDetails, setContactDetails] = useState({});
  const [checkErrors, setCheckErrors] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [blurred, setBlurred] = useState({});

  const handleInputChange = (value, attribute) => {
    const clonedDetails = _.cloneDeep(contactDetails);
    clonedDetails[attribute] = value;

    setContactDetails(clonedDetails);

    setEditDetails(true);
  };

  const checkErrorsInForm = async () => {
    const validEmail = Utils.validateEmail(contactDetails?.contact_email);
    const validPhone = Utils.validateMobile(contactDetails?.phone);

    if (
      !contactDetails.first_name ||
      !contactDetails.last_name ||
      !contactDetails.title ||
      !validEmail ||
      !validPhone
    ) {
      setCheckErrors(true);
      return true;
    }

    setCheckErrors(false);
    return false;
  };

  const onConfirmDelete = async () => {
    setLoading(true);
    try {
      let updatedMembers = _.cloneDeep(data);
      updatedMembers.splice(memberId, 1);

      updatedMembers.length && updatedMembers.forEach((member) => {
        delete member?.date_of_birth;
        delete member?.ssn_last_4;
        delete member?.is_ssn_provided;
        delete member?.verification_document;
      });

      const payload = {
        [level]: {
          location_id,
          region_id,
          group_id,
          partner_id,
          contact_members: updatedMembers,
        },
      };

      const response = await USER_SERVICES.updateLevels(payload, level);

      if (response?.type === "success") {
        dispatchGlobalNotification("success", "Member removed successfully");
        closePopup();
        onUpdate("Settings");
      } else {
        throw response?.message || response?.error;
      }
    } catch (error) {
      dispatchGlobalNotification(
        "error",
        error || "Error removing the member details"
      );
    }
    setLoading(false);
  };

  const onConfirmClick = async () => {
    if (!editDetails) {
      closePopup();
      return;
    }

    setBlurred({
      ...blurred,
      email: true,
      phone: true
    });

    let hasErrors = await checkErrorsInForm();
    if (hasErrors) return;

    setLoading(true);
    try {
      let updatedMembers = _.cloneDeep(data);
      if (type === "add") {
        updatedMembers.push(contactDetails);
      } else if (type === "edit") {
        updatedMembers[memberId] = contactDetails;
      }

      updatedMembers.length && updatedMembers.forEach((member) => {
        !member.last_name && (member.last_name = '');
        delete member?.date_of_birth;
        delete member?.ssn_last_4;
        delete member?.is_ssn_provided;
        delete member?.verification_document;
      });

      const payload = {
        [level]: {
          location_id,
          region_id,
          group_id,
          partner_id,
          contact_members: updatedMembers,
        },
      };

      const response = await USER_SERVICES.updateLevels(payload, level);

      if (response?.type === "success") {
        const successMsg =
          type === "add"
            ? "Member added successfully"
            : "Member updated successfully";
        dispatchGlobalNotification("success", successMsg);
        closePopup();
        onUpdate("Settings");
      } else {
        throw response?.message || response?.error;
      }
    } catch (error) {
      dispatchGlobalNotification(
        "error",
        error || "Error updating the member details"
      );
    }
    setLoading(false);
  };

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } },
    });

  useEffect(() => {
    if (type === "add") {
      setContactDetails(UserUtils.getContactKeys());
    } else if (type === "edit") {
      setContactDetails(data[memberId]);
    }
  }, [type]);

  return (
    <>
      <ConfirmModal
        isOpen={Boolean(type === "delete")}
        closeModal={closePopup}
        emitConfirmEvent={onConfirmDelete}
        isLoading={isLoading}
        options={{
          title: `Delete member`,
          description: <>Are you sure you want to delete the member?</>,
          confirmText: "Confirm",
          cancelText: "Cancel",
        }}
      />

      {Boolean(type === "add" || type === "edit") && (
        <CommonDialog
          open={Boolean(type === "add" || type === "edit")}
          handleClose={closePopup}
          dialogTitle={type === "add" ? "Add member" : "Edit member"}
          primaryBtnTxt="Confirm"
          handlePrimaryButtonClick={onConfirmClick}
          disabledPrimaryButton={isLoading}
          primaryButtonStyles={{ minWidth: 105 }}
          isLoading={isLoading}
          secondaryBtnTxt="Cancel"
          disableSecondaryButton={isLoading}
        >
          <ContactDetailsForm
            {...contactDetails}
            checkErrors={checkErrors}
            handleTextInputChange={handleInputChange}
            blurred={blurred}
            setBlurred={setBlurred}
            showStripeCheckBox={
              type === "add" ||
              (type === "edit" && !contactDetails?.is_representative)
            }
          />
        </CommonDialog>
      )}
    </>
  );
};

export default ContactDetailsModal;
