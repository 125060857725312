import Utils from "Shared/Utils";

export const requiredFieldMessage = (value, fieldName) =>
  !value ? `${fieldName} is required` : "";

export const phoneValidationMessage = (phoneNumber) => {
  if (!phoneNumber) return "Phone is required";

  if (!Utils.validateMobile(phoneNumber)) return "Phone must be of length 10";

  return "";
};

export const emailValidationMessage = (email) => {
  if (!email) return "Email is required";

  if (!Utils.validateEmail(email)) return "Enter Valid Email Address";

  return "";
};

export const zipcodeValidationMessage = (zipcode) => {
  if (!zipcode) return "Zipcode is required";

  if (!Utils.validateZipCode(zipcode)) return "Enter Valid Zipcode";

  return "";
};

export const websiteValidationMessage = (website, name = 'Website') => {
  if (!website)
    return `${name} is required`;

  if (!Utils.validateWebsite(website))
    return "Enter Valid Website";

  return "";
};

export const ssnValidationMessage = (ssn_last_4) => {
  if (!ssn_last_4 || !Utils.validateFourDigitSSN(ssn_last_4)) {
    return "Last 4 digits of SSN is required";
  }
  return "";
};

export const npiValidationMessage = (value) => {
  if (!value)
    return "Billing NPI is required";

  if (!Utils.validateNPI(value))
    return "Enter Valid NPI number"

  return "";
};

export const taxIdValidationMessage = (value) => {
  if (!value)
    return "Tax ID is required";

  if (!Utils.validateTaxId(value))
    return "Enter Valid Tax ID"

  return "";
};

export const facilityIdValidationMessage = (value) => {
  if (!value)
    return "Facility Id is required";

  if (!Utils.validateFacilityId(value))
    return "Enter Valid Facility Id"

  return "";
};
