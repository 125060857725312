import React, { createContext, useReducer } from "react";
import { reducer, initialState } from "./subscriberReducer";
import PropTypes from "prop-types";

export const SubscriberContext = createContext();

const SubscriberProvider = ({ children, ...other }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch, ...other };

  return (
    <SubscriberContext.Provider value={value}>
      {children}
    </SubscriberContext.Provider>
  );
};

SubscriberContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubscriberProvider;
