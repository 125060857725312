import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const ToggleTickSwitch = withStyles((theme) => ({
  root: {
    width: 56,
    height: 40,
    padding: 10,
  },
  switchBase: {
    padding: 12,
    color: '#ff6a00',
  },
  thumb: {
    width: 16,
    height: 16,
    backgroundColor: '#fff',
  },
  track: {
    background: `linear-gradient(to right, var(--theme-text-disabled), var(--theme-text-disabled))`,
    opacity: '1 !important',
    borderRadius: 20,
    position: 'relative',
  },
  checked: {
    '&$switchBase': {
      color: '#185a9d',
      transform: 'translateX(16px)',
      '&:hover': {
        backgroundColor: 'rgba(24,90,257,0.08)',
      },
    },
    '& $thumb': {
      backgroundColor: '#fff',
    },
    '& + $track': {
      background: 'linear-gradient(to right, var(--theme-highlight-color), var(--theme-highlight-color-2))',

      '&:before': {
        opacity: 1,
      },
      '&:after': {
        opacity: 0,
      }
    },
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default ToggleTickSwitch