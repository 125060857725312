import React, { useContext } from "react";
import { CircularProgress } from "@material-ui/core";

import { Context } from "context";

const CommonLoader = () => {
  const {
    state: { domainConfig },
  } = useContext(Context);
  const { themeProvider = "", loaderSrc = "/images/loader.gif" } = domainConfig;

  const getLoaderImage = () => {
    if (themeProvider === "Vyne") return <CircularProgress />;

    return <img src={loaderSrc} alt="loader" />;
  };

  return <div className="subs_loader">{getLoaderImage()}</div>;
};

export default CommonLoader;
