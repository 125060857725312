import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";
import {
  Grid,
  Paper
} from '@material-ui/core';

import wpServices from 'Services/wpService';
import CommonLoader from 'Views/Common/CommonLoader';
import clsx from 'clsx';
import LayoutView from "Views/Common/LayoutView";
import { SectionHeader } from 'components';

const TermsConditions = () => {
  const [termsContent, setContent] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getTermsConditions = async () => {
      const response = await wpServices.getTermsConditions();
      setContent(response.data);
      setLoading(false);
    };

    getTermsConditions();
  }, []);

  return (
    <>
      <LayoutView.DocumentTitle title={"Terms of Service"} />


      <SectionHeader
        title="Terms of Service"
        titleVariant="h4"
        titleComponent="h4"
        rightSection={
          <Paper className={clsx('widget-wrapper mg_top_32 ')} elevation={0}>

            {
              isLoading ?
                <CommonLoader />
                :
                <Grid item xs={12}>
                  {
                    parse(termsContent?.content?.rendered || '')
                  }

                </Grid>
            }

          </Paper>

        }
      >


      </SectionHeader>
    </>
  )
};

export default TermsConditions;
