import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    margin: '0 5px'
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(12px)',
      '& + $track': {
        opacity: 1,
      },
      '&$disabled + $track': {
        opacity: 0.1,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    color: theme.palette.secondary.contrastText,
  },
  track: {
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme.palette.secondary.main,
  },
  checked: {},
  disabled: {},
}))(Switch);

export default CustomSwitch;
