import httpService from "../Api/http.service";

const { REACT_APP_API_BASE_URL } = process.env;
export const getSubApiBaseUrl = () => `${REACT_APP_API_BASE_URL}/sub`;

const addDependentDetails = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriber/dependent`,
    method: "post",
    data: payload
  });

const updateDependentDetails = (patient_id, payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriber/dependent/${patient_id}`,
    method: "put",
    data: payload
  });

const deleteDependentDetails = (patient_id) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriber/dependent/${patient_id}`,
    method: "delete"
  });

const getDependentCheckoutDetails = (payload) => 
  httpService({
    url: getSubApiBaseUrl() + `/subscriber/dependent/checkout`,
    method: "put",
    data: payload
  });

const addMember = (payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriptions/members`,
    method: "post",
    data: payload
  });

const deleteMember = (patient_id, payload) =>
  httpService({
    url: getSubApiBaseUrl() + `/subscriptions/members/${patient_id}`,
    method: "delete",
    data: payload
  });


  const dependentService = {
    addDependentDetails,
    updateDependentDetails,
    deleteDependentDetails,
    getDependentCheckoutDetails,
    addMember,
    deleteMember
  };
  
  export default dependentService;
