import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";
import wpServices from 'Services/wpService';
import CommonLoader from 'Views/Common/CommonLoader';
import LayoutView from "Views/Common/LayoutView";
import { SectionHeader } from 'components';
import {
  Grid,
  Paper
} from '@material-ui/core';
import clsx from 'clsx';
const HipaaNotice = () => {
  const [hipaaContent, setContent] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getHipaaNotice = async () => {
      const response = await wpServices.getHipaaNotice();

      setContent(response.data);
      setLoading(false);
    };

    getHipaaNotice();
  }, []);

  return (
    <>
    <LayoutView.DocumentTitle title={"HIPAA Privacy Statement"} />

    
    <SectionHeader
      title="HIPAA Privacy Statement"
      titleVariant="h4"
      titleComponent="h4"
      rightSection={
        <Paper className={clsx('widget-wrapper mg_top_32 ')} elevation={0}>

          {
            isLoading ?
              <CommonLoader />
              :
              <Grid item xs={12}>
                {
                  parse(hipaaContent?.content?.rendered || '')
                }

              </Grid>
          }

        </Paper>

      }
    >


    </SectionHeader>
  </>
  );
};

export default HipaaNotice;
