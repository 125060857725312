import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  AccordionDetails,
  AccordionSummary,
  Divider,
  Fab,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { Heading } from "components";
import style from "./AccordionWrapper.module.scss";

const AccordionWrapper = ({
  children,
  collapseEnabled,
  expanded,
  info,
  onChange,
  subTitle,
  title,
  titleClass = '',
  type,
  showDivider = true,
  showArrow = true,
  ...rest
}) => {

  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <Accordion
      expanded={isExpanded}
      classes={{
        root: style.accordionWrapperRoot,
      }}
      elevation={0}
      onChange={() => collapseEnabled && onChange(type)}
      {...rest}
    >
      <AccordionSummary
        expandIcon={collapseEnabled && showArrow ?
          <Fab
            size="small"
            classes={{
              root: style.fabIcon,
            }}
          >
            <KeyboardArrowDownIcon classes={{ root: style.iconColor }} />
          </Fab> : false
        }
      >
        <Heading
          title={title}
          titleClass={titleClass}
          info={info}
          subTitle={subTitle}
        />
      </AccordionSummary>
      {showDivider && (
        <Divider />
      )}
      <AccordionDetails classes={{ root: style.accordionDetailsContainer }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

AccordionWrapper.propTypes = {
  children: PropTypes.element,
  collapseEnabled: PropTypes.bool,
  expanded: PropTypes.bool,
  info: PropTypes.string,
  onChange: PropTypes.func,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  titleClass: PropTypes.string,
  type: PropTypes.string,
  showDivider: PropTypes.bool,
  showArrow: PropTypes.bool,
  rest: PropTypes.object,
};

export default AccordionWrapper;
