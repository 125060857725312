import React, { useState, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/react";

// Components
import CommonDialog from "components/CommonDialog";
import BankDetailsInfo from "Views/Common/BankDetailsInfo";

// Services
import { USER_SERVICES } from "Services";

// Context
import { Context } from "context";
import { ADD_NOTIFICATION } from "context/actions";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

const BankDetailModal = (props) => {
  const { dispatch: globalDispatch } = useContext(Context);

  const {
    data,
    isOpen,
    closePopup,
    levelData,
    settingsData,
    onUpdate
  } = props;
 
  const {
    setting_level: level
  } = settingsData;

  const [bankDetails, setBankDetails] = useState({
    accountHolderName: data?.account_holder_name,
    accountNumber: data?.last4 ? `**** ${data?.last4}` : "",
    routingNumber: data?.routing_number
  });
  const [checkErrors, setCheckErrors] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editBankDetails, setEditBankDetails] = useState(false);

  const handleInputChange = (value, attribute) => {
    setBankDetails({ ...bankDetails, [attribute]: value });
    setEditBankDetails(true);
  };

  const checkErrorsInForm = async () => {
    if (
      !bankDetails.accountHolderName ||
      !bankDetails.accountNumber ||
      !bankDetails.routingNumber
    ) {
      setCheckErrors(true);
      return true;
    }

    setCheckErrors(false);
    return false;
  };

  const onConfirmClick = async () => {
    if(!editBankDetails) {
      closePopup();
      return ;
    }

    let hasErrors = await checkErrorsInForm();
    if(hasErrors)
      return;

    setLoading(true);
    try {
      const rData = await (
        await stripePromise
      ).createToken("bank_account", {
        country: "us",
        currency: "USD",
        account_number: bankDetails.accountNumber,
        routing_number: bankDetails.routingNumber,
        account_holder_type: "company",
        account_holder_name: bankDetails.accountHolderName,
      });

      if (rData?.error) {
        Sentry.captureException(rData?.error);
        rData.error?.message && Sentry.captureMessage(rData.error.message);

        dispatchGlobalNotification('error', rData?.error?.message);
        setLoading(false);

        return;
      }

      const payload = {
        bank_token: rData?.token?.id,
      };

      const response = await USER_SERVICES.accountSetup(payload, level, levelData[`${level}_id`], 'put');

      if (response?.type === 'success') {
        dispatchGlobalNotification('success', "Details updated successfully");
        closePopup();
        onUpdate("Settings");
      }
      else {
        throw ( response?.message || response?.error );
      }
    } catch (error) {
      dispatchGlobalNotification('error', error || "Error updating the details");
    }
    setLoading(false);
  };

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } }
    });

  return (
    <CommonDialog
      open={isOpen}
      handleClose={closePopup}
      isLoading={isLoading}
      dialogTitle="Update Bank Details"
      primaryBtnTxt="Confirm"
      handlePrimaryButtonClick={onConfirmClick}
      disabledPrimaryButton={isLoading}
      secondaryBtnTxt="Cancel"
    >
      <BankDetailsInfo
        bankDetails={bankDetails}
        handleInputChange={handleInputChange}
        checkErrors={checkErrors}
      />
    </CommonDialog>
  );
};

export default BankDetailModal;