import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField, Typography, Grid } from '@material-ui/core';

import { AntSwitch, SectionHeader } from 'components';
import { PlansContext } from 'Views/Plans/context';
import {
  COMMISSION_UPDATED,
  UPDATE_PRICE_VALIDITY,
} from 'Views/Plans/context/actions';
import styles from './CommissionPayout.module.scss';

const CommissionPayout = (props) => {
  const { plan = {}, planIndex } = props;
  const {
    state: {
      isValidPrice,
      isValid,
      default_commission,
      highlightErrors,
      selectedGroup,
      productInfo,
      plan_id,
    },
    dispatch,
  } = useContext(PlansContext);
  const { plan_package_type } = productInfo;
  const { group_id } = selectedGroup || {};
  const { commission, tiers } = plan;
  const { type, values } = commission;
  const isPercent = type === 'percent';
  const tierId = `payout-grid-${planIndex + 1}`;
  const primaryAmount =
    parseFloat(tiers[0]?.unit_amount || 0) +
    parseFloat(tiers[0]?.upfront_amount || 0) +
    parseFloat(tiers[0]?.signup_amount || 0);

  useEffect(() => {
    const payStructure = { ...default_commission };

    if ((group_id && !plan_id) || !Boolean(values.length))
      dispatchCommissionChange({
        ...commission,
        values: ['Primary'].map(() => payStructure),
      });
  }, [group_id]);

  useEffect(() => {
    const sum = values.map((item) =>
      Object.values(item).reduce(
        (addedValue, value) => addedValue + parseFloat(value || '0'),
        0,
      ),
    );

    const validationValue = !isPercent ? primaryAmount : 100;
    const isValidTotal = Boolean(sum[0] === validationValue);
    const isTiersError =
      !tiers.length ||
      tiers.some((tier) =>
        Object.keys(tier).some((key) => hasError(key, tier[key])),
      );
    const isCurrentValid = isValidPrice[planIndex]?.payout;

    if ((isValidTotal && isCurrentValid) || (!isValidTotal && !isCurrentValid))
      return;

    dispatch({
      type: UPDATE_PRICE_VALIDITY,
      payload: {
        isValidPrice: {
          ...isValidPrice,
          [planIndex]: {
            ...isValidPrice[planIndex],
            ...(plan_package_type !== "Tiered" && {tiers: !isTiersError}),
            payout: isValidTotal,
          },
        },
      },
    });
  }, [commission, primaryAmount]);


  const hasError = (key, value) => {
    if (
      [
        'unit_amount',
        'upfront_amount',
        'signup_amount',
        'signup_charge_amount',
      ].includes(key) &&
      (value === 0 || value === '0')
    )
      return false;

    if (
      [
        'unit_charge_amount',
        'upfront_charge_amount',
        'flat_amount',
        'flat_charge_amount',
      ].includes(key)
    )
      return false;

    return !value;
  };

  useEffect(() => {
    if (
      Boolean(highlightErrors) &&
      isValid.group &&
      isValid.cpt &&
      isValid.product &&
      isValidPrice[0]?.tiers &&
      !isValidPrice[planIndex]?.payout
    ) {
      if (planIndex > 0 && (!isValidPrice[0].payout || !isValidPrice[1].tiers))
        return;

      setTimeout(() => {
        document
          .getElementById(tierId)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }, [highlightErrors, isValid, isValidPrice]);

  const dispatchCommissionChange = (updated_commission) =>
    dispatch({
      type: COMMISSION_UPDATED,
      payload: { commission: updated_commission, planIndex },
    });

  const handleTypeChange = (event) =>
    dispatchCommissionChange({
      ...commission,
      type: event.target.checked ? 'percent' : 'fixed',
    });

  const handleChange = (event, rowIndex, value) => {
    const updatedValues = values.map((item, index) =>
      index === rowIndex
        ? {
            ...item,
            [event.target.name]: value || event.target.value || '0',
          }
        : item,
    );

    dispatchCommissionChange({
      ...commission,
      values: updatedValues,
    });
  };

  const onBlur = (event, rowIndex) => {
    let value = Math.abs(+parseFloat(event.target.value).toFixed(2)) || 0;

    if (isPercent) value = value > 100 ? 100 : value;

    if (event.target.value != value) handleChange(event, rowIndex, value);
  };

  const handleFocus = (event) => event.target.select();


  const renderInputField =
    (name, label, value, rowIndex = 0) => {
      return (
        <TextField
          required
          fullWidth
          variant="outlined"
          value={value || 0}
          label={label}
          name={name}
          onChange={(event) => handleChange(event, rowIndex)}
          onFocus={handleFocus}
          onBlur={(event) => onBlur(event, rowIndex)}
          error={Boolean(highlightErrors && !isValidPrice[planIndex]?.payout)}
          InputProps={{
            startAdornment: !isPercent && (
              <InputAdornment position="start">$</InputAdornment>
            ),
            endAdornment: isPercent && (
              <InputAdornment position="end">%</InputAdornment>
            ),
          }}
          inputProps={{
            type: 'number',
            min: 0,
            'data-testid': `commission-payout-${name}`,
          }}
        />
      );
    }

  const columns = [
    {
      name: 'partner',
      label: 'Partner',
      value: commission?.values[0]?.partner,
    },
    {
      name: 'group',
      label: 'Group',
      value: commission?.values[0]?.group,
    },
    {
      name: 'region',
      label: 'Region',
      value: commission?.values[0]?.region,
    },
    {
      name: 'location',
      label: 'Location',
      value: commission?.values[0]?.location,
    },
    {
      name: 'provider',
      label: 'Provider',
      value: commission?.values[0]?.provider,
    },
  ];

  const calculateTotalCommision = () => {
    const totalValue = commission?.values.map((item) =>
      Object.values(item).reduce(
        (addedValue, value) => addedValue + parseFloat(value || '0'),
        0,
      ),
    );

    return (
      <Typography
        variant="body1"
        component="p"
        className={styles.helperTextPosition}
      >
        Total: {totalValue}%
        
        {/* keeping it for future use */}

        {/* {Boolean(primaryAmount) && (
          <Typography variant="caption" className={styles.totalValue}>
            / {isPercent ? '100%' : `$${primaryAmount}`}
          </Typography>
        )} */}
      </Typography>
    );
  };


  return (
    <>
      <SectionHeader
        title="Payout"
        titleClasses={["font-weight-400"]}
        titleVariant="h6"
        titleComponent="h6"
        helperText={calculateTotalCommision()}
        rightSection={
          <AntSwitch
            disabled
            leftLabel="Fixed"
            rightLabel="Percentage"
            name="commission-payout"
            checked={isPercent}
            onChange={handleTypeChange}
            className={styles.headerSwitch}
          />
        }
      />
      <div id={tierId}>
         <Grid container spacing={3} className="mg_top_24">
            {columns.map(({ name, label, value }, index) => {
              return <Grid item xs={12} md={6} lg={2} key={`commission-payout-grid-${index + 1}`} >
                 {renderInputField(name, label, Number(value || 0))}
              </Grid> 
            })}
         </Grid>
      </div>
      {Boolean(highlightErrors && !isValidPrice[planIndex]?.payout) && (
        <Typography className="mg_top_20" color="error" align="right">
          * Please ensure the values add up to total
        </Typography>
      )}
    </>
  );
};

CommissionPayout.propTypes = {
  plan: PropTypes.object.isRequired,
  planIndex: PropTypes.number.isRequired,
};

export default CommissionPayout;
