import React, { useContext, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";

import { SUBSCRIBER_SERVICES } from "Services";
import { Context } from "context";
import {
  UPDATE_COUPON_CODE,
  RESET_COUPON_CODE,
  VALIDATING_COUPON_CODE,
  SET_COUPON_CODE_DETAIL,
  SET_COUPON_CODE_ERROR,
  ADD_NOTIFICATION,
} from "context/actions";

const PromoCode = (props) => {
  const { acceptedDurations = ["once"], disabled = false, product_type = 'recur', amountDue = 0 } = props;

  const {
    state: {
      promoCode: {
        couponCode,
        loading: codeLoading,
        couponError,
        couponDetails = [],
      } = {},
    } = {},
    dispatch,
  } = useContext(Context);

  const getError = (duration) => {
    switch (duration) {
      case 'alreadyExists':
        return 'Coupon applied already';
      case 'once':
        return 'Coupon should be repeating use';
      case 'repeating':
      case 'forever':
        return 'Coupon should be one time use';
      case 'cupounAmountMoreThanAmountDue':
        return 'Higher value coupon cannot be used for this transaction';
      default:
        return 'Sorry, this code is not valid. Make sure you entered it correctly.';
    }
  };

  const dispatchGlobalNotification = (severity, message) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } },
    });

  const checkCouponCodeValidity = async () => {
    dispatch({
      type: VALIDATING_COUPON_CODE,
      payload: {
        loading: true,
      },
    });

    let errorMessage = "";
    try {
      const payload = { coupon_code: couponCode, product_type };
      const response = await SUBSCRIBER_SERVICES.validateCouponCode(payload);

      if (response?.type === "success") {
        const { duration = "", id = "", amount_off = 0 } = response?.data || {};


        if (amountDue < amount_off / 100) {
          errorMessage = getError('cupounAmountMoreThanAmountDue');
          throw new Error();
        } else if (couponDetails.some((coupon) => coupon.id === id)) {
          errorMessage = getError('alreadyExists');
          throw new Error();
        } else if (acceptedDurations.includes(duration)) {
          dispatch({
            type: SET_COUPON_CODE_DETAIL,
            payload: {
              detail: response.data,
            },
          });

          dispatchGlobalNotification('success', 'Coupon applied successfully');
        } else {
          errorMessage = getError(duration);
          throw new Error();
        }
      } else {
        errorMessage = getError("");
        throw new Error();
      }
    } catch (error) {
      console.log({ error });

      !errorMessage && (errorMessage = getError(""));
      dispatch({
        type: SET_COUPON_CODE_ERROR,
        payload: {
          error: errorMessage,
        },
      });
    }
  };

  const resetPromoCode = () =>
    dispatch({
      type: RESET_COUPON_CODE,
    });

  const getPromoButtonField = () => {
    if (codeLoading) return <CircularProgress size={20} />;

    if (!couponCode) return;

    return (
      <Button
        onClick={checkCouponCodeValidity}
        size="small"
        data-testid="promo-apply-button"
      >
        <Typography> Apply </Typography>
      </Button>
    );
  };

  const onCouponChange = (evt) =>
    dispatch({
      type: UPDATE_COUPON_CODE,
      payload: {
        value: evt?.target?.value || "",
      },
    });

  useEffect(() => {
    return () => resetPromoCode();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography
          component={Link}
          className="cursor_pointer"
          color="secondary"
          data-testid="have-promo-code"
          disabled={disabled}
        >
          Have a Promo Code?
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Promo Code"
          name="coupon_code"
          value={couponCode}
          onChange={onCouponChange}
          error={Boolean(couponError)}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment>{getPromoButtonField()}</InputAdornment>
            ),
          }}
          helperText={
            Boolean(couponError) && couponError
          }
          inputProps={{
            "data-testid": "promo-code-input",
          }}
          autoFocus
        />
      </Grid>
    </>
  );
};

PromoCode.propTypes = {
  acceptedDurations: PropTypes.array,
  disabled :PropTypes.bool, 
  product_type:  PropTypes.string, 
  amountDue: PropTypes.number,
};

export default PromoCode;
