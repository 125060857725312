import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { reducer, initialState } from './plansReducer';

export const PlansContext = createContext();

const PlansProvider = ({ children, ...other }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch, ...other };

  return <PlansContext.Provider value={value}>{children}</PlansContext.Provider>;
};

PlansProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default PlansProvider;
