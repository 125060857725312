import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { IconButton, Typography, Tooltip, Button } from "@material-ui/core";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";

import { TableListView } from "components";
import Utils from "Shared/Utils";
import { formatDateTimeFromUnix } from 'utils';
import { USER_SERVICES } from "Services";
import ConfirmModal from "Views/Common/ConfirmModal";
import { Context } from "context";
import { ADD_NOTIFICATION } from 'context/actions';

const UserManagement = (props) => {
  const {
    users = [],
    onUpdate,
    handleResetPassword
  } = props;

  const { dispatch } = useContext(Context);

  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [userData, setUserData] = useState({});

  const tableColumns = [
    {
      name: "full_name",
      label: "Full Name",
    },
    {
      name: "email",
      label: "Email Address",
    },
    {
      name: "password",
      label: "Password",
      options: {
        sort: false,
      },
    },
    {
      name: "role",
      label: "Role",
    },
    {
      name: "level_name",
      label: "Level Name",
    },
    {
      name: "employee_id",
      label: "Employee ID",
    },
    {
      name: "recent_login",
      label: "Last Login",
    },
    {
      name: "action",
      label: Boolean(users?.length > 1) ? "Action" : " ",
      options: {
        sort: false,
        customBodyRender: (indUser, { rowIndex }) => {
          const showButton = Boolean(users?.length > 1);
 
          return (
            <>
              {showButton && (
                <Typography
                  className="theme_color_orange cursor_pointer"
                  onClick={() => openPopup(indUser)}
                  aria-label="delete"
                  data-testid={`delete-user-button-${rowIndex}`}
                >
                  Remove
                </Typography>
              )}
            </>
          );
        },
      },
    },
  ];

  const tableData = users?.map((indUser, index) => ({
    full_name: indUser?.name,
    email: indUser?.email,
    password: (
      <div className="d-flex align-center">
        <Typography variant="body1">************</Typography>
        <Tooltip arrow title={
          <Typography variant="body1">
            Reset Password
          </Typography>
        }>
          <IconButton size="small" className="mg_left_8" onClick={() => { handleResetPassword && handleResetPassword(indUser) }} data-testid={`reset-password-button-${index}`}>
            <LockOpenRoundedIcon className="theme_color_orange" fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </div>
    ),
    role: Utils.getRole(indUser?.role),
    level_name: indUser?.level_name || '-',
    employee_id: indUser?.employee_id || '-',
    recent_login: indUser.recent_login ? formatDateTimeFromUnix(indUser.recent_login.date_time) : '-',
    action: indUser,
  }));

  const triggerUserCreation = () =>
    history.push({
      pathname: `/users/create`,
    });

    const openPopup = (user) => {
      setUserData(user);
      setShowPopup(true);
    };

    const closePopup = () => {
      setUserData({});
      setShowPopup(false);
    };
  
  const onDeleteClick = async () => {
    setLoading(true);
    try {
      const { email = "", partner_id = "" } = userData;
  
      const payload = {
        user: {
            username: email,
            partner_id: partner_id,
            hard_delete: true
        }
      };
  
      let response = await USER_SERVICES.deleteUser(payload);
      if (response?.type === "success") {
        setGlobalNotification("success", "User deleted successfully");
        onUpdate && onUpdate();
      }
      else {
        throw (response?.message || response?.error);
      }
    }
    catch(err) {
      setGlobalNotification("error", err || "Error while deleting user details");
    }
    setLoading(false);
  };

  const setGlobalNotification = (severity, message) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          severity,
          message
        }
      }
    });

  const btnProps = {
    otherButtons : (
      <Button
        onClick={triggerUserCreation}
        color="secondary"
        size="large"
        data-testid='add-new-user-button'
      >
        Add New User
      </Button>
    )
  };

  return (
    <>
      <TableListView
        headerTitle={`User Management`}
        tableData={tableData}
        tableOptions={{}}
        tableColumns={tableColumns}
        pagination={Boolean(tableData.length > 10)}
        headerSection={true}
        emptyPlaceHolderContent="No Users found"
        {...btnProps}
      />

      {
        showPopup &&
        <ConfirmModal
          isOpen={showPopup}
          closeModal={closePopup}
          emitConfirmEvent={onDeleteClick}
          isLoading={isLoading}
          options={{
            title: "Delete User",
            description: "Are you sure you want to delete?",
            confirmText: "Confirm",
            cancelText: "Cancel"
          }}
        />
      }
    </>
  );
};

export default UserManagement;
