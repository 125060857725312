import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';

const FilePreview = (props) => {
  const { fileName = '', size = '', onReplaceFile } = props;

  const fileSize = `${(size / 1024 / 1024).toFixed(3)} MB`;
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      className={clsx(classes.dropzone, 'mt-32')}
    >
      <Grid item>
        <img src="/images/file-types/xls.svg" alt="xls" />
      </Grid>
      <Grid item style={{ paddingTop: '12px' }}>
        <Typography
          variant="body1"
          style={{ fontSize: 16, lineHeight: '1rem' }}
        >
          {fileName} <Typography component="span">({fileSize})</Typography>
        </Typography>
      </Grid>
      <Grid item style={{ paddingTop: '12px' }}>
        <Typography
          variant="body1"
          color="secondary"
          onClick={onReplaceFile}
          style={{
            fontSize: 15,
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Replace file
        </Typography>
      </Grid>
    </Grid>
  );
};

FilePreview.propTypes = {
  fileName: PropTypes.string,
  size: PropTypes.number,
  onReplaceFile: PropTypes.func,
};

export default FilePreview;
