import React from 'react';
import { Button } from '@material-ui/core';
import { ReactComponent as ClearIcon } from "assets/images/clear.svg";
import styles from './ClearButton.module.scss'

const ClearButton = (props) => (
  <Button
    className={styles.clearButton}
    color="secondary"
    fullWidth
    variant="outlined"
    startIcon={<ClearIcon className={styles.iconColor} />}
    {...props}
  />
)

export default ClearButton;
