import React from "react";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";

import style from "./FieldsWrapper.module.scss";

const FieldsWrapper = (props) => {
  const { children } = props;

  return (
    <Paper
      variant="outlined"
      elevation={0}
      classes={{ root: style.fields_wrapper_main_container }}
    >
      {children}
    </Paper>
  );
};

FieldsWrapper.propTypes = {
  children: PropTypes.node,
};

export default FieldsWrapper;
