import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { reducer, initialState } from './reducer';

export const Context = createContext();

const Provider = ({ children, ...other }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch, ...other };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node.isRequired
};

export default Provider;
