import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useHistory } from "react-router";
import clsx from "clsx";
import {
  IconButton,
  Button,
  TextField,
  Grid,
  CircularProgress,
  InputAdornment,
  Typography,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  ArrowRight as ArrowDropRightIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import PropTypes from "prop-types";

// Components
import { Chip, TableListView } from "components";
import ConfirmModal from "Views/Common/ConfirmModal";
import { CategoryDiscounts } from "Views/Plans/CreatePlan/components";
import AddPlansPopup from './AddPlansPopup';

// Constants
import { PLAN_PACKAGE_TYPE } from "Views/Common/enum";

// Utils
import { formatCurrency } from "utils";
import { getTargetValue } from "Shared/Utils";

// Services
import { LOCATION_SERVICES, PLAN_SERVICES } from "Services";

// Context
import { Context } from "context";
import { ADD_NOTIFICATION } from 'context/actions';
import { withPlansContext } from "Views/Plans/context";

// Styles
import styles from "./AssociatedPlan.module.scss";

const AssociatedPlan = (props) => {
  const {
    plansLoading,
    planList,
    hierarchyDetails = {},
    isCreationEnabled,
    isSettingsView,
    onUpdate,
  } = props;
  const {
    name: level_name,
    partner_id,
    group_id,
    location_id,
  } = hierarchyDetails;

  const { dispatch: globalDispatch } = useContext(Context);

  const [editedRow, setEditedRow] = useState('');
  const [loadingRow, setLoadingRow] = useState('');
  const [eventsData, setEventsData] = useState([]);
  const [baseData, setBaseData] = useState([]);

  const [deletePlanId, setDeletePlanId] = useState('');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [groupPlans, setGroupPlans] = useState([]);

  const history = useHistory();

  const setGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          severity,
          message,
        },
      },
    });

  const openAddPopup = () => setIsAddPopupOpen(true);
  const closeAddPopup = () => setIsAddPopupOpen(false);

  const handleDeleteOpen = (planId) => {
    setDeletePlanId(planId);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeletePlanId('');
    setDeleteOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (!location_id) return;

    setDeleteLoading(true);

    try {
      const payload = { plan_package_ids: [deletePlanId] };
      const response = await LOCATION_SERVICES.deleteLocationPlans(
        location_id,
        payload,
      );

      if (response?.type === 'success' && response?.data) {
        setGlobalNotification(
          'success',
          'The plan has been successfully removed from this location.',
        );

        handleDeleteClose();
        onUpdate?.();
      } else {
        throw response?.message || response?.error;
      }
    } catch (err) {
      setGlobalNotification(
        'error',
        err || 'An error occurred during the deletion of the location plan.',
      );
    } finally {
      setDeleteLoading(false);
    }
  };

  const getEventsData = () => {
    let tableData = [];
    const planListCloned = _.cloneDeep(planList);
    let dataId = 0;

    planListCloned?.forEach(plan => {
      const pTiers = plan.pricing_tiers_list || [];
      const sPlans = [];

      pTiers.forEach((pTier, pIndex) => {
        const {
          unit_amount = 0,
          upfront_amount = 0,
          signup_amount = 0,
        } = pTier?.pricing_tiers?.tiers?.[0] || {};
        const upfrontActivationFee = upfront_amount > 0 ? upfront_amount : signup_amount;
        sPlans.push({
          plan_id: pTier?.plan_id,
          plan_package_id: plan?.plan_package_id,
          plan_name: pIndex ? '' : plan?.plan_name,
          plan_package_type: pIndex
            ? ''
            : PLAN_PACKAGE_TYPE[plan?.plan_package_type],
          billing_frequency: pTier?.pricing_tiers?.billing_frequency,
          pms_discount_id: pTier?.pms_discount_id,
          unit_amount:  formatCurrency(unit_amount / 100),
          upfront_activation_fee: formatCurrency(upfrontActivationFee / 100),
          member_type: pTier?.plan_type || '-',
          status: <Chip label={pTier.status} status={pTier.status} />,
          action: pIndex ? '' : plan?.plan_package_id,
          lastRow: pIndex === pTiers.length - 1,
          dataId,
        });

        dataId++;
      });

      tableData = [...tableData, ...sPlans];
    });

    setEventsData(tableData);
    setBaseData(tableData);
  };

  const updateValues = (id, key, value) => {
    const updatedData = _.cloneDeep(eventsData);
    const updatedValue = value !== undefined
      ? value
      : baseData[id][key];
    updatedData[id] = {
      ...updatedData[id],
      [key]: updatedValue
    };
    setEventsData(updatedData);
  };

  const handleSubmitChanges = async (index) => {
    setLoadingRow(index);

    try {
      if (eventsData?.[index]?.pms_discount_id !== baseData?.[index]?.pms_discount_id) {
        const payload = {
          pms_discount_id: eventsData[index].pms_discount_id,
        };

        const plan_id = baseData[index].plan_id;
        const response = await LOCATION_SERVICES.updateLocationPlan(location_id, plan_id, payload);
        if (response?.type === "success") {
          setGlobalNotification('success', 'Location Plan Updated Successfully');
          baseData[index].pms_discount_id = eventsData[index].pms_discount_id;
          setEditedRow('');
        }
        else {
          throw (response?.message || response?.error);
        }
      }
      else {
        setEditedRow('');
      }
    }
    catch (err) {
      setGlobalNotification("error", err || "Error occured while updating Location Plan");
    }

    setEditedRow("");
    setLoadingRow("");
  }

  const renderTableInput = (value, name, rowId) => {
    return (
      <TextField
        required
        fullWidth
        variant="outlined"
        margin="dense"
        value={value}
        name={name}
        onChange={(event) => updateValues(rowId, name, getTargetValue(event))}
        inputProps={{
          'data-testid': `pms-discount-id-edit-${rowId}`,
          maxLength: 10,
          onInput: (e) => {
            e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, '');
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={clsx (styles.actionIcon, 'theme_color_orange cursor_pointer')}>
              <>
                {loadingRow === rowId ? (
                  <CircularProgress thickness={5} size={20} />
                ) : (
                  <>
                    <SaveIcon
                      fontSize="small"
                      className="mr-10"
                      onClick={() => handleSubmitChanges(rowId)}
                      data-testid={`pms-id-save-icon-${rowId}`}
                    />
                    <CloseIcon
                      fontSize="small"
                      onClick={() => {
                        setEditedRow('');
                        updateValues(rowId, 'pms_discount_id');
                      }}
                      data-testid={`pms-id-cancel-icon-${rowId}`}
                    />
                  </>
                )}
              </>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const renderTableValue = (value, rowIndex) => {
    if (value)
      return (
        <Grid item className="d-flex align-center">
          {value}
          <EditIcon
            className="theme_color_orange"
            fontSize="small"
            style={{ marginLeft: 8 }}
            data-testid={`${rowIndex}-edit-pms-id`}
            onClick={(e) => {
              e.stopPropagation();

              setEditedRow(rowIndex);
            }}
          />
        </Grid>
      );

    return (
      <Grid item className="d-flex align-center">
        <Typography
          className="theme_color_orange"
          fontSize="small"
          data-testid={`${rowIndex}-add-pms-id`}
          style={{ textDecoration: 'underline' }}
          onClick={(e) => {
            e.stopPropagation();

            setEditedRow(rowIndex);
          }}
        >
          Missing Plan Id
        </Typography>
      </Grid>
    );
  };

  const renderActionButtons = (rowIndex, planId) => {
    const viewButton = (
      <IconButton
        aria-label="view"
        size="small"
        onClick={(e) => {
          e.stopPropagation();

          viewPlan(planId);
        }}
        data-testid={`view-plan-button-${rowIndex}`}
      >
        <VisibilityIcon className="theme_color_orange" fontSize="inherit" />
      </IconButton>
    );

    const deleteButton = (
      <IconButton
        aria-label="delete"
        size="small"
        onClick={(e) => {
          e.stopPropagation();

          handleDeleteOpen(planId);
        }}
        data-testid={`delete-plan-button-${rowIndex}`}
      >
        <DeleteIcon className="theme_color_orange" fontSize="inherit" />
      </IconButton>
    );

    return <>{level_name === 'Location' ? deleteButton : viewButton}</>;
  };

  const commonCellProps = () => ({ className: styles.tableColumn });
  const pmsCellProps = () => ({ className: styles.pmsColumn });

  const renderPlanName = (value, tableMetaData) => {
    if (!value) return <></>;

    const { rowIndex } = tableMetaData;

    const packageId = eventsData?.[rowIndex]?.plan_package_id;
    const { dataId = 0 } =
      eventsData?.find(
        (indData) => indData?.plan_package_id === packageId && indData?.lastRow,
      ) || {};

    return (
      <Grid item className="d-flex align-center">
        {level_name === 'Location' &&
          (expandedRows.includes(dataId) ? (
            <ArrowDropDownIcon
              className="mr-10 cursor_pointer"
              onClick={() => handleOpenClick(rowIndex, dataId)}
            />
          ) : (
            <ArrowDropRightIcon
              className="mr-10 cursor_pointer"
              onClick={() => handleOpenClick(rowIndex, dataId)}
            />
          ))}
        {value}
      </Grid>
    );
  };

  const tableColumns = [
    {
      name: 'plan_name',
      label: 'Plan Name',
      options: {
        setCellProps: commonCellProps,
        customBodyRender: renderPlanName,
      },
    },
    {
      name: 'plan_package_type',
      label: 'Plan Type',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'member_type',
      label: 'Member Type',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'unit_amount',
      label: 'Unit Price',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'upfront_activation_fee',
      label: 'Upfront/Activation Fee',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'billing_frequency',
      label: 'Billing Frequency',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'pms_discount_id',
      label: 'PMS Plan ID',
      options: {
        setCellProps: pmsCellProps,
        display: level_name === 'Location' ? true : 'excluded',
        customBodyRender: (value, tableMetaData) => {
          const { rowIndex } = tableMetaData;

          return editedRow === rowIndex
            ? renderTableInput(value, 'pms_discount_id', rowIndex)
            : renderTableValue(value, rowIndex);
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        setCellProps: commonCellProps,
        sort: false,
        display: !isSettingsView && isCreationEnabled,
        customBodyRender: (planId, tableMetaData) => {
          const { rowIndex } = tableMetaData;

          if (!planId) return '';

          return (
            <Grid container spacing={2}>
              <Grid item alignItems="center" justifyContent="center">
                {renderActionButtons(rowIndex, planId)}
              </Grid>
            </Grid>
          );
        },
      },
    },
  ];

  const getPlansByGroupid = async () => {
    if (!group_id) return;

    try {
      const res = await PLAN_SERVICES.fetchGroupPlanPackages(group_id);
  
      if (res?.type === "success" && res?.data) {
        setGroupPlans(res?.data?.rows);
      }
      else {
        throw res;
      }
    }
    catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (level_name === 'Location')
      getPlansByGroupid();
  }, [group_id]);

  useEffect(() => {
    getEventsData();
  }, [planList]);

  const triggerPlanCreation = () =>
    history.push({
      pathname: `/plans/create`,
      state: { partner_id, group_id },
    });

  const viewPlan = (planPackageId) => {
    if (!planPackageId) return;

    history.push({
      pathname: `/plans/info`,
      state: {
        plan_package_id: planPackageId,
        group_id,
      },
    });
  };

  const handleOpenClick = (rowIndex, dataId) => {
    if(isSettingsView || !(isCreationEnabled)) return;

    if (editedRow !== '') {
      updateValues(rowIndex, 'pms_discount_id')
    }

    if (expandedRows.includes(dataId))
      setExpandedRows([]);
    else
      setExpandedRows([dataId]);
  };

  const btnProps = {
    otherButtons:
      isCreationEnabled &&
      (level_name === 'Group' ||
        (groupPlans.length && groupPlans.length !== planList.length)) ? (
        <Button
          onClick={() =>
            level_name === 'Group' ? triggerPlanCreation() : openAddPopup()
          }
          color="secondary"
          size="large"
          data-testid="add-plan"
        >
          + Add Plan
        </Button>
      ) : (
        ''
      ),
  };

  const otherButtons = (
    <Button
      onClick={() => setExpandedRows([])}
      color="secondary"
      variant="outlined"
      size="large"
      data-testid="category-discount-cancel-button"
      style={{ marginRight: 16 }}
    >
      Cancel
    </Button>
  );

  const renderExpandableRows = (_rowData, { rowIndex }) => {
    const planPackageId = eventsData?.[rowIndex]?.plan_package_id;

    return (
      <TableRow>
        <TableCell colSpan={9}>
          <Paper variant="outlined" elevation={0} className={"widget-wrapper"}>
            <CategoryDiscounts
              level={'location'}
              levelId={location_id}
              planPackageId={planPackageId}
              otherButtons={otherButtons}
            />
          </Paper>
        </TableCell>
      </TableRow>
    );
  };

  const handleChangeRowsPerPage = (updatedRowsPerPage) =>
    setRowsPerPage(updatedRowsPerPage);

  const tableOptions = {
    sort: false,
    rowsPerPage,
    rowsPerPageOptions: [10, 25, 100],
    onChangeRowsPerPage: handleChangeRowsPerPage,
    expandableRows: level_name === 'Location',
    rowsExpanded: expandedRows,
    renderExpandableRow: renderExpandableRows,
  };

  return (
    <>
      <TableListView
        headerTitle="Associated Plan(s)"
        tableData={eventsData}
        tableOptions={tableOptions}
        tableColumns={tableColumns}
        pagination={Boolean(eventsData.length > 10)}
        headerSection={true}
        emptyPlaceHolderContent="No Associated Plans found"
        className={'location-plans-table'}
        {...btnProps}
        tableProps={{
          components: {
            ExpandButton: () => <></>,
          },
        }}
        isLoading={plansLoading}
      />

      {deleteOpen && (
        <ConfirmModal
          isOpen={true}
          closeModal={handleDeleteClose}
          emitConfirmEvent={handleConfirmDelete}
          isLoading={deleteLoading}
          options={{
            title: `Delete Plan`,
            description: (
              <>
                Are you sure you want to delete the plan <br />
                from this location?
              </>
            ),
            confirmText: 'Confirm',
            cancelText: 'Cancel',
          }}
        />
      )}

      {isAddPopupOpen && (
        <AddPlansPopup
          data={hierarchyDetails}
          onClose={closeAddPopup}
          onUpdate={onUpdate}
          groupPlans={groupPlans}
          locationPlans={planList}
        />
      )}
    </>
  );
};

AssociatedPlan.propTypes = {
  plansLoading: PropTypes.bool,
  planList: PropTypes.array.isRequired,
  hierarchyDetails: PropTypes.object.isRequired,
  isCreationEnabled: PropTypes.bool,
  isSettingsView: PropTypes.bool,
  onUpdate: PropTypes.func,
};

export default withPlansContext(AssociatedPlan);
