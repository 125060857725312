const palette = {
  alternate: {
    main: '#f5f6f7',
    dark: '#E8EAF6',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    light: '#F0F7FF',
    main: '#002855',
    contrastText: '#FFF',
    highlight: '#5F99DC',
    highlight2: '#5F99DC',
    highlight3: '#5F99DC',
  },
  secondary: {
    light: '#325E8E',
    main: '#325E8E',
    contrastText: '#FFF',
    highlight: '#5F99DC',
  },
  text: {
    primary: '#475670',
    secondary: '#6F6C99',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#38ada9',
    light: '#EAF1F8',
    errorBorder: '#F44336',
    levelOnboardColor: '#475670',
    lightGrey: '#64748B',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#FFF',
    default: '#FFF',
    variant1: '#F2F8FA',
    level2: '#F5F5F5',
    level1: '#FFF',
    progressBG: '#E5EAF4',
    error: '#FFEAEA',
    loginGradiant:
      'linear-gradient(174.67deg, #cc003300 18.59%, #fbecf0 95.73%)',
    levelOnboardGradiant:
      'linear-gradient(174.67deg, #cc003300 18.59%, #fbecf0 95.73%)',
    levelOnboardBg: '#fbecf0',
  },
  hover: 'rgba(245,244,246,.5)',
  table_head: '#E6F2FF',
};

export default palette;
