import React from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';
import {
  Grid,
  Typography,
  CircularProgress,
  Tooltip,
  Divider,
} from '@material-ui/core';
import InfoIcon from "@material-ui/icons/Info";

import {
  FieldsWrapper,
  Heading,
  SectionWrapper,
  ToggleTickSwitch,
} from 'components';
import styles from "./ReportCapabilities.module.scss";

const SectionContainer = (props) => {
  const { type = 'create', title = 'Report Capabilities', children } = props;

  if (type === 'info') {
    return (
      <SectionWrapper title={title}>
        {children}
      </SectionWrapper>
    );
  }

  return (
    <FieldsWrapper>
      {children}
    </FieldsWrapper>
  );
};

SectionContainer.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
};

const ReportCapabilities = (props) => {
  const {
    type = 'create',
    title = 'Report Capabilities',
    subtitle = 'Choose the capability options, and upload any addition information',
    isLoading = false,
    data: {
      new_subscriber = false,
      sub_stats_monthly = false,
      sub_stats_weekly = false,
    } = {},
    handleChange,
  } = props;

  const subscriberNotificationText = 'Patient Portal sign-up notifications';

  return (
    <SectionContainer {...props}>
      <Grid container spacing={2} alignItems="stretch">
        {type === 'create' && (
          <>
            <Grid item xs={12}>
              <Heading title={title} subTitle={subtitle} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={4} lg={3} className="d-flex align-center ">
          <Typography
            variant="body1"
            color="textSecondary"
            className={clsx(styles.toggleTitle, 'd-flex align-center')}
          >
            New Subscriber Notification
            <Tooltip title={subscriberNotificationText}>
              <InfoIcon
                color="primary"
                data-testid="heading-info-button"
                fontSize="small"
                className="ml-5 cursor-pointer"
              />
            </Tooltip>
          </Typography>

          <ToggleTickSwitch
            className="mg_left_8"
            checked={new_subscriber}
            onChange={handleChange}
            name="new_subscriber"
            disabled={Boolean(isLoading)}
            inputProps={{
              'aria-label': 'New Subscriber Report checkbox',
              'data-testid': 'toggle-new-subscriber-report',
            }}
          />

          {Boolean(isLoading === 'new_subscriber') && (
            <CircularProgress size={20} color="primary" />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} className="d-flex align-center ">
          <Typography
            variant="body1"
            color="textSecondary"
            className={styles.toggleTitle}
          >
            New Subscriber Monthly Stats
          </Typography>

          <ToggleTickSwitch
            className="mg_left_8"
            checked={sub_stats_monthly}
            onChange={handleChange}
            name="sub_stats_monthly"
            disabled={Boolean(isLoading)}
            inputProps={{
              'aria-label': 'New Subscriber Monthly Stats checkbox',
              'data-testid': 'toggle-new-subscriber-monthly-stats',
            }}
          />

          {Boolean(isLoading === 'sub_stats_monthly') && (
            <CircularProgress size={20} color="primary" />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} className="d-flex align-center ">
          <Typography
            variant="body1"
            color="textSecondary"
            className={styles.toggleTitle}
          >
            New Subscriber Weekly Stats
          </Typography>

          <ToggleTickSwitch
            className="mg_left_8"
            checked={sub_stats_weekly}
            onChange={handleChange}
            name="sub_stats_weekly"
            disabled={Boolean(isLoading)}
            inputProps={{
              'aria-label': 'New Subscriber Weekly Stats checkbox',
              'data-testid': 'toggle-new-subscriber-weekly-stats',
            }}
          />

          {Boolean(isLoading === 'sub_stats_weekly') && (
            <CircularProgress size={20} color="primary" />
          )}
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

ReportCapabilities.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  handleChange: PropTypes.func,
  type: PropTypes.string,
};

export default ReportCapabilities;
