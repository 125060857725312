import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  // IconButton,
  InputAdornment,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
// import DeleteIcon from '@material-ui/icons/Delete';

import { calculateStripeCharges } from 'utils';
import { PlansContext } from 'Views/Plans/context';
import {
  TIER_UPDATED,
  UPDATE_PRICE_VALIDITY,
} from 'Views/Plans/context/actions';
import { SectionHeader } from 'components';

const PriceTiers = (props) => {
  const { plan = {}, planIndex } = props;
  const {
    state: {
      default_tier_value,
      isValid,
      isValidPrice,
      highlightErrors,
      has_subscriptions,
      discounts,
    },
    dispatch,
  } = useContext(PlansContext);

  const { plan_details, tiers = [] } = plan;
  const includeProcessingFee = Boolean(
    plan_details?.stripe_fee_paid_by === 'subscriber',
  );
  const tierId = `plan-tier-${planIndex + 1}`;

  useEffect(() => {
    const defaultVal = {
      ...default_tier_value,
      up_to: 1,
    };

    if (!tiers?.length) dispatchTierValues([defaultVal]);
  }, [dispatch]);

  useEffect(() => {
    if (
      Boolean(highlightErrors) &&
      isValid.group &&
      isValid.cpt &&
      isValid.product &&
      discounts?.formValid &&
      !isValidPrice[planIndex]?.tiers
    ) {
      if (
        planIndex > 0 &&
        (!isValidPrice[0]?.tiers || !isValidPrice[0]?.payout)
      )
        return;

      setTimeout(() => {
        document
          .getElementById(tierId)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }, [highlightErrors, isValid, isValidPrice]);

  useEffect(() => {
    if (tiers?.length > 1) dispatchTierValues(tiers.splice(0, 1));
  }, [tiers]);

  useEffect(() => {
    const isError =
      !tiers.length ||
      tiers?.some((tier) =>
        Object.keys(tier).some((key) => hasError(key, tier[key])),
      );
    const isCurrentValid = isValidPrice[planIndex]?.tiers;

    if ((isError && !isCurrentValid) || (!isError && isCurrentValid)) return;

    dispatch({
      type: UPDATE_PRICE_VALIDITY,
      payload: {
        isValidPrice: {
          ...isValidPrice,
          [planIndex]: {
            ...isValidPrice[planIndex],
            tiers: !isError,
          },
        },
      },
    });
  }, [tiers]);

  useEffect(() => {
    let updated_tiers;

    if (includeProcessingFee) {
      updated_tiers = tiers?.map((tier) => ({
        ...tier,
        unit_charge_amount: calculateStripeCharges(tier.unit_amount, false),
        upfront_charge_amount: calculateStripeCharges(
          tier.upfront_amount,
          false,
        ),
        signup_charge_amount: calculateStripeCharges(tier.signup_amount, false),
      }));
    } else if (tiers?.length) {
      updated_tiers = tiers?.map((tier) => ({
        ...tier,
        unit_charge_amount: tier.unit_amount,
        upfront_charge_amount: tier.upfront_amount,
        signup_charge_amount: tier.signup_amount,
      }));
    }

    updated_tiers?.length && dispatchTierValues(updated_tiers);
  }, [includeProcessingFee]);

  const dispatchTierValues = (updated_tiers) =>
    dispatch({
      type: TIER_UPDATED,
      payload: { tiers: updated_tiers, planIndex },
    });

  // const deleteTier = (rowIndex) =>
  //   dispatchTierValues(tiers.filter((tier, index) => index !== rowIndex));

  const handleFocus = (event) => event.target.select();

  const updateValues = (
    name,
    value,
    rowIndex,
    calculatedValues = {},
  ) => {
    let updatedVal = Math.abs(parseFloat(value)) || 0;
    dispatchTierValues(
      tiers?.map((tier, index) =>
        rowIndex === index
          ? {
              ...tier,
              [name]: updatedVal,
              ...calculatedValues,
            }
          : tier,
      ),
    );
  };

  const hasError = (key, value) => {
    if (
      ['unit_amount', 'upfront_amount', 'signup_amount'].includes(key) &&
      (value === 0 || value === '0')
    )
      return false;

    if (
      [
        'unit_charge_amount',
        'upfront_charge_amount',
        'flat_amount',
        'flat_charge_amount',
        'signup_charge_amount',
      ].includes(key)
    )
      return false;

    return !value;
  };

  const onBlur = (rowIndex) => (event) => {
    if (!event.target.value) {
      updateValues(event.target.name, 0, rowIndex);
    }
  };

  const renderInputField =
    (
      name,
      label,
      value,
      enableAdornment,
      chargeField,
      includeFixedFee,
      isDisabled = false,
      rowIndex = 0,
    ) =>
     {
      return (
        <TextField
          required
          variant="outlined"
          fullWidth
          label={label}
          name={name}
          value={value}
          disabled={
            // Boolean(rowIndex && tableData.length - 1 === rowIndex) ||
            has_subscriptions ||
            isDisabled
          }
          onChange={(event) => {
            let calculatedValues = {};
            const inputValue = parseFloat(event.target.value || 0);
            if (chargeField)
              //Include the $.30 fee only to the primary member unit price
              calculatedValues = {
                [chargeField]: includeProcessingFee
                  ? calculateStripeCharges(
                      inputValue,
                      rowIndex ? false : includeFixedFee,
                    )
                  : 0,
              };

            updateValues(
              name,
              inputValue,
              rowIndex,
              calculatedValues,
            );
          }}
          onFocus={handleFocus}
          onBlur={onBlur(rowIndex)}
          error={Boolean(highlightErrors) && hasError(name, value)}
          InputProps={{
            startAdornment: enableAdornment && (
              <InputAdornment position="start">$</InputAdornment>
            ),
          }}
          inputProps={{
            type: 'number',
            'data-testid': `price-tiers-${name}`,
          }}
        />
      );
    };
  const columns = [
    {
      name: 'unit_amount',
      label: 'Per Unit',
      value: tiers?.[0]?.unit_amount,
      enableAdornment: true,
      chargeField: "unit_charge_amount",
      includeFixedFee: false
    },
    {
      name: 'unit_charge_amount',
      label: 'Charge Amount',
      value: tiers?.[0]?.unit_charge_amount,
      enableAdornment: true,
      chargeField: "unit_charge_amount",
      includeFixedFee: false,
      isDisabled: true

    },
    {
      name: 'upfront_amount',
      label: 'Upfront Cost',
      value: tiers?.[0]?.upfront_amount,
      enableAdornment: true,
      chargeField: "upfront_charge_amount",
      includeFixedFee: false
    },
    {
      name: 'upfront_charge_amount',
      label: 'Upfront Charge Amount',
      value: tiers?.[0]?.upfront_charge_amount,
      enableAdornment: true,
      chargeField: "upfront_charge_amount",
      includeFixedFee: false,
      isDisabled: true,
    },
    {
      name: 'signup_amount',
      label: 'Activation Cost',
      value: tiers?.[0]?.signup_amount,
      enableAdornment: true,
      chargeField: "signup_charge_amount",
      includeFixedFee: false,

    },
    {
      name: 'signup_charge_amount',
      label: 'Activation Charge Amount',
      value: tiers?.[0]?.signup_charge_amount,
      enableAdornment: true,
      chargeField: "signup_charge_amount",
      includeFixedFee: false,
      isDisabled: true,
    },
    // {
    //   name: "",
    //   options: {
    //     display: !has_subscriptions,
    //     customHeadRender: () => null,
    //     setCellProps: commonCellProps,
    //     customBodyRender: (_value, tableMetaData) => {
    //       const { rowIndex } = tableMetaData;
    //       return (
    //         rowIndex > 0 &&
    //         rowIndex < tiers.length - 1 && (
    //           <IconButton
    //             aria-label="delete"
    //             onClick={() => deleteTier(rowIndex)}
    //             data-testid={`delete-price-tier-button-${rowIndex}`}
    //           >
    //             <DeleteIcon fontSize="inherit" />
    //           </IconButton>
    //         )
    //       );
    //     },
    //   },
    // },
  ];

  return (
    <>
      <SectionHeader
        title="Member Pricing"
        titleClasses={['font-weight-400']}
        titleVariant="h6"
        titleComponent="h6"
      />

      <div id={tierId}>
        <Grid container spacing={3} className="mg_top_24">
            {columns.map(
              (
                {
                  name,
                  label,
                  value,
                  enableAdornment,
                  chargeField,
                  includeFixedFee,
                  isDisabled,
                },
                index
              ) => {
                return (<Grid item lg={2} md={6} xs={12} key={`pricing-tier-grid-${index + 1}`} >
                  {renderInputField(
                  name,
                  label,
                  value,
                  enableAdornment,
                  chargeField,
                  includeFixedFee,
                  isDisabled,
                )}
                </Grid>)
              },
            )}
          </Grid>
      </div>
      {Boolean(highlightErrors && !isValidPrice[planIndex]?.tiers) && (
        <Typography className="mg_top_20" color="error" align="right">
          * Please fill the required fields
        </Typography>
      )}
    </>
  );
};

PriceTiers.propTypes = {
  plan: PropTypes.object.isRequired,
  planIndex: PropTypes.number.isRequired,
};

export default PriceTiers;
