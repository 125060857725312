import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

import { SectionHeader } from 'components';
import { PlansContext } from 'Views/Plans/context';
import {
  PLAN_UPDATED,
  UPDATE_FIELDS_VALIDITY,
  UPDATE_PRICE_VALIDITY,
} from 'Views/Plans/context/actions';
import { REVSHARE_SERVICES } from 'Services';
import Utils from 'Shared/Utils';
import PricingDetails from '../PricingDetails';
import PriceTiers from '../PriceTiers';
import SubscribiliCommission from '../SubscribiliCommission';
import MemberTier from '../MemberTier'
import CommissionPayout from '../CommissionPayout';
import VendorPayout from '../VendorPayout';
import styles from './PriceInfo.module.scss';

const PriceInfo = () => {
  const {
    state: {
      plans,
      isValidMemberTo,
      isValidPrice,
      default_plan,
      isValid,
      highlightErrors,
      plan_id,
      has_subscriptions,
      productInfo,
    },
    dispatch,
  } = useContext(PlansContext);
  const { plan_package_type, additional_members } = productInfo;

  const [expandedPlan, setExpandedPlan] = useState({ 0: true });
  const [isLoading, setLoading] = useState(false);
  const [revshareList, setRevshareList] = useState([]);
  const [showPriceTierBtn, setShowPriceTierBtn] = useState(true);

  const isTieredPlan = plan_package_type === 'Tiered';

  const fetchRevshareList = async () => {
    if (isLoading) return;

    setLoading(true);

    try {
      let response = await REVSHARE_SERVICES.fetchRevshareByQueryParams();

      if (Utils.checkIfSuccess(response)) {
        let rows = Utils.getValue(response?.data?.rows, []);

        setRevshareList(rows);
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    !revshareList.length && fetchRevshareList();
  }, []);

  useEffect(() => {
    if (!plan_id) dispatchPlanUpdate([default_plan]);
  }, []);

  useEffect(() => {
    checkforAdditionalMembers();
  }, [plan_package_type, additional_members, isValidMemberTo, plans]);

  useEffect(() => {
    const expandedState = {};

    if (plans.length) {
      plans.forEach((_plan, index) => {
        if (index === plans.length - 1) {
          expandedState[index] = true;

          return;
        }

        expandedState[index] = false;
      });

      setExpandedPlan(expandedState);
    }
  }, [plans.length]);

  useEffect(() => {
    
    const errors = Object.keys(isValidPrice).map((key) => {
      const item = {
        ...isValidPrice[key],
        ...(isTieredPlan
          ? { tiers: true, member_tier: isValidPrice[key]?.member_tier }
          : { tiers: isValidPrice[key]?.tiers, member_tier: true }),
      };
      return Object.values(item).some((value) => !value)
    })

    const isError = errors.some((item) => item);
    const expandedState = {};
    dispatch({
      type: UPDATE_FIELDS_VALIDITY,
      payload: {
        isValid: { ...isValid, priceValidity: !isError, errorId: 0 },
      },
    });

    let errorIndex = 0;
    if (isError) {
      plans.forEach((_plan, index) => {
        expandedState[index] = Boolean(errors[index]);
        errorIndex = index;
      });

      setExpandedPlan(expandedState);
    }

    dispatch({
      type: UPDATE_FIELDS_VALIDITY,
      payload: {
        isValid: {
          ...isValid,
          priceValidity: !isError,
          errorIndex: isError ? errorIndex : '',
        },
      },
    });
  }, [highlightErrors, isValidPrice]);

  const dispatchPlanUpdate = (updatedPlan) =>
    dispatch({
      type: PLAN_UPDATED,
      payload: { plans: updatedPlan },
    });

  const dispatchPriceValidity = (updatedValidity) =>
    dispatch({
      type: UPDATE_PRICE_VALIDITY,
      payload: { isValidPrice: updatedValidity },
    });

  const handleExpansion = (accordionIndex) => (_event, isExpanded) => {
    setExpandedPlan({
      ...expandedPlan,
      [accordionIndex]: isExpanded,
    });
  };

  const addPricingTier = () => {
    const updatedDefaultPlan = {
      ...default_plan,
      plan_details: {
        ...default_plan?.plan_details,
        member_type: isTieredPlan ? 'Tiered' : 'General',
      },
      tiers: isTieredPlan
        ? [
            {
              up_to: 1,
              unit_amount: '',
              unit_charge_amount: 0,
              upfront_amount: 0,
              upfront_charge_amount: 0,
              signup_amount: 0,
              signup_charge_amount: 0,
            },
          ]
        : [],
    };

    dispatchPlanUpdate(plans.concat([updatedDefaultPlan]));
    dispatchPriceValidity({
      ...isValidPrice,
      [plans.length]: {
        tiers: isTieredPlan,
        payout: false,
        details: false,
        member_tier: false,
      },
    });
  };

  const deletePlan = (planIndex) => {
    const updatedValidity = Object.assign({}, isValidPrice);
    const updatedPlans = plans.filter((_plan, index) => index !== planIndex);
    delete updatedValidity[planIndex];

    dispatchPlanUpdate(updatedPlans);
    dispatchPriceValidity(updatedValidity);
  };

  const checkforAdditionalMembers = () => {
    if (plan_package_type === 'Tiered' && (additional_members === 0 || !isValidMemberTo))
      setShowPriceTierBtn(false);
    else  setShowPriceTierBtn(true);
  };

  return (
    <Paper className={'widget-wrapper'} elevation={0}>
      <SectionHeader
        title="Price Information"
        titleVariant="h5"
        titleComponent="h5"
        gutterBottom
        rightSection={
          Boolean(!has_subscriptions) &&
          showPriceTierBtn && (
            <Button
              startIcon={<AddRoundedIcon fontSize="large" />}
              size="large"
              color="secondary"
              onClick={addPricingTier}
              data-testid="add-plan-price-button"
            >
              Add Pricing Tier
            </Button>
          )
        }
      />
      <Divider />

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          {plans.map((plan, index) => (
            <Accordion
              key={index}
              elevation={0}
              expanded={Boolean(expandedPlan[index])}
              onChange={handleExpansion(index)}
              className="mg_top_20"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={clsx(styles.accordianBackground)}
                aria-controls="panel-content"
                classes={{
                  content: 'd-flex align-center justify-content-between',
                  expandIcon: styles.accordionIcon,
                }}
              >
                <Typography variant="h6" component="h6">
                  {plan?.plan_details?.billing_frequency
                    ? `${plan?.plan_details?.billing_frequency} - ${
                        plan?.member_tier?.price_name ||
                        plan?.plan_details?.member_type || index + 1
                      }`
                    : `Pricing Tier ${index + 1}`}
                </Typography>

                {plans.length > 1 && (
                  <IconButton
                    aria-label="delete"
                    disabled={plan_package_type === 'Tiered' && index === 0}
                    onClick={() => deletePlan(index)}
                    data-testid={`delete-plan-price-button-${index}`}
                    classes={{
                      root: styles.deleteIconColor,
                    }}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                )}
              </AccordionSummary>
              <AccordionDetails className={styles.accordionWrapper}>
                <Grid container spacing={3} alignItems="center">
                  <Grid
                    item
                    xs={12}
                    id={`price-info-pricing-details-${index}`}
                    className="mg_top_20"
                  >
                    <Paper
                      variant="outlined"
                      elevation={0}
                      className={styles.detailsContainer}
                    >
                      <PricingDetails plan={plan} planIndex={index} />
                    </Paper>
                  </Grid>

                  {plan_package_type === 'Tiered' && (
                    <Grid item xs={12}>
                      <Paper
                        variant="outlined"
                        elevation={0}
                        className={styles.detailsContainer}
                      >
                        <MemberTier plan={plan} planIndex={index} />
                      </Paper>
                    </Grid>
                  )}

                  {plan_package_type !== 'Tiered' && (
                    <Grid item xs={12}>
                      <Paper
                        variant="outlined"
                        elevation={0}
                        className={styles.detailsContainer}
                      >
                        <PriceTiers plan={plan} planIndex={index} />
                      </Paper>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Paper
                      variant="outlined"
                      elevation={0}
                      className={styles.detailsContainer}
                    >
                      <SubscribiliCommission
                        plan={plan}
                        planIndex={index}
                        loading={isLoading}
                        list={revshareList}
                      />
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper
                      variant="outlined"
                      elevation={0}
                      className={styles.detailsContainer}
                    >
                      <CommissionPayout plan={plan} planIndex={index} />
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper
                      variant="outlined"
                      elevation={0}
                      className={styles.detailsContainer}
                    >
                      <VendorPayout plan={plan} planIndex={index} />
                    </Paper>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PriceInfo;
