import React, { useContext } from "react";
import clsx from "clsx";
import { Avatar, Button, Grid, Typography } from "@material-ui/core";
import { Context } from 'context';
import { THEME_UPDATED } from 'context/actions';
import CommonDialog from "components/CommonDialog";
import styles from "./ThemeChangeModal.module.scss";

const ThemeChangeModal = (props) => {
  const {
    isOpen,
    onClose,
  } = props
  const { state: { activeTheme }, dispatch } = useContext(Context);

  const themes = [{
    key: 'default',
    label: 'Default',
    color: '#5A3B84'
  }, {
    key: 'dark',
    label: 'Dark',
    color: '#002855'
  }, {
    key: 'green',
    label: 'Green',
    color: '#91c83f'
  }];

  const changeTheme = (updatedTheme) => dispatch({
    type: THEME_UPDATED,
    payload: { activeTheme: updatedTheme }
  });

  return (
    <CommonDialog
      open={isOpen}
      handleClose={onClose}
      dialogTitle="Change Theme"
      maxWidth={"xs"}
    >
      <Grid
        container
        className="text_align_center gutter"
        spacing={3}
        alignItems="center"
        justify="center"
        alignContent="center"
      >
        {
          themes.map(theme => (
            <Grid key={theme.key} item xs={12} md={4}>
              <Button
                fullWidth
                className={clsx({ [styles.active]: (theme.key === activeTheme) })}
                onClick={() => changeTheme(theme.key)}
                data-testid={`${theme.key}-theme`}
              >
                <div>
                  <Avatar className="mt-10" style={{ background: theme.color }}> {""} </Avatar>
                  <Typography className="mg_top_16" component={"p"}>{theme.label}</Typography>
                </div>
              </Button>
            </Grid>
          ))
        }
      </Grid>
    </CommonDialog>
  );
}

export default ThemeChangeModal;
