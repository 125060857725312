import {
  SUBSCRIBER_INFO_LOADING,
  UPDATE_SUBSCRIBER_INFO,
  RESET_SUBSCRIBER_INFO,
  UPDATE_COMMUNICATION_DETAILS,
  UPDATE_SUBSCRIBER_DETAILS,
  RESET_SUBSCRIBER_DETAILS,
  OPEN_EHR_LINK_POPUP,
  CLOSE_EHR_LINK_POPUP,
  APPT_ACTIVE_STEP,
  APPT_PATIENTS_LIST,
  APPT_SELECT_PATIENT,
  APPT_SERVICES_LOADING,
  APPT_SERVICES_LIST,
  APPT_SELECT_SERVICE,
  APPT_SELECT_PROVIDER,
  APPT_SLOTS_LOADING,
  APPT_SLOTS_LIST,
  APPT_SELECT_SLOT,
  APPT_SELECT_INTERVAL,
  APPT_SELECT_DATE,
  APPT_CHANGE_MONTH,
  APPT_CREATE_SUCCESS,
  BOOK_ANOTHER_APPOINTMENT,
  UPDATE_PRIMARY_INFO,
  ADD_ADDITIONAL_MEMBER,
  UPDATE_ADDITIONAL_MEMBER,
  RESET_ADDITIONAL_MEMBERS,
  UPDATE_COUPON_CODE,
  RESET_COUPON_CODE,
  VALIDATING_COUPON_CODE,
  SET_COUPON_CODE_DETAIL,
  MONETARY_LOADING,
  MONETARY_DATA_UPDATE,
  INVOICE_LOADING,
  INVOICE_DATA_UPDATE,
  GROUP_SETTINGS_LOADING,
  GROUP_SETTINGS_UPDATE,
  LOCATION_SETTINGS_LOADING,
  LOCATION_SETTINGS_UPDATE,
  UPDATE_SUBSCRIBER_IMPORT_DATA,
  RESET_SUBSCRIBER_IMPORT_DATA,
  UPDATE_VALID_ADDRESS,
} from './actions';

const addMemberDetails = {
  first_name: '',
  last_name: '',
  gender: '',
  date_of_birth: null,
  plan_package_id: '',
  addons: [],
  include_signup: true,
  addons_signup: {},
  pms_id: '',
  pms_display_id: '',
};

const addMemberBlurred = {
  first_name: false,
  last_name: false,
  gender: false,
  date_of_birth: false,
  plan_package_id: false,
  pms_id: false,
  pms_display_id: false,
};

const addMemberError = {
  first_name: '',
  last_name: '',
  gender: '',
  date_of_birth: '',
  plan_package_id: '',
  pms_id: '',
  pms_display_id: '',
};

const createSubscriberInit = {
  planInfo: {
    group_id: '',
    location_id: '',
    plan_package_id: '',
    plan_frequency: '',
    plan_id: '',
  },
  addMembersInfo: {
    initData: [],
    list: [],
    blurred: [],
    errors: [],
  },
};

const apptInitialState = {
  activeStep: 0,
  completedSteps: [],
  servicesLoading: false,
  selectedService: {},
  selectedProvider: {},
  slotsLoading: false,
  selectedSlot: {},
  selectedIntervals: [],
  startDate: '',
  endDate: '',
  selectedDate: '',
};

const initSubscriberDetails = {
  isValid: false,
  values: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    relationFullname: '',
    relationship: '',
    // relationDob: "",
    locationName: '',
    patientId: '',
    language: 'en',
  },
  touched: {},
  errors: {},
};

const initImportState = {
  activeStep: 0,
  completedSteps: [],
  importFile: null,
  sizeError: false,
  loading: false,
  reviewResponse: {},
  reviewSuccess: false,
  uploadSuccess: false,
};

const initialState = {
  isValidAddress: true,
  subscriberInfo: {},
  ...createSubscriberInit,
  subscriberDetails: {
    ...initSubscriberDetails,
  },
  communicationDetails: {
    isValid: false,
    isUpdated: false,
    initData: {},
    values: {
      email: '',
      phone: '',
      relationship: '',
      member: '',
      addressline: '',
      city: '',
      state: '',
      zipcode: '',
    },
    touched: {},
    errors: {},
    blurred: {},
  },
  linkPatientModal: {
    isOpen: false,
    patient_id: '',
    pms_id: '',
  },
  bookAppointmentModal: {
    ...apptInitialState,
    patientsList: [],
    selectedPatient: {},
    servicesList: [],
    providersList: [],
    slotsList: [],
  },
  promoCode: {
    coupon_code: '',
    loading: false,
    isCouponValid: null,
    couponDetail: {},
    couponError: '',
  },
  monetary: {
    loading: false,
    data: [],
  },
  invoices: {
    loading: false,
    data: [],
  },
  group: {
    loading: false,
    data: {},
  },
  location: {
    loading: false,
    data: {},
  },
  import: {
    ...initImportState,
  },
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case UPDATE_VALID_ADDRESS:
      return {
        ...state,
        isValidAddress: payload.isValidAddress,
      };
    case SUBSCRIBER_INFO_LOADING:
      return {
        ...state,
        subscriberInfo: {
          loading: payload.loading,
        },
      };

    case UPDATE_SUBSCRIBER_INFO:
      return {
        ...state,
        subscriberInfo: { ...payload },
      };
    case RESET_SUBSCRIBER_INFO:
      return {
        ...state,
        subscriberInfo: {},
      };
    case UPDATE_COMMUNICATION_DETAILS:
      return {
        ...state,
        communicationDetails: payload.communicationDetails,
      };
    case UPDATE_SUBSCRIBER_DETAILS:
      return {
        ...state,
        subscriberDetails: payload.subscriberDetails,
      };
    case RESET_SUBSCRIBER_DETAILS:
      return {
        ...state,
        subscriberDetails: { ...initSubscriberDetails },
      };
    case OPEN_EHR_LINK_POPUP:
      return {
        ...state,
        linkPatientModal: payload.data,
      };
    case CLOSE_EHR_LINK_POPUP:
      return {
        ...state,
        linkPatientModal: initialState.linkPatientModal,
      };
    case APPT_ACTIVE_STEP:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          activeStep: payload.step,
        },
      };
    case APPT_PATIENTS_LIST:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          patientsList: payload.patients,
          selectedPatient: payload.patient || payload.patients[0],
        },
      };
    case APPT_SELECT_PATIENT:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          selectedPatient: payload.patient,
        },
      };
    case APPT_SERVICES_LOADING:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          servicesLoading: payload.loading,
        },
      };
    case APPT_SERVICES_LIST:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          servicesLoading: false,
          servicesList: payload.services,
        },
      };
    case APPT_SELECT_SERVICE:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          ...payload,
        },
      };
    case APPT_SELECT_PROVIDER:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          selectedProvider: payload.provider,
        },
      };
    case APPT_SLOTS_LOADING:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          slotsLoading: payload.loading,
        },
      };
    case APPT_SELECT_INTERVAL:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          selectedIntervals: payload.interval,
        },
      };
    case APPT_SLOTS_LIST:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          slotsLoading: false,
          slotsList: payload.slots,
        },
      };
    case APPT_SELECT_SLOT:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          ...payload,
        },
      };
    case APPT_SELECT_DATE:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          selectedDate: payload.date,
        },
      };
    case APPT_CHANGE_MONTH:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          selectedDate: payload.date,
          startDate: payload.start,
          endDate: payload.end,
        },
      };
    case APPT_CREATE_SUCCESS:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          ...payload,
        },
      };
    case BOOK_ANOTHER_APPOINTMENT:
      return {
        ...state,
        bookAppointmentModal: {
          ...state.bookAppointmentModal,
          ...apptInitialState,
        },
      };

    /* Create Subscriber */
    case UPDATE_PRIMARY_INFO:
      return {
        ...state,
        planInfo: {
          ...state.planInfo,
          ...payload,
        },
      };
    case ADD_ADDITIONAL_MEMBER:
      return {
        ...state,
        addMembersInfo: {
          ...state.addMembersInfo,
          list: [...state.addMembersInfo.list, { ...addMemberDetails }],
          blurred: [...state.addMembersInfo.blurred, { ...addMemberBlurred }],
          errors: [...state.addMembersInfo.errors, { ...addMemberError }],
        },
      };
    case UPDATE_ADDITIONAL_MEMBER:
      return {
        ...state,
        addMembersInfo: {
          list: payload.list || state.addMembersInfo.list,
          blurred: payload.blurred || state.addMembersInfo.blurred,
          errors: payload.errors || state.addMembersInfo.errors,
        },
      };
    case RESET_ADDITIONAL_MEMBERS:
      return {
        ...state,
        addMembersInfo: {
          ...state.addMembersInfo,
          list: state.addMembersInfo.initData || [],
          blurred: [],
          errors: [],
        },
      };
    case UPDATE_COUPON_CODE:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          coupon_code: payload.value,
          isCouponValid: null,
          couponDetail: {},
          couponError: '',
        },
      };
    case RESET_COUPON_CODE:
      return {
        ...state,
        promoCode: {
          coupon_code: '',
          loading: false,
          isCouponValid: null,
          couponDetail: {},
          couponError: '',
        },
      };
    case VALIDATING_COUPON_CODE:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          loading: payload.loading,
          couponDetail: payload.loading ? {} : state.promoCode.couponDetail,
        },
      };
    case SET_COUPON_CODE_DETAIL:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          coupon_code: payload.couponCode || state.promoCode.coupon_code,
          isCouponValid: payload.isValid,
          couponDetail: payload.detail,
          couponError: payload.error,
        },
      };

    case MONETARY_LOADING: {
      return {
        ...state,
        monetary: {
          ...state.monetary,
          loading: payload.loading,
        },
      };
    }

    case MONETARY_DATA_UPDATE: {
      return {
        ...state,
        monetary: {
          ...state.monetary,
          loading: payload.loading,
          data: payload.data,
        },
      };
    }

    case INVOICE_LOADING: {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: payload.loading,
        },
      };
    }
    case INVOICE_DATA_UPDATE: {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: payload.loading,
          data: payload.data,
        },
      };
    }

    case GROUP_SETTINGS_LOADING: {
      return {
        ...state,
        group: {
          ...state.group,
          loading: payload.loading,
        },
      };
    }

    case GROUP_SETTINGS_UPDATE: {
      return {
        ...state,
        group: {
          ...state.group,
          loading: payload.loading,
          data: payload.data,
        },
      };
    }

    case LOCATION_SETTINGS_LOADING: {
      return {
        ...state,
        group: {
          ...state.location,
          loading: payload.loading,
        },
      };
    }

    case LOCATION_SETTINGS_UPDATE: {
      return {
        ...state,
        location: {
          ...state.location,
          data: payload.data,
        },
      };
    }

    case UPDATE_SUBSCRIBER_IMPORT_DATA: {
      return {
        ...state,
        import: {
          ...state.import,
          ...payload,
        },
      };
    }

    case RESET_SUBSCRIBER_IMPORT_DATA: {
      return {
        ...state,
        import: { ...initImportState },
      };
    }

    default:
      throw new Error();
  }
};

export { reducer, initialState };
