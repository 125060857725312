const getGroupKeys = () => ({
  country_code: '+1',
  phone: '',
  partner_id: '',
  group_name: '',
  legal_name: '',
  industry: '',
  plan_website: '',
  patient_website: '',
  patient_url_part: '',
  fee_schedule_desc: '',
  plan_summary_desc: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipcode: '',
  },
  email: '',
});

const requiredFields = {
  required: true,
  isValid: true,
  value: '',
  text: '',
  error: false,
  touched: false,
};

const getGpReqFields = () => ({
  partner_id: { ...requiredFields },
  group_name: { ...requiredFields },
  legal_name: { ...requiredFields, required: false },
  plan_website: { ...requiredFields },
  patient_website: { ...requiredFields },
  patient_url_part: { ...requiredFields },
  fee_schedule_desc: { ...requiredFields, required: false },
  plan_summary_desc: { ...requiredFields, required: false },
  industry: { ...requiredFields },
  email: { ...requiredFields },
  address: {
    line1: { ...requiredFields },
    city: { ...requiredFields },
    state: { ...requiredFields },
    zipcode: { ...requiredFields },
  },
  phone: { ...requiredFields },
});

const getSettingReqFields = () => ({
  vendor: { ...requiredFields },
  config_name: { ...requiredFields },
  form_url: { ...requiredFields },
});

const getSettingDetailFields = () => ({
  connect_enabled: false,
  rep_info: {},
  pms_auto_link: false,
  new_subscriber: false,
  sub_stats_monthly: false,
  sub_stats_weekly: false,
  pms_patient_details: false,
  employee_list: false,
  auto_location_plan: true,
  allow_get_addons: true,
  provider_payment_types: [],
  logo: '',
  pms_discount_mode: false,
  platform_cost_enabled: false,
  platform_cost_level: 'region',
  platform_cost_amount: 1,
  platform_cost_day: 1,
  platform_revshare_enabled: false,
  platform_revshare_id: '',
  platform_revshare_type: 'fixed',
  platform_revshare_amount: 1,
  enable_consent_form: false,
  vendor: '',
  config_name: '',
  form_url: ''
});

const getContactKeys = () => ({
  first_name: '',
  last_name: '',
  title: '',
  contact_email: '',
  country_code: '+1',
  phone: '',
  is_internal: true,
  errorCheck: {
    first_name: { ...requiredFields },
    last_name: { ...requiredFields },
    title: { ...requiredFields },
    contact_email: { ...requiredFields },
    phone: { ...requiredFields },
  },
});

const getKeysName = (name) => {
  if (name === 'patient_url_part') {
    return 'Domain Prefix';
  } else if (name === 'industry') {
    return 'Industry';
  } else if (name === 'partner_id') {
    return 'Partner Id';
  } else if (name === 'group_name') {
    return 'Group Name';
  } else if (name === 'legal_name') {
    return 'Legal Name';
  }
};

const getDiscountType = (discountFlag) => {
  if (discountFlag) return 'insurance';
  else return 'discount';
};

const GroupUtils = {
  getGroupKeys,
  getGpReqFields,
  getSettingReqFields,
  getSettingDetailFields,
  getContactKeys,
  getDiscountType,
  getKeysName,
};

export default GroupUtils;
