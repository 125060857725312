import httpService from "../Api/http.service";
import Utils from "Shared/Utils";

const { REACT_APP_API_BASE_URL } = process.env;

const fetchGroupByPartnerId = (paramsObj, product = 'recur') => {
  let qParams = Utils.getQueryString({ ...paramsObj, product });
  qParams = qParams ? `?${qParams}` : '';

  return httpService({
    url: `/groups${qParams}`,
    method: "get",
  });
};

const createGroup = (payload) =>
  httpService({
    url: `/group`,
    method: "post",
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
  });

const deleteGroup = (payload) =>
  httpService({
    url: `/group`,
    method: "delete",
    data: payload,
  });

const getAllMaterials = (group_id) =>
  httpService({
    url: `/group/materials?group_id=${group_id}`,
    method: "get",
  });

const createFileUploadLink = () =>
  httpService({
    url: `/file/upload-link`,
    method: 'post',
  });

const uploadFile = (url, payload) =>
  httpService({
    url: url,
    data: payload,
    method: 'post',
    headers: { "Content-Type": "multipart/form-data" }
  });

const updateMarketingMaterial = (group_id, payload) =>
  httpService({
    url: `/group/${group_id}/materials`,
    method: 'put',
    data: payload,
  });

const deleteMarketingMaterial = (group_id, payload) =>
  httpService({
    url: `/group/${group_id}/materials`,
    method: 'delete',
    data: payload
  });

const requestPrintMaterial = (group_id, payload) =>
  httpService({
    url: `/group/${group_id}/print-materials`,
    method: 'put',
    data: payload
  });

const updateSendgridId = (id, payload) =>
  httpService({
    url: `/group/${id}/comms`,
    method: "put",
    data: payload
  });

const getDisclosureDetails = (group_id) => 
  httpService({
    url: `${REACT_APP_API_BASE_URL}/subscriberportal/terms-details/${group_id}`,
    method: "get",
  });

const getGroupDisclosurePdf = (group_id) =>
  httpService({
    url: `${REACT_APP_API_BASE_URL}/subscriberportal/groups/${group_id}/disclosure/pdf`,
    method: "get",
  });

const groupService = {
  fetchGroupByPartnerId,
  createGroup,
  deleteGroup,
  getAllMaterials,
  createFileUploadLink,
  uploadFile,
  updateMarketingMaterial,
  deleteMarketingMaterial,
  requestPrintMaterial,
  updateSendgridId,
  getDisclosureDetails,
  getGroupDisclosurePdf
};

export default groupService;
