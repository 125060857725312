import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";

const SearchTextField = (props) => {
  const {
    searchInput,
    handleSearchInputChange,
    placeholder,
    handleFilterClick,
  } = props;

  return (
    <FormControl fullWidth variant="outlined">
      <OutlinedInput
        value={searchInput}
        onChange={handleSearchInputChange}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />{" "}
          </InputAdornment>
        }
        endAdornment={
          <>
            {handleFilterClick && (
              <InputAdornment position="end">
                <IconButton onClick={handleFilterClick} data-testid="table-search-icon">
                  <FilterListIcon />{" "}
                </IconButton>
              </InputAdornment>
            )}
          </>
        }
        color="secondary"
        placeholder={placeholder}
        inputProps={{
          'data-testid': 'table-search-input'
        }}
      />
    </FormControl>
  );
};

export default SearchTextField;
