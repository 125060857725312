import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography } from '@material-ui/core';

// Styles
import styles from './EmptyPlaceholderView.module.scss';

const EmptyPlaceholderView = (props) => {
  const {
    imageUrl,
    primaryContent,
    secondaryContent,
    button,
    buttonStartIcon,
    handleButtonClick,
    otherButtons
  } = props;

  return (
    <section className={styles.wrapper}>
      <Grid
        className="text_align_center"
        container
        spacing={3}
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12}>
          <img src={imageUrl} alt="No data found" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" color="textPrimary">
            {primaryContent}
          </Typography>
          <Typography className="mg_top_16" variant="h6" color="textSecondary">
            {secondaryContent}
          </Typography>
        </Grid>
        {(button || otherButtons) && (
          <Grid className="mg_top_16" item xs={12}>
            {otherButtons}

            {button && (
              <Button
                className='mg_left_16'
                startIcon={buttonStartIcon}
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleButtonClick}
                data-testid={`empty-placeholder-${button}`}
              >
                {button}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </section>
  );
};

EmptyPlaceholderView.propTypes = {
  imageUrl: PropTypes.string,
  primaryContent: PropTypes.string,
  secondaryContent: PropTypes.string,
  button: PropTypes.string,
  buttonStartIcon: PropTypes.node,
  handleButtonClick: PropTypes.func,
  otherButtons: PropTypes.node,
};

export default EmptyPlaceholderView;
