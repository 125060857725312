import React, { createRef, lazy, Suspense, useEffect, useContext } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { signOut } from "aws-amplify/auth";

import { Context } from "context";
import {
  ADD_NOTIFICATION,
  RESET_SITE_DATA,
  UPDATE_PAYMENT_TYPES,
  UPDATE_STRIPE_ONB_STATUS,
} from "context/actions";
import CommonLoader from "Views/Common/CommonLoader";
import { ONBOARD_STAGE } from "Views/Common/enum";
import { Header, SideNav } from "common-layout";
import { checkIfAdmin } from "Views/Login/LoginHelper";
import LogoutSession from "Views/LogoutSession/LogoutSession"
import TermsConditions from "Views/TermsConditions";
import PrivacyPolicy from "Views/PrivacyPolicy";
import HipaaNotice from "Views/HipaaNotice";
import { STRIPE_ONBOARDING_SERVICES, USER_SERVICES } from "Services";

// lazy loading components.
const Dashboard = lazy(() => import("Views/Dashboard/Dashboard"));

const RevshareInfo = lazy(() => import("Views/Revshare/RevshareInfo/RevshareInfo"));
const RevshareList = lazy(() => import("Views/Revshare/RevshareList/RevshareList"));

const CreateLocation = lazy(() => import("Views/Location/CreateLocation/CreateLocation"));
const LocationInfo = lazy(() => import("Views/Location/LocationInfo/LocationInfo"));
const LocationList = lazy(() => import("Views/Location/LocationList/LocationList"));

const CreatePartner = lazy(() => import("Views/Partner/CreatePartner/CreatePartner"));
const PartnerInfo = lazy(() => import("Views/Partner/PartnerInfo/PartnerInfo"));
const PartnerPlan = lazy(() => import("Views/Partner/PartnerList/PartnerList"));

const Plans = lazy(() => import("Views/Plans"));

const PatientDashboard = lazy(() => import("Views/FlexPay/PatientDashboard"));
const Packages = lazy(() => import("Views/FlexPay/Packages"));
const CustomerInvoices = lazy(() => import("Views/FlexPay/Invoices"));

const ScheduleTraining = lazy(() => import("Views/ScheduleTraining/ScheduleTraining"))
const FAQs = lazy(() => import("Views/FAQ/FAQs"))

const CreateGroup = lazy(() => import("Views/Group/CreateGroup/CreateGroup"));
const GroupListing = lazy(() => import("Views/Group/GroupList/GroupListing"));
const GroupInfo = lazy(() => import("Views/Group/GroupInfo/GroupInfo"));

const RegionInfo = lazy(() => import("Views/Region/RegionInfo/RegionInfo"));
const RegionListing = lazy(() => import("Views/Region/RegionList/RegionListing"));
const CreateRegion = lazy(() => import("Views/Region/CreateRegion/CreateRegion"));

const Employer = lazy(() => import("Views/Employer"));
const Employee = lazy(() => import("Views/Employee"));

const Users = lazy(() => import("Views/Users"));

const GroupSetting = lazy(() => import("Views/Settings/GroupSetting"));

const StripeOnboarding = lazy(() => import("Views/Stripe"));

const LevelOnboarding = lazy(() => import("Views/LevelOnboarding"));

const Support = lazy(() => import("Views/Support"));
const Marketing = lazy(() => import("Views/Marketing"));

const Transactions = lazy(() => import("Views/Transactions"));

// const CustomReports = lazy(() => import("Views/CustomReports"));
const CustomReportsV2 = lazy(() => import("Views/CustomReportsV2"));

const TransferReports = lazy(() => import("Views/TransferReports"));

const EnrollmentTrends = lazy(() => import("Views/EnrollmentTrends"));

const Subscriber = lazy(() => import("Views/Subscriber"))

const SubscriptionMaterials = lazy(() => import("Views/SubscriptionMaterials"))

const ExternalPatientInfo = lazy(() => import("Views/ExternalPatientInfo"));

const SavingsCalculator = lazy(() => import("Views/SavingsCalculator/SavingsCalculator"));

const Main = () => {
  const {
    state: { user, logoutUser, logoutLoading, stripeOnboard },
    dispatch,
  } = useContext(Context);

  const {
    refresh: stripeRefresh = false,
    init: stripeInit = false,
  } = stripeOnboard;

  const {
    subscribili_tc_accepted_flag,
    onboard_stage = "",
  } = user?.settings?.settings || {};

  const { features: userFeatures = [] } = user?.details || {};

  const ref = createRef();
  const history = useHistory();

  const { pathname } = useLocation();
  const isStripeOnboarding = pathname.includes("stripe-onboarding");
  const isLevelOnboarding = pathname.includes("level-onboarding");
  const isScheduleTraining = pathname.includes("schedule-training");
  const isOnboarding = isStripeOnboarding || isLevelOnboarding;
  const admin_level_roles = checkIfAdmin(user);
  const isOnboardingUser = userFeatures.includes('onb');

  const setGlobalNotification = (severity, message) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          severity,
          message
        }
      }
    });

  const redirectToPage = (route, state = {}) =>
    history.push({
      pathname: route,
      state
    });

  const getLevelData = (data) => {
    const {
      partner_id = "",
      group_id = "",
      region_id = "",
      location_id = "",
    } = data;

    if (location_id) {
      return {
        level: "location",
        label: "Location",
        level_id: location_id,
        partner_id,
      };
    } else if (region_id) {
      return {
        level: "region",
        label: "Region",
        level_id: region_id,
        partner_id,
      };
    } else if (group_id) {
      return {
        level: "group",
        label: "Group",
        level_id: group_id,
        partner_id,
      };
    } else if (partner_id) {
      return {
        level: "partner",
        label: "Partner",
        level_id: partner_id,
        partner_id,
      };
    }
  };

  const getProcessedData = (data = {}) => {
    const { locations = [], regions = [], groups = [], partners = [] } = data;

    const allLevels = {
      locations,
      regions,
      groups,
      partners
    };

    const processedData = {};
    Object.keys(allLevels).forEach(levelKey => {
      const levelData = allLevels?.[levelKey] || [];

      const pData = levelData.map(indLevel => {
        const {
          level = "location",
          level_id = "",
          label = "Location",
        } = getLevelData(indLevel);
        const name = indLevel?.[`${level}_name`] || '';
        const upadetdData = {...indLevel, level, level_id, label, name};

        return {
          ...upadetdData,
          level_data: upadetdData,
          action: upadetdData,
        };
      });

      processedData[levelKey] = pData;
    });

    return processedData;
  };

  const fetchOnboardingLevels = async () => {
    dispatch({
      type: UPDATE_STRIPE_ONB_STATUS,
      payload: {
        refresh: false,
        loading: true
      }
    });

    try {
      const response = await STRIPE_ONBOARDING_SERVICES.getOnboardingLevels();

      if(response?.type === 'success' && response?.data) {
        const { stats = {}, ...otherData } = response.data;
        const { pending = 0 } = stats;

        if (pending) {
          const processedData = getProcessedData(otherData) || {};

          dispatch({
            type: UPDATE_STRIPE_ONB_STATUS,
            payload: {
              init: true,
              loading: false,
              stats,
              ...processedData
            }
          });

          redirectToPage("/stripe-onboarding");

          return;
        }
        else {
          isStripeOnboarding && redirectToPage('/dashboard');
        }
      }
      else {
        throw response?.error || response?.message;
      }
    }
    catch(err) {
      err && setGlobalNotification('error', err );
    }

    dispatch({
      type: UPDATE_STRIPE_ONB_STATUS,
      payload: {
        init: true,
        loading: false
      }
    });
  };

  const fetchPaymentTypeList = async () => {
    dispatch({
      type: UPDATE_PAYMENT_TYPES,
      payload: {
        loading: true,
      },
    });

    try {
      const pResponse = await USER_SERVICES.getPaymentTypes();

      if (pResponse?.type === "success") {
        dispatch({
          type: UPDATE_PAYMENT_TYPES,
          payload: {
            loading: false,
            list: pResponse?.data
          },
        });
      }
      else {
        throw pResponse;
      }
    }
    catch(err) {
      console.log(err?.message || err?.error || "Error while fetching payment types");

      dispatch({
        type: UPDATE_PAYMENT_TYPES,
        payload: {
          loading: false,
        },
      });
    }
  };

  useEffect(() => {
    stripeRefresh && fetchOnboardingLevels();
  }, [stripeRefresh]);

  useEffect(() => {
    // Get payment types
    fetchPaymentTypeList();

    // stripe-onboarding validation
    if (admin_level_roles && isOnboardingUser) {
      fetchOnboardingLevels();

      return;
    }

    dispatch({
      type: UPDATE_STRIPE_ONB_STATUS,
      payload: {
        init: true,
      }
    });
  }, []);

  useEffect(() => {
    if (
      admin_level_roles &&
      ![ONBOARD_STAGE.COMPLETED, ""].includes(onboard_stage) &&
      !subscribili_tc_accepted_flag
    ) {
      redirectToPage("/level-onboarding");

      return;
    }

    if (
      (isLevelOnboarding &&
        (!admin_level_roles || subscribili_tc_accepted_flag)) ||
      (isStripeOnboarding && (!admin_level_roles || !isOnboardingUser))
    ) {
      redirectToPage("/dashboard");

      return;
    }

    ref.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  const getWrapperClassName = () => {
    if (isStripeOnboarding)
      return "stripe_onboard_wrapper";
    else if (isLevelOnboarding)
      return "location_onboard_wrapper";
    else if (isScheduleTraining)
      return "schedule_training_wrapper dashboard_wrapper"

    return "dashboard_wrapper";
  };

  const handleLogOut = async () => {
    try {
      await signOut();

      window.zE?.('messenger', 'logoutUser');
  
      // window?.fcWidget?.user
      //   .clear()
      //   .then(() => {
      //     console.log("Freshchat User cleared");
      //   })
      //   .catch(() => {
      //     console.log("Freshchat User Not cleared");
      //   })
      //   .finally(() => {
      //     window?.fcWidget?.destroy();
      //   });
  
      localStorage.setItem("subscribiliToken", "");
      localStorage.setItem("userDetail", "");
      localStorage.setItem("tokenValidity", "");
  
      dispatch({
        type: RESET_SITE_DATA,
      });
    }
    catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    logoutUser && handleLogOut()
  },[logoutUser]);

  return (
    <div className={logoutLoading ? "disable-form" : undefined}>
      {!isOnboarding && <Header />}
      <main className="d-flex">
        {!isOnboarding && <SideNav />}

        <div
          className={getWrapperClassName()}
          ref={ref}
        >
          <Suspense fallback={<CommonLoader />}>
            <Switch>
              {!stripeInit && <CommonLoader />}
              <Route path="/" exact component={Dashboard} />
              <Route path="/dashboard" exact component={Dashboard} />
              <Route path="/stripe-onboarding" component={StripeOnboarding} />
              <Route path="/level-onboarding" component={LevelOnboarding} />
              <Route path="/subscriber" component={Subscriber} />

              <Route path="/schedule-training" component={ScheduleTraining} />
              <Route path="/faqs" component={FAQs} />
              <Route path="/plan-materials"  component={SubscriptionMaterials}/>

              <Route path="/employer" component={Employer} />
              <Route path="/employee" component={Employee} />

              <Route path="/revshare-info" exact component={RevshareInfo} />
              <Route path="/revshare-list" exact component={RevshareList} />

              <Route path="/create-location" exact component={CreateLocation} />
              <Route path="/location-info" exact component={LocationInfo} />
              <Route path="/location-list" exact component={LocationList} />
              <Route path="/create-group" exact component={CreateGroup} />
              <Route path="/group-list" exact component={GroupListing} />
              <Route path="/group-info" exact component={GroupInfo} />
              <Route path="/settings" exact component={GroupSetting} />
              <Route path="/create-partner" exact component={CreatePartner} />
              <Route path="/partner-info" exact component={PartnerInfo} />
              <Route path="/partner-plan" exact component={PartnerPlan} />
              <Route path="/plans" component={Plans} />

              <Route path="/flex/dashboard" component={PatientDashboard} />
              <Route path="/flex/invoices" component={CustomerInvoices} />
              <Route path="/flex/packages" component={Packages} />

              <Route path="/create-region" exact component={CreateRegion} />
              <Route path="/region-info" exact component={RegionInfo} />
              <Route path="/region-list" exact component={RegionListing} />
              <Route path="/users" component={Users} />
              <Route path="/support" exact component={Support} />
              <Route path="/marketing-materials" exact component={Marketing} />
              <Route path="/transfers-payout" exact component={Transactions} />
              <Route path="/custom-reports" exact component={CustomReportsV2} />
              <Route path="/provider-payments" exact component={TransferReports} />
              <Route path="/enrollment-trends" exact component={EnrollmentTrends} />
              <Route path="/patient-lookup" exact component={ExternalPatientInfo} />
              <Route path="/savings-calculator" exact component={SavingsCalculator} />
              <Route path="/terms-of-service" exact component={TermsConditions} />
              <Route path="/hipaa-notice" exact component={HipaaNotice} />
              <Route path="/privacy-policy" exact component={PrivacyPolicy} />
              {/* <Redirect to="/dashboard" /> */}
            </Switch>
          </Suspense>
        </div>
      </main>

      <LogoutSession />
    </div>
  );
};

export default Main;
