import httpService from "../Api/http.service";

const fetchRevshareByQueryParams = () => {
  return httpService({
    url: `/revshares`,
    method: "get",
  });
};

const createRevshare = (payload) =>
  httpService({
    url: `/revshares`,
    method: "post",
    data: payload,
  });

  const updateRevshare = (payload) =>
    httpService({
      url: `/revshares`,
      method: "put",
      data: payload,
    });

const deleteRevshare = (payload) =>
  httpService({
    url: `/revshares`,
    method: "delete",
    data: payload,
  });

const revshareService = {
  createRevshare,
  updateRevshare,
  deleteRevshare,
  fetchRevshareByQueryParams
};

export default revshareService;
