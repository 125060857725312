import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import CommonLoader from 'Views/Common/CommonLoader';
import { CommonDialog } from "components";

const DisclosureModal = (props) => {
  const {
    isOpen,
    showButton = false,
    closePopup,
    emitSuccessEvent,
    isLoading,
    disclosureContent
  } = props;

  return (
    <CommonDialog
      open={isOpen}
      handleClose={closePopup}
      dialogTitle={ disclosureContent?.title?.rendered || "Terms and Conditions for Subscribers" }
      secondaryBtnTxt={showButton && "I Agree"}
      handleSecondaryButtonClick={emitSuccessEvent}
      maxWidth="md"
    >
      {isLoading
        ? <CommonLoader />
        : parse(disclosureContent?.content?.rendered || '')
      }
    </CommonDialog>
  )
};

DisclosureModal.propTypes = {
  isOpen: PropTypes.bool,
  showButton: PropTypes.bool,
  closePopup: PropTypes.func,
  emitSuccessEvent: PropTypes.func,
  isLoading: PropTypes.bool,
  disclosureContent: PropTypes.object
};

export default DisclosureModal;