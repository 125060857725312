import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  Tooltip,
  IconButton
} from "@material-ui/core";
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Info as InfoIcon
 } from '@material-ui/icons';
import styles from "./PasswordConditions.module.scss";

const PasswordConditions = (props) => {
  const {
    password = '',
    setCriteriaError,
  } = props;

  const getErrors = () => {
    return {
      isMinLength: /^.{8,}$/.test(password),
      isMaxLength: /^.{0,128}$/.test(password),
      isUpperCase: /.*[A-Z].*/.test(password),
      isLowerCase: /.*[a-z].*/.test(password),
      isNumber: /.*[0-9].*/.test(password),
      isSpecialCharacter: /[!@#$%^&*.,~()_+\-=\[\]{};':"\\|<>\/?]+/.test(password)
    }
  };

  const [errors, setErrors] = useState(getErrors());

  const getClassName = (condition) => condition ? 'color_success' : 'color_error';

  const renderIcon = (condition) => (
    condition
      ? <CheckIcon fontSize="small" className="mr-10" />
      : <CloseIcon fontSize="small" className="mr-10" />
  );

  const specialCharInfo = '^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < \' : ; | _ ~ ` = + -';

  useEffect(() => {
    const fieldErrors = getErrors();
    let isError = false;

    Object.keys(fieldErrors).forEach(key => {
      Boolean(!fieldErrors[key]) && (isError = true);
    });
    
    setErrors(fieldErrors);
    setCriteriaError(isError);
  }, [password]);

  return (
    <Box p={1}>
      <List className={styles.criteriaList}>
        <ListItem className={getClassName(errors.isMinLength)}>
          {renderIcon(errors.isMinLength)}
          Minimum of 8 characters
        </ListItem>

        <ListItem className={getClassName(errors.isMaxLength)}>
          {renderIcon(errors.isMaxLength)}
          Maximum of 128 characters
        </ListItem>

        <ListItem className={getClassName(errors.isUpperCase)}>
          {renderIcon(errors.isUpperCase)}
          Contains uppercase
        </ListItem>

        <ListItem className={getClassName(errors.isLowerCase)}>
          {renderIcon(errors.isLowerCase)}
          Contains lowercase
        </ListItem>

        <ListItem className={getClassName(errors.isNumber)}>
          {renderIcon(errors.isNumber)}
          Contains number
        </ListItem>

        <ListItem className={getClassName(errors.isSpecialCharacter)}>
          {renderIcon(errors.isSpecialCharacter)}
          Contains special character
          {
            <Tooltip title={specialCharInfo}>
              <IconButton size="small" >
                <InfoIcon fontSize="small" color="primary" data-testid="heading-info-button" />
              </IconButton>
            </Tooltip>
          }
        </ListItem>
      </List>
    </Box>
  );
};

export default PasswordConditions;
