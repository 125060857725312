import React, { useState, useContext } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import {
  CommonDialog,
  FilePreview,
  DragAndDropFiles
} from "components";
import { FileUploader } from "react-drag-drop-files";
import { GROUP_SERVICES } from "Services";
import { Context } from "context";
import { ADD_NOTIFICATION } from 'context/actions';
import styles from "./UploadMaterialModal.module.scss";

const UploadMaterialModal = (props) => {
  const {
    handleClose,
    groupId,
    onUpdate
  } = props;

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [sizeError, setSizeError] = useState(false);
  const [typeError, setTypeError] = useState(false);

  const { dispatch } = useContext(Context);

  const fileTypes = ["PDF"];
  const maxFileSize = 100;

  const setGlobalNotification = (severity, message) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          severity,
          message
        }
      }
    });

  const handleFileChange = (cFile) => setFile(cFile);

  const handleFilenameChange = (e) => setFileName(e.target.value);

  const onRemove = () => {
    setFile(null);
    setSizeError(false);
    setTypeError(false);
  };

  const closeModal = () => {
    onRemove();
    handleClose();
  };

  const handleUpload = async () => {
    if (!file) return;

    setLoading(true);
    try {
      const uploadLink = await GROUP_SERVICES.createFileUploadLink();

      const fields = uploadLink?.data?.fields;
      const fdata = new FormData();
      Object.entries(fields).map(([key, value]) => {
        fdata.set(key, value)
      })
      fdata.set('file', file)
      const uploadResponse = await GROUP_SERVICES.uploadFile(uploadLink?.data?.url, fdata)
      if (uploadResponse?.type !== 'success') {
        throw "Error in uploading marketing material";
      }
  
      const payload = {
        'files': [{
          key: fields?.key,
          name: fileName,
          filename: file.name,
        }]
      }
      const response = await GROUP_SERVICES.updateMarketingMaterial(groupId, payload);

      if (response?.type === 'success') {
        setGlobalNotification("Success", "Marketing material uploaded successfully");
        handleClose();
        onUpdate();
      } else {
        throw response?.message || response?.error;
      }
    } catch (err) {
      setGlobalNotification("error", err || "Error in uploading marketing material");
    }
    setLoading(false);
  };

  return (
    <CommonDialog
      open={true}
      dialogTitle="Upload Marketing Material"
      primaryBtnTxt="Upload File"
      handlePrimaryButtonClick={handleUpload}
      disabledPrimaryButton={loading || !file || !fileName}
      primaryButtonStyles={{ minWidth: 125 }}
      secondaryBtnTxt="Cancel"
      disableSecondaryButton={loading}
      handleClose={closeModal}
      maxWidth='sm'
      isLoading={loading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}> </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {file && !sizeError && !typeError && (
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="file_name"
                      value={fileName}
                      label="Document Title"
                      variant="outlined"
                      onChange={(e) => handleFilenameChange(e)}
                      inputProps={{
                        'data-testid': 'file-name'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FilePreview
                      name={file.name}
                      size={file.size}
                      onRemove={onRemove}
                    />
                  </Grid>
                </Grid>
              )}

              {!file && (
                <FileUploader
                  handleChange={handleFileChange}
                  name="file"
                  types={fileTypes}
                  children={<DragAndDropFiles maxSize={maxFileSize}/>}
                  maxSize={maxFileSize}
                  minSize={0}
                  onSizeError={() => setSizeError(true)}
                  onTypeError={() => setTypeError(true)}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {sizeError && (
                <Typography className={styles.texterror}>Upload Failed. Please upload a file less than {maxFileSize}MB.</Typography>
              )}

              {typeError && (
                <Typography className={styles.texterror}>Upload Failed. Please upload a file of type PDF.</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CommonDialog>
  );
}

export default UploadMaterialModal;


