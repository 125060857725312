import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import CommonDialog from "components/CommonDialog";

const ConfirmModal = (props) => {
  const {
    isOpen,
    closeModal,
    emitConfirmEvent,
    isLoading,
    customClass,
    customTitleClass,
    customDescriptionClass,
    options: {
      title = "Confirm Popup",
      description = "Are you sure?",
      confirmText = "Confirm",
      cancelText = "Cancel"
    } = {},
    maxWidth = 'xs'
  } = props

  return (
    <CommonDialog
      open={isOpen}
      handleClose={closeModal}
      dialogTitle={title}
      titleClass={customTitleClass}
      paperClass={customClass}
      primaryBtnTxt={confirmText}
      maxWidth={maxWidth}
      handlePrimaryButtonClick={emitConfirmEvent}
      disabledPrimaryButton={isLoading}
      primaryButtonProps={{ fullWidth: true }}
      isLoading={isLoading}
      secondaryBtnTxt={cancelText}
      disableSecondaryButton={isLoading}
      secondaryButtonProps={{ fullWidth: true }}
    >
      <Grid
        container
        className="text_align_center"
        spacing={3}
        alignItems="center"
        justify="center"
        alignContent="center"
      >
        <Grid item xs={12}>
          <Typography variant="body1" className={customDescriptionClass}>
            {description}
          </Typography>
        </Grid>
      </Grid>
    </CommonDialog>
  );
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  emitConfirmEvent: PropTypes.func,
  isLoading: PropTypes.bool,
  options: PropTypes.object,
  maxWidth: PropTypes.string,
  customClass: PropTypes.string,
  customTitleClass: PropTypes.string,
  customDescriptionClass: PropTypes.string,
}

export default ConfirmModal;
