import httpService from "../Api/http.service";

const { REACT_APP_API_BASE_URL } = process.env;
const getStripeOauthBaseUrl = () => `${REACT_APP_API_BASE_URL}/pay`;
const getManagementBaseUrl = () => `${REACT_APP_API_BASE_URL}/mgt`;

const validateStripeCode = (code, groupId) => 
  httpService({
    url: getStripeOauthBaseUrl() + `/verify-stripe-oauth/group/${groupId}`,
    method: "post",
    data: { code }
  });

const getOnboardingLevels = () => 
  httpService({
    url: getManagementBaseUrl() + `/levels/onboard`,
    method: "get",
  });

const stripeOnboardingServices = {
  validateStripeCode,
  getOnboardingLevels,
};

export default stripeOnboardingServices;
