import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button, Tooltip, IconButton } from '@material-ui/core';
import ConfirmModal from 'Views/Common/ConfirmModal';
import {
  Visibility as VisibilityIcon,
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { TableListView } from 'components';
import { UploadUcrFeesSchedule } from '../../components';
import PropTypes from 'prop-types';
import { Context } from 'context';
import { FEE_SCHEDULE_MAPPER } from 'global-constants/constants';
import { ADD_NOTIFICATION } from 'context/actions';
import { formatDate } from 'utils';
import { USER_SERVICES } from 'Services';
import useStyles from './styles';

const UcrFeesSchedule = (props) => {
  const {
    levelData = {},
    settings: { ucr_fees_schedule: ucrFeesSchedule } = {},
    onUpdate,
    isLoading = false
  } = props;

  const { group_id, location_id } = levelData;

  const { dispatch: globalDispatch } = useContext(Context);

  const [isOpen, setIsOpen] = useState(false);
  const [accessUrl, setAccessUrl] = useState({});
  const [deleteKey, setDeleteKey] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);

  const styles = useStyles();

  useEffect(() => {
    getAccessUrl();
  }, [ucrFeesSchedule]);

  const getAccessUrl = async () => {
    const getUrls = [];

    ucrFeesSchedule?.key && getUrls.push(ucrFeesSchedule?.key);

    if (getUrls.length) {
      const { data, type } = await USER_SERVICES.getAWSAccessURLs(getUrls);

      if (type === 'success') {
        setAccessUrl(data);
      }
    }
  };

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } },
    });

  const renderActions = (actionData, { rowIndex }) => {
    const { fileKey } = actionData;

    return (
      <Grid container alignItems="center" key={fileKey}>
        {renderActionButtons(actionData, rowIndex)}
      </Grid>
    );
  };

  const commonCellProps = () => ({ className: styles.tableColumn });
  const tableColumns = [
    {
      name: 'category',
      label: 'Category',
      options: {
        sort: false,
        setCellHeaderProps: () => ({ className: styles.categoryHeader }),
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'fileName',
      label: 'File Name',
      options: {
        sort: false,
        setCellHeaderProps: () => ({ className: styles.nameHeader }),
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'uploaded_on',
      label: 'Uploaded on',
      options: {
        sort: false,
        setCellHeaderProps: () => ({ className: styles.dateHeader }),
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        sort: false,
        setCellProps: commonCellProps,
        customBodyRender: renderActions
      },
    },
  ];

  const tableData = [
    {
      category: FEE_SCHEDULE_MAPPER.ucr_fees_schedule,
      fileName: ucrFeesSchedule?.name,
      uploaded_on: ucrFeesSchedule?.updated_on
        ? formatDate(ucrFeesSchedule?.updated_on * 1000)
        : '-',
      actions: {
        key: 'ucr_fees_schedule',
        fileKey: ucrFeesSchedule?.key,
      },
    },
  ];

  const renderActionButtons = (actionData, rowIndex) => {
    const { key, fileKey } = actionData;
    return (
      <>
        <Tooltip arrow title="View">
          <IconButton
            size="small"
            className="mr-10"
            data-testid={`view-document-button-${rowIndex}`}
            onClick={() => onViewClick(fileKey)}
          >
            <VisibilityIcon className="theme_color_orange" fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Upload">
          <IconButton
            className="mr-10"
            size="small"
            component="label"
            onClick={() => setIsOpen(true)}
          >
            <CloudUploadIcon
              className="theme_color_orange"
              fontSize="inherit"
            />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Delete">
          <IconButton size="small" onClick={() => setDeleteKey(key)}>
            <DeleteIcon className="theme_color_orange" fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const onViewClick = (key) => {
    const redirectUrl = accessUrl[key];
    redirectUrl && window.open(redirectUrl, '_blank', 'noopener noreferrer');
  };

  const onDeleteAsset = async () => {
    if (!deleteKey) return;

    setDeleteLoading(true);

    const payload = { location_id };
    try {
      const response = await USER_SERVICES.deleteFile(
        group_id,
        deleteKey,
        payload,
      );

      if (response?.type === 'success') {
        dispatchGlobalNotification('success', 'File deleted successfully');
        onUpdate?.('Settings');
        setDeleteKey();
      } else {
        throw response?.message || response?.error || 'Error in deleting file';
      }
    } catch (err) {
      dispatchGlobalNotification('error', err);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      <TableListView
        headerTitle="Fee Schedule"
        headerSection={true}
        tableOptions={{
          pagination: false,
        }}
        tableColumns={tableColumns}
        tableData={ucrFeesSchedule && tableData}
        otherButtons={
          !ucrFeesSchedule &&
            <Button
              color="secondary"
              size="large"
              data-testid="add-fee-schedule-button"
              onClick={() => setIsOpen(true)}
            >
              Add Fee Schedule
            </Button>
        }
        isLoading={isLoading}
      />
      {isOpen && (
        <UploadUcrFeesSchedule
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          levelData={levelData}
          onUpdate={onUpdate}
        />
      )}
      {Boolean(deleteKey) && (
        <ConfirmModal
          isOpen={Boolean(deleteKey)}
          closeModal={() => setDeleteKey()}
          emitConfirmEvent={onDeleteAsset}
          isLoading={deleteLoading}
          options={{
            title: `Delete ${FEE_SCHEDULE_MAPPER?.ucr_fees_schedule}`,
            description: 'Are you sure you want to delete?',
            confirmText: 'Confirm',
            cancelText: 'Cancel',
          }}
        />
      )}
    </>
  );
};

UcrFeesSchedule.propTypes = {
  levelData: PropTypes.object,
  settings: PropTypes.object,
  onUpdate: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default UcrFeesSchedule;
