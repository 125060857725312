import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import clsx from "clsx";
import {
  Grid,
  Hidden,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputAdornment,
  CircularProgress,
  IconButton,
  Tooltip,
  Box
} from "@material-ui/core";
import { CommonDialog, TextField } from "components";
import {
  Search as SearchIcon,
  Cancel as CloseIcon
} from '@material-ui/icons';
import { ReactComponent as TickIcon } from "assets/images/tick-circle.svg";
import MUIDataTable from "mui-datatables";
import { formatDob } from 'utils';
import Utils, { getTargetValue, formatPhoneNumber } from "Shared/Utils";
import { PMS_SERVICES, USER_SERVICES } from "Services";
import { Context } from "context";
import { ADD_NOTIFICATION } from 'context/actions';
import styles from "./LinkPatientModal.module.scss";

const LinkPatientModal = (props) => {
  const {
    closePopup,
    onSuccess,
    data: {
      members: {
        rows: membersList = []
      } = {},
      subscription: {
        group_id: groupId = "",
        location_id: locationId = "",
        partner_id: partnerId = "",
      } = {},
    } = {},
    modalProps: {
      title = "",
      subTitle = ""
    } = {},
    updatePmsModal = false
  } = props;

  const {
    state: {
      user,
      linkPatientModal: {
        patient_id: linkPatientId = '',
        pms_id: linkPmsId = '',
      } = {},
    } = {},
    dispatch: globalDispatch,
  } = useContext(Context);

  const { level, levelId } = user;

  const primaryMember = membersList.find(mem => !mem.is_dependent) || {};
  const dependentMembers = membersList.filter(mem => mem.is_dependent) || [];
  const sortedMembers = [primaryMember, ...dependentMembers];

  const [fetchLoading, setFetchLoading] = useState(false);
  const [searchAllData, setSearchAllData] = useState([]);
  const [searchIdData, setSearchIdData] = useState({});
  const [noMatches, setNoMatches] = useState(false);
  const [searchIdLoading, setSearchIdLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [radioVal, setRadioVal] = useState(linkPmsId);
  const [inputVal, setInputVal] = useState("");
  const [createLoading, setCreateLoading] = useState(false);
  const [linkingLoading, setLinkingLoading] = useState(false);

  const [selPatientId, setSelPatientId] = useState(linkPatientId);
  const [patientList, setPatientList] = useState([...sortedMembers]);
  const [initPatientList] = useState([...sortedMembers]);
  const [settingsDetail, setSettingsDetail] = useState();

  const onRadioSelect = (uValue, uId) => {
    if (uId >= searchAllData.length) {
      if(inputVal !== "" && searchIdData.first_name) {
        setRadioVal(searchIdData.pms_id);
      }
      else {
        setRadioVal("");
      }

      return;
    }

    setRadioVal(uValue);
  };

  const showCreatePMSButton = () => {
    if (!settingsDetail) return false;

    const excludedVendors = ["athena", "nexhealth"]
    const vendor = settingsDetail?.settings?.pms_vendor?.[0];

    return !excludedVendors.includes(vendor);
  }

  const commonCellProps = () => ({ className: styles.tableColumns });
  const idCellProps = () => ({ className: styles.idColumn });
  const tableColumns = [
    {
      name: "radio_id",
      label: " ",
      options: {
        setCellProps: () => ({ className: styles.radioColumn }),
        setCellHeaderProps: () => ({ className: styles.radioColumn }),
        customBodyRender: (value, tableMeta) => {
          const { rowIndex } = tableMeta;

          return (
            <FormControlLabel
              label=""
              value={value}
              control={
                <Radio
                  checked={Boolean(radioVal === value)}
                  value={value}
                  color="primary"
                  inputProps={{
                    "data-testid": `link-patient-radio-button-${rowIndex}`
                  }}
                />}
              onChange={() => onRadioSelect(value, rowIndex)}
            />
          )
        }
      }
    },
    {
      name: "pms_pat_id",
      label: "Patient ID",
      options: {
        setCellHeaderProps: idCellProps,
        customBodyRender: (value, tableMeta) => {
          const { rowIndex } = tableMeta;

          if (rowIndex < searchAllData.length) {
            return <Typography variant="body1"> {value} </Typography>
          }
          else {
            return (
              <TextField
                required
                fullWidth
                autoFocus
                variant="outlined"
                margin="dense"
                value={inputVal}
                name="pms_id"
                onKeyDown={(event) => {
                  if(event.keyCode == 13)
                    handleSearchPmsClick()
                }}
                onChange={(event) => {
                  const tValue = getTargetValue(event);

                  setInputVal(tValue);
                  setSearchIdData({});
                  setTableData([...searchAllData, emptyRowData]);
                  (radioVal === rowIndex) && setRadioVal("");
                  setNoMatches(false);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className={styles.searchIcon}>
                      <IconButton size="small" data-testid={`link-patient-search-pms-icon-${rowIndex}`}>
                        {
                          searchIdLoading
                            ? <CircularProgress thickness={5} size={20} />
                            : <SearchIcon onClick={handleSearchPmsClick} />
                        }
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                className={styles.searchInput}
                placeholder="Patient ID"
                helperText={noMatches && "No matches found"}
                error={noMatches}
                inputProps={{
                  'data-testid': `link-patient-pms-id-${rowIndex}`
                }}
              />
            )
          }
        }
      }
    },
    {
      name: "first_name",
      label: "First Name",
      options: {
        setCellProps: commonCellProps
      }
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        setCellProps: commonCellProps
      }
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        setCellProps: commonCellProps,
        customBodyRender: (value) => value ?? "-",
      },
    },
    {
      name: "email",
      label: "Email Address",
      options: {
        setCellProps: commonCellProps
      }
    },
    {
      name: "dob",
      label: "Date of Birth",
      options: {
        setCellProps: commonCellProps
      }
    },
    {
      name: "phone",
      label: "Phone No.",
      options: {
        setCellProps: commonCellProps
      }
    },
    {
      name: "address",
      label: "Address",
      options: {
        setCellProps: commonCellProps
      }
    }
  ];

  const emptyRowData = {
    pms_id: inputVal,
    pms_display_id: inputVal,
    pms_pat_id: inputVal,
    first_name: "-",
    last_name: "-",
    email: "-",
    gender: "-",
    dob: "-",
    phone: "-",
    address: "-"
  };

  const fetchModmedData = async (patient_id) => {
    setFetchLoading(true);
    const { data: pmsData } = await PMS_SERVICES.findPmsPatientDetails(groupId, locationId, patient_id);

    if (pmsData?.length) {
      const tabData = pmsData.map((pData) => {
        const {
          pms_id = "",
          pms_display_id = "",
          pms_info = {},
          given = [],
          family: last_name = "",
          email: mailId = "",
          gender,
          date_of_birth = "",
          phone = "",
          address: {
            line1 = "",
            city = "",
            state = "",
            country = "",
            zipcode = ""
          } = {}
        } = pData;
        const [first_name] = given;

        return {
          pms_id: pms_id,
          pms_display_id: pms_display_id,
          pms_pat_id: pms_display_id || pms_id,
          pms_info,
          first_name,
          last_name,
          email: mailId,
          gender,
          dob: date_of_birth ? formatDob(date_of_birth, "YYYY-MM-DD") : '-',
          phone: formatPhoneNumber(phone),
          address: `${line1}, ${city}, ${state}, ${country} ${zipcode}`
        }
      });

      getTableData(tabData);
    }
    else {
      getTableData([]);
    }
    setFetchLoading(false);
  };

  const getTableData = (respData) => {
    let tData = respData.map(mData => {
      return {
        ...mData,
        radio_id: mData.pms_id
      };
    });

    setSearchAllData(tData);
    setTableData([...tData, emptyRowData]);
  };

  const options = {
    textLabels: {
      body: {
        noMatch: "No patient ID found"
      }
    },
    filter: false,
    sort: false,
    selectableRows: "none",
    responsive: "standard",
    print: false,
    pagination: false,
    search: false,
    download: false,
    viewColumns: false,
    elevation: 0,
    serverSide: true,
  };

  const handleSearchPmsClick = async () => {
    if(!inputVal) return;

    setSearchIdLoading(true);
    try {
      const { data } = await PMS_SERVICES.getPmsPatientDetails(groupId, locationId, inputVal, selPatientId);

      if (data) {
        const {
          pms_id = '',
          pms_display_id = '',
          pms_info = {},
          given = [],
          family: last_name = "",
          email: mailId = "",
          gender,
          date_of_birth = "",
          phone = "",
          address: {
            line1 = "",
            city = "",
            state = "",
            country = "",
            zipcode = ""
          } = {}
        } = data;
        const [first_name] = given;

        const searchTabData = {
          pms_id: pms_id,
          pms_display_id: pms_display_id,
          pms_pat_id: pms_display_id || pms_id,
          pms_info,
          first_name,
          last_name,
          email: mailId,
          gender,
          dob: date_of_birth ? formatDob(date_of_birth, "YYYY-MM-DD") : '-',
          phone: formatPhoneNumber(phone),
          address: `${line1}, ${city}, ${state}, ${country} ${zipcode}`,
          radio_id: pms_id
        };

        setSearchIdData(searchTabData);
        setTableData([...searchAllData, searchTabData]);
      }
      else {
        setSearchIdData({});
        setTableData([...searchAllData, emptyRowData]);
        setNoMatches(true);
      }
    } catch (err) {
      console.log(err);
    }
    setSearchIdLoading(false);
  };

  const onCreatePatientClick = async () => {
    setCreateLoading(true);
    try {
      const payload = {
        group_id: groupId,
        patient_id: selPatientId
      };
      const createResponse = await PMS_SERVICES.createPmsDetails(payload);

      const { data, error, message } = createResponse;
      if (data) {
        let updatedPatients = _.cloneDeep(patientList);

        updatedPatients.forEach((patient, index) => {
          if(patient?.patient_id === selPatientId) {
            updatedPatients[index].pms_id = data?.pms_id;
          }
        });
        setPatientList(updatedPatients);

        const allPatientsLinked = updatedPatients.every(patient => patient?.pms_id !== '');
        if(allPatientsLinked)
          onSuccess();
        else
          setGlobalNotification("success", "Subscriber Created & Patient ID linked Successfully");
      }
      else {
        throw (message || error);
      }
    }
    catch (err) {
      setGlobalNotification('error', err || 'Error in creating and linking patient ID. Please try again in sometime');
    }
    setCreateLoading(false);
  };

  const getLinkPMSPayload = (selectedData) => {
    const { pms_id = '', pms_display_id = '', pms_info = {} } = selectedData;

    if (!pms_id) return;

    const payload = {
      group_id: groupId,
      patient_id: selPatientId,
      pms_id,
      pms_info
    };

    if (pms_display_id) {
      payload.pms_display_id = `${pms_display_id}`;
    }

    return payload
  }

  const onLinkPatientClick = async () => {
    if(!radioVal) return;

    setLinkingLoading(true);
    try {
      let selectedData = {};
      if(inputVal !== "" && searchIdData.first_name) {
        selectedData = searchIdData;
      }
      else {
        selectedData = tableData.find(tData => tData.pms_id === radioVal) || {};
      }

      const payload = getLinkPMSPayload(selectedData);
      if(!payload) return;

      const linkResponse = await PMS_SERVICES.linkPmsDetails(payload);

      const { data, error, message } = linkResponse;
      if (data) {
        let updatedPatients = _.cloneDeep(patientList);

        updatedPatients.forEach((patient, index) => {
          if(patient?.patient_id === selPatientId) {
            updatedPatients[index].pms_id = selectedData.pms_id;
          }
        });
        setPatientList(updatedPatients);

        setGlobalNotification("success", "Subscriber linked successfully");
        const allPatientsLinked = updatedPatients.every(patient => patient?.pms_id !== '');
        allPatientsLinked && onSuccess();
      }
      else {
        throw (message || error);
      }
    }
    catch (err) {
      setGlobalNotification('error', err || 'Error in creating and linking patient ID. Please try again in sometime');
    }
    setLinkingLoading(false);
  };

  const handlePatientSelect = (event) => {
    const selectedPId = event.target.value;
    const { pms_id = '' } = patientList.find(patient => patient?.patient_id === selectedPId);

    setInputVal("");
    setRadioVal(pms_id);
    setSelPatientId(selectedPId);
    fetchModmedData(selectedPId);
  };

  const handleClose = () => {
    if(updatePmsModal) {
      const pmsLinked = patientList.some((patient, index) => patient?.pms_id !== initPatientList[index].pms_id);

      if(pmsLinked) {
        onSuccess();
        return;
      }
    }

    closePopup();
  };

  const setGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          severity,
          message
        }
      }
    });

  const getSettingsDetail = async () => {
    let level_name, level_id;

    if (Utils.checkIfInternalUser(user)) {
      level_name = 'location'
      level_id = locationId
    } else {
      level_name = level
      level_id = levelId
    }

    let response = await USER_SERVICES.getSettings(
      level_name,
      level_id,
      'partner_id=' + partnerId
    );  
    Utils.checkIfSuccess(response) && setSettingsDetail(response.data);
  };

  useEffect(() => {
    getSettingsDetail();
    fetchModmedData(selPatientId);
  }, []);

  const pmsExists = () => {
    if (!tableData.length) return false;

    const selPatient = patientList.find(patient => patient?.pms_id === radioVal);
    const selectedData = tableData.find(tData => tData?.pms_id === selPatient?.pms_id);

    return Boolean(selectedData);
  };

  const renderPatientLabel = (patient) => (
    <Grid container alignItems="center">
      {patient?.first_name} {patient?.last_name}
      {(patient?.pms_display_id || patient?.pms_id) && (
        <Tooltip title={patient?.pms_display_id || patient?.pms_id}>
          <TickIcon className={styles.tickIcon} />
        </Tooltip>
      )}
    </Grid>
  );

  return (
    <CommonDialog
      open={true}
      handleClose={handleClose}
      maxWidth="lg"
      primaryBtnTxt={'Link Selected Patient'}
      handlePrimaryButtonClick={onLinkPatientClick}
      disabledPrimaryButton={
        !radioVal ||
        pmsExists() ||
        linkingLoading ||
        createLoading ||
        fetchLoading
      }
      isLoading={linkingLoading}
      primaryButtonStyles={{ minWidth: 195 }}
      secondaryBtnTxt={showCreatePMSButton() && 'Create New PMS Patient'}
      handleSecondaryButtonClick={onCreatePatientClick}
      disableSecondaryButton={createLoading || linkingLoading || fetchLoading}
      isSecondaryBtnLoading={createLoading}
      secondaryButtonStyles={{ minWidth: 218 }}
      noPadding={true}
    >
      <Grid container spacing={0}>
        {Boolean(updatePmsModal) && (
          <IconButton
            size="small"
            onClick={handleClose}
            className={styles.closeIcon}
            data-testid="close-pms-modal-button"
          >
            <CloseIcon />
          </IconButton>
        )}

        <Grid item xs={12} md={6} className={styles.titleContainer}>
          <Typography variant="h4" className={styles.title}>
            {title}
          </Typography>
          <Typography variant="h6" className={styles.subTitle}>
            {subTitle}
          </Typography>
          <Grid item xs={12}>
            <RadioGroup
              className={styles.mTop10Botttom20}
              aria-label="cards"
              name="Cards"
              value={selPatientId}
              onChange={handlePatientSelect}
            >
              {patientList.map((patient, index) => (
                <FormControlLabel
                  key={patient?.patient_id}
                  value={patient?.patient_id}
                  control={
                    <Radio
                      inputProps={{
                        'data-testid': `pms-patient-radio-button-${index}`,
                      }}
                    />
                  }
                  label={renderPatientLabel(patient)}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item className={clsx(styles.tableTitle, 'd-flex align-center')}>
            <Typography variant="h5">
              {fetchLoading && 'Checking in the EHR for this patient…'}
              {!fetchLoading &&
                (searchAllData.length === 0 &&
                Object.keys(searchIdData).length === 0
                  ? 'No patient record found.'
                  : 'Potential matching EHR patient records')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Hidden smDown>
            <img
              src="/images/ehr-gif.gif"
              alt="ehr"
              className={styles.gifImage}
            />
          </Hidden>
        </Grid>
        <Grid item xs={12} className={styles.tableWrapper}>
          {fetchLoading ? (
            <Box textAlign="center" p={6}>
              <CircularProgress
                className={styles.spinner}
                color="primary"
                thickness={5}
                size={25}
              />
            </Box>
          ) : (
            <MUIDataTable
              data={tableData}
              columns={tableColumns}
              options={options}
            />
          )}
        </Grid>
      </Grid>
    </CommonDialog>
  );
};

export default LinkPatientModal;
