export const PARTNER_ADMIN = {
  key: "partner_admin",
  value: "Partner Admin",
};

export const PARTNER_USER = {
  key: "partner_user",
  value: "Partner User",
};

export const GROUP_ADMIN = {
  key: "group_admin",
  value: "Group Admin",
};

export const GROUP_USER = {
  key: "group_user",
  value: "Group User",
};

export const REGION_ADMIN = {
  key: "region_admin",
  value: "Region Admin",
};

export const REGION_USER = {
  key: "region_user",
  value: "Region User",
};

export const SUPER_ADMIN = {
  key: "superadmin",
  value: "Superadmin",
};

export const SUBSCRIBILI_USER = {
  key: "subscribili",
  value: "Subscribili",
};

export const LOCATION_ADMIN = {
  key: "location_admin",
  value: "Location Admin",
};

export const LOCAL_USER = {
  key: "location_user",
  value: "Location User",
};

export const REVSHARE_ADMIN = {
  key: "revshare_admin",
  value: "Rev Share Admin",
};
