import React from "react";
import { useHistory } from "react-router";
import { IconButton, Button } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Chip, TableListView } from "components";
import styles from "./AssociatedGroup.module.scss";

const AssociatedGroup = ({ groupList, isCreationEnabled, hierarchyDetails: { partner_id }, isSettingsView }) => {
  const history = useHistory();

  const commonCellProps = () => ({ className: styles.tableColumn });
  const tableColumns = [
    {
      name: "name",
      label: "Name",
      options: {
        setCellProps: commonCellProps
      }
    },
    {
      name: "address",
      label: "Address",
      options: {
        setCellProps: commonCellProps,
        sort: false
      }
    },
    {
      name: "city",
      label: "City",
      options: {
        setCellProps: commonCellProps
      }
    },
    {
      name: "state",
      label: "State",
      options: {
        setCellProps: commonCellProps
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        setCellProps: commonCellProps
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        setCellProps: commonCellProps,
        sort: false,
        display: !isSettingsView
      }
    },
  ];

  const tableData =
    groupList?.map((ingGroup) => ({
      name: ingGroup?.group_name,
      address: ingGroup?.address?.line1,
      city: ingGroup?.address?.city,
      state: ingGroup?.address?.state,
      status: (
        <Chip
          label={ingGroup.status}
          status={ingGroup.status}
        />
      ),
      action: (
        <IconButton aria-label="delete" size="small" onClick={() => viewGroup(ingGroup)} data-testid="view-group-button">
          <VisibilityIcon className="theme_color_orange" fontSize="inherit" />
        </IconButton>
      ),
    }));

  const triggerGroupCreation = () =>
    history.push({
      pathname: `/create-group`,
      state: { partner_id },
    });

  const viewGroup = (groupData) =>
    history.push({
      pathname: `/group-info`,
      state: { groupData },
    });

  const onRowClick = (rowData, rowMeta) => {
    const { dataIndex = 0 } = rowMeta;

    !isSettingsView && viewGroup(groupList[dataIndex]);
  };

  const btnProps = {
    otherButtons : (
      isCreationEnabled &&
        <Button
          onClick={triggerGroupCreation}
          color="secondary"
          size="large"
          data-testid='add-group'
        >
          Add Group
        </Button>
    )
  };

  return (
    <TableListView
      headerTitle="Associated Group(s)"
      tableData={tableData}
      tableOptions={{
        onRowClick
      }}
      tableColumns={tableColumns}
      pagination={Boolean(tableData.length > 10)}
      headerSection={true}
      emptyPlaceHolderContent="No Associated Groups found"
      {...btnProps}
    />
  );
};

export default AssociatedGroup;

