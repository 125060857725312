import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Tabs,
  Tab,
} from '@material-ui/core';

import { SectionHeader, ToggleTickSwitch, Select } from 'components';
import { PlansContext } from 'Views/Plans/context';
import {
  SUBSCRIBILI_COMMISSION_UPDATED,
  REVSHARE_UPDATED,
} from 'Views/Plans/context/actions';
import { getDecimalValue } from 'Shared/Utils';
import useStyles from './styles';

const PAYOUT_TYPES = [
  {
    label: '$',
    value: 'fixed',
  },
  {
    label: '%',
    value: 'percent',
  },
];

const SubscribiliCommission = (props) => {
  const {
    plan,
    planIndex,
    loading: isLoading = false,
    list: revshareList = [],
  } = props;

  const {
    dispatch,
  } = useContext(PlansContext);
  const { revshare_payout = {}, subscribili_comm = {} } = plan;

  const {
    enabled: revshareEnabled = false,
    revshare_id: revshareId = '',
    payout_type: revshareType = 'percent',
    amount: revshareAmount = 0,
  } = revshare_payout;

  const { type, primary } = subscribili_comm;

  const classes = useStyles();

  const isPercentShare = revshareType === 'percent';
  const isPercentComm = type === 'percent';

  const dispatchRevShareChange = (name, value) =>
    dispatch({
      type: REVSHARE_UPDATED,
      payload: {
        revshare_payout: { ...revshare_payout, [name]: value },
        planIndex,
      },
    });

  const handleSwitchChange = (event) => {
    const { name: eName, checked } = event.target;

    dispatchRevShareChange(eName, Boolean(checked));
  };

  const handleRevshareChange = (name, value, type = '') => {
    const updatedValue = type === 'number' ? getDecimalValue(value) : value;

    dispatchRevShareChange(name, updatedValue);
  };

  const dispatchCommissionChange = (updated_subscribili_comm) =>
    dispatch({
      type: SUBSCRIBILI_COMMISSION_UPDATED,
      payload: { subscribili_comm: updated_subscribili_comm, planIndex },
    });

  const handleTypeChange = (value) => {
    const commissionType = value;

    dispatchCommissionChange({
      type: commissionType,
      primary: 0,
      dependent: 0,
    });
  };

  const handleChange = (name, value, type = '') => {
    const updatedValue = type === 'number' ? getDecimalValue(value) : value;

    dispatchCommissionChange({
      ...subscribili_comm,
      [name]: updatedValue,
    });
  };

  const onBlur = (event, isPercent) => {
    let value = +parseFloat(event.target.value).toFixed(2) || 0;

    if (isPercent) {
      value = value > 100 ? 100 : value;
    }

    dispatchCommissionChange({
      ...subscribili_comm,
      [event.target.name]: value,
    });
  };

  useEffect(() => {
    if (!revshareId && revshareList.length)
      handleRevshareChange('revshare_id', revshareList?.[0]?.revshare_id);
  }, [revshareList]);

  const renderInputField = ({
    value,
    name,
    label,
    disabled = false,
    fieldProps = {},
    onChange = () => {},
    isPercentage = false,
  }) => (
    <TextField
      fullWidth
      name={name}
      label={label}
      value={value}
      variant="outlined"
      className={classes.textFieldPosition}
      disabled={disabled}
      onChange={onChange}
      onBlur={(evt) => onBlur(evt, isPercentage)}
      inputProps={{
        'data-testid': `subscribili-commission-${name}`
      }}
      {...fieldProps}
    />
  );

  return (
    <>
      <SectionHeader
        title="Subscribili Commission"
        titleClasses={['font-weight-400']}
        titleVariant="h6"
        titleComponent="h6"
      />

      <Grid container spacing={3} alignItems="center" className="mg_top_24">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          {renderInputField({
            name: 'primary',
            label: 'Member',
            value: primary,
            isPercentage: isPercentComm,
            onChange: ({ target }) =>
              handleChange(target?.name, target?.value, 'number'),
            fieldProps: {
              InputProps: {
                endAdornment: (
                  <Grid container className={classes.tabsContainer}>
                    <Tabs
                      value={isPercentComm ? 'percent' : 'fixed'}
                      indicatorColor="secondary"
                      textColor="secondary"
                      onChange={(_, value) => {
                        handleTypeChange(value);
                      }}
                      className={classes.discountTabs}
                    >
                      {PAYOUT_TYPES.map((indType) => (
                        <Tab
                          key={indType?.value}
                          value={indType?.value}
                          label={indType?.label}
                        />
                      ))}
                    </Tabs>
                  </Grid>
                ),
              },
            },
          })}
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className="d-flex align-center justify-content-center"
        >
          <Typography variant="body1" color="textSecondary">
            Revenue Share Enabled
          </Typography>

          <ToggleTickSwitch
            className="mg_left_8"
            checked={revshareEnabled}
            onChange={handleSwitchChange}
            name="enabled"
            inputProps={{
              'aria-label': 'secondary checkbox',
              'data-testid': 'toggle-enable-revshare',
            }}
          />
        </Grid>

        {revshareEnabled && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="d-flex align-center "
            >
              <FormControl
                fullWidth
                required
                variant="outlined"
                disabled={isLoading || !revshareEnabled}
              >
                <InputLabel htmlFor="outlined-Group-native-simple">
                  RevShare Name
                </InputLabel>
                <Select
                  id="commission-select-revshare"
                  label="RevShare Name *"
                  name="revshare_id"
                  value={revshareId}
                  onChange={({ target = {} }) =>
                    handleRevshareChange('revshare_id', target?.value)
                  }
                >
                  {revshareList?.map((vendor, i) => (
                    <MenuItem
                      value={vendor?.revshare_id}
                      key={vendor?.revshare_id}
                      data-testid={`commission-revshare-select-item-${i}`}
                    >
                      {vendor?.revshare_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              {renderInputField({
                name: 'amount',
                label: 'RevShare Amount *',
                value: revshareAmount,
                isPercentage: isPercentShare,
                onChange: ({ target }) =>
                  handleRevshareChange('amount', target?.value, 'number'),
                fieldProps: {
                  InputProps: {
                    endAdornment: (
                      <Grid container className={classes.tabsContainer}>
                        <Tabs
                          value={revshareType}
                          indicatorColor="secondary"
                          textColor="secondary"
                          onChange={(_evt, value) =>
                            handleRevshareChange('payout_type', value)
                          }
                          className={classes.discountTabs}
                        >
                          {PAYOUT_TYPES.map((indType) => (
                            <Tab
                              key={indType?.value}
                              value={indType?.value}
                              label={indType?.label}
                            />
                          ))}
                        </Tabs>
                      </Grid>
                    ),
                  },
                },
              })}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

SubscribiliCommission.propTypes = {
  plan: PropTypes.object.isRequired,
  planIndex: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  list: PropTypes.array,
};

export default SubscribiliCommission;
