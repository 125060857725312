import React from "react";
import SubscriberProvider from "./SubscriberProvider";

const withSubscriberContext = (Component) => (props) =>
  (
    <SubscriberProvider>
      <Component {...props} />
    </SubscriberProvider>
  );

export default withSubscriberContext;
