import httpService from "../Api/http.service";

const { REACT_APP_API_BASE_URL } = process.env;

const createLocation = (payload) =>
  httpService({
    url: `/location`,
    method: "post",
    data: payload,
  });

  const createLocations = (payload) =>
    httpService({
      url: `/locations`,
      method: "post",
      data: payload,
    });

const updateLocation = (data) =>
  httpService({
    url: `/location`,
    method: "put",
    data,
  });

const deleteLocation = (payload) =>
  httpService({
    url: `/location`,
    method: "delete",
    data: payload,
  });

const fetchLocationByPartnerId = (group_id, partner_id, params = '') => {
  let api = group_id
    ? `/locations?partner_id=${partner_id}&group_id=${group_id}${params}`
    : `/locations?partner_id=${partner_id}${params}`;

  return httpService({
    url: api,
    method: "get",
  });
};

const updateLocationPlan = (location_id, plan_id, data) =>
  httpService({
    url: `/locations/${location_id}/plans/${plan_id}`,
    method: "put",
    data,
  });

const setLocationPlans = (location_id, data) =>
  httpService({
    url: `/locations/${location_id}/plans`,
    method: "post",
    data,
  });

const addLocationPlans = (location_id, data) =>
  httpService({
    url: `/location/${location_id}/plans`,
    method: "post",
    data,
  });

const deleteLocationPlans = (location_id, data) =>
  httpService({
    url: `/location/${location_id}/plans`,
    method: "delete",
    data,
  });

const getLocationByFacilityId = (facility_id) =>
  httpService({
    url: `${REACT_APP_API_BASE_URL}/subscriberportal/locations?facid=${facility_id}`,
    method: "get",
  });

const getSavingsSummary = (group_id, location_id) =>
  httpService({
    url: `${REACT_APP_API_BASE_URL}/subscriberportal/savings-summary?group_id=${group_id}&location_id=${location_id}`,
    method: "get",
  });

const locationService = {
  createLocation,
  createLocations,
  updateLocation,
  deleteLocation,
  fetchLocationByPartnerId,
  updateLocationPlan,
  setLocationPlans,
  addLocationPlans,
  deleteLocationPlans,
  getLocationByFacilityId,
  getSavingsSummary
};

export default locationService;
