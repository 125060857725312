import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import styles from './RemoveButton.module.scss'

const RemoveButton = (props) => (
  <MuiButton
    className={styles.removeButton}
    color="secondary"
    fullWidth
    variant="outlined"
    startIcon={<DeleteOutlinedIcon className={styles.iconColor} />}
    {...props}
  />
)

export default RemoveButton;
