import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router";
import clsx from 'clsx';
import {
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';

import { SectionHeader, Select } from 'components';
import { Context as GlobalContext } from 'context';
import { GROUPS_DATA_RECEIVED } from 'context/actions';
import { PlansContext } from 'Views/Plans/context';
import { GROUP_CHANGED } from 'Views/Plans/context/actions';
import { GROUP_SERVICES } from 'Services';
import styles from './AssociateGroup.module.scss';

const AssociateGroup = () => {
  const {
    state: { groupsData },
    dispatch: globalDispatch,
  } = useContext(GlobalContext);

  const {
    state: { selectedGroup, highlightErrors, has_subscriptions },
    dispatch,
  } = useContext(PlansContext);

  const [isGroupsLoading, setGroupsLoading] = useState(true);

  const history = useHistory();

  const isGroupError = Boolean(highlightErrors && !selectedGroup);

  useEffect(() => {
    const fetchAllGroups = async () => {
      const { data } = await GROUP_SERVICES.fetchGroupByPartnerId();
      const groups = data?.rows || [];

      setGroupsLoading(false);

      globalDispatch({
        type: GROUPS_DATA_RECEIVED,
        payload: { groupsData: groups }
      });

      if (groups.length) {
        selectDefaultGroup(groups);
      }
    };

    fetchAllGroups();
  }, []);

  const selectDefaultGroup = (groupList) => {
    const { state: locationState } = history.location;
    let defaultGroup = groupList[0];

    if (locationState) {
      defaultGroup = groupList.find(group => group.group_id === locationState.group_id) || {};
      // history.replace({ ...history.location, state: null })
    }

    dispatchGroup(defaultGroup);
  };

  const dispatchGroup = (group) => dispatch({
    type: GROUP_CHANGED,
    payload: { selectedGroup: group }
  });

  const handleGroupChange = ({ target: { value } }) =>
    (value.group_id !== selectedGroup.group_id) && dispatchGroup(value);

  return (
    <Paper className={clsx('widget-wrapper')} elevation={0}>
      <SectionHeader
        title="Associate Group"
        titleVariant="h5"
        titleComponent="h5"
        subTitle="Associate the plan to the group"
        titleJustify="flex-start"
        gutterBottom
        rightSection={
          <Tooltip arrow title={
            <Typography variant="body1">
              Make sure to follow the SOP while associating a group or partner
            </Typography>
          }>
            <InfoIcon className={styles.infoIcon} fontSize="small" color="primary" />
          </Tooltip>
        }
      />
      <Divider />

      <Grid container spacing={3} className='gutter' alignItems="center">
        <Grid item xs={isGroupsLoading ? 10 : 12} sm={6} md={4} lg={3} className="position-relative">
          <FormControl
            fullWidth
            required
            disabled={!Boolean(groupsData.length) || has_subscriptions}
            error={isGroupError}
            variant="outlined"
          >
            <InputLabel id="associate-group-label">Group</InputLabel>
            <Select
              id="associate-select-group"
              name="group"
              value={selectedGroup}
              onChange={handleGroupChange}
              label="Group *"
            >
              {
                groupsData.map((group, index) => (
                  <MenuItem
                    key={index}
                    value={group}
                    data-testid={`associate-group-select-item-${index}`}
                  >
                    {group.group_name}
                  </MenuItem>
                ))
              }
            </Select>
            {
              isGroupError &&
              <FormHelperText>Please select a group</FormHelperText>
            }
          </FormControl>

          {
            isGroupsLoading &&
            <CircularProgress size={20} thickness={5} className='dropdown-loader' />
          }
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AssociateGroup;
