import React from 'react';
import { TextField as MuiTextfield } from '@material-ui/core';

const TextField = (props) => (
  <MuiTextfield
    {...props}
  />
)

export default TextField;
