import React, { useState, useContext, useEffect } from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';
import clsx from 'clsx';
import {
  Divider,
  Grid,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  Tabs,
  Tab,
  FormHelperText,
  CircularProgress,
  ListItemText,
  Typography,
} from '@material-ui/core';

import { SectionHeader, Select, CheckBox } from 'components';
import { Context } from 'context';
import { ADD_NOTIFICATION } from 'context/actions';
import { PlansContext } from 'Views/Plans/context';
import {
  PRODUCT_INFO_UPDATED,
  UPDATE_FIELDS_VALIDITY,
  PLAN_UPDATED,
} from 'Views/Plans/context/actions';
import { PLAN_PACKAGE_TYPE, STATUS_LIST } from 'Views/Common/enum';
import { CategoryDiscounts, CPTValues } from '../';
import { SUBSCRIPTION_TYPE, PLAN_ATTRIBUTES } from 'global-constants/constants';
import { PLAN_SERVICES } from 'Services';
import Utils from 'Shared/Utils';
import useStyles from './styles';

const ProductInfo = (props) => {
  const { dispatch: globalDispatch } = useContext(Context);
  const {
    state: {
      productInfo,
      selectedGroup,
      isValid,
      highlightErrors,
      has_subscriptions,
      plans,
      plan_id,
    },
    dispatch,
  } = useContext(PlansContext);
  const { setExecuteHandleSubmit } = props || {};
  let {
    plan_name,
    plan_package_type,
    member_types: subTypes,
    description,
    statement_descriptor,
    allowed_age_max,
    allowed_age_min,
    additional_members,
    display_order = '',
    addon_allowed,
    allow_future_dated = false,
    allowFutureDateToggle,
    renewal_plan_pkg_id,
    plan_status,
    disclosure_text = '',
  } = productInfo;

  const vet_only = selectedGroup.industry === 'Veterinary';
  const classes = useStyles();

  const isAllPricePrivateOnly = plans.every(
    (plan) => plan.plan_details.private_only,
  );
  const [plansLoading, setPlansLoading] = useState(true);
  const [plansList, setPlansList] = useState([]);
  const [renewalPlanId] = useState(renewal_plan_pkg_id);
  const [planAttributes, setPlanAttributes] = useState([]);
  const memberTypeSelected = planAttributes.some(
    (attrs) =>
      (attrs.name === SUBSCRIPTION_TYPE.CUSTOMER.value ||
        attrs.name === SUBSCRIPTION_TYPE.EMPLOYEE.value) &&
      attrs.value === true,
  );

  useEffect(() => {
    setPlanAttributes([
      {
        name: 'addon_allowed',
        value: addon_allowed,
      },
      {
        name: 'private_only',
        value: isAllPricePrivateOnly,
      },
      {
        name: SUBSCRIPTION_TYPE.CUSTOMER.value,
        value: subTypes?.includes(SUBSCRIPTION_TYPE.CUSTOMER.value),
      },
      {
        name: SUBSCRIPTION_TYPE.EMPLOYEE.value,
        value: subTypes?.includes(SUBSCRIPTION_TYPE.EMPLOYEE.value),
      },
      {
        name: 'allow_future_dated',
        value: allow_future_dated,
      },
    ]);
  }, [isAllPricePrivateOnly, addon_allowed, subTypes, allow_future_dated]);

  useEffect(() => {
    const infoFields = { plan_name, description, statement_descriptor };
    const isFieldsError = Object.keys(infoFields).some((key) => {
      if (!infoFields[key]) {
        return true;
      }

      return false;
    });

    dispatch({
      type: UPDATE_FIELDS_VALIDITY,
      payload: {
        isValid: { ...isValid, product: !isFieldsError },
      },
    });
  }, [highlightErrors, productInfo]);

  useEffect(() => {
    dispatch({
      type: UPDATE_FIELDS_VALIDITY,
      payload: {
        isValid: { ...isValid, memberTypeSelected: memberTypeSelected },
      },
    });
  }, [highlightErrors, memberTypeSelected]);

  const dispatchProductInfoChange = (updatedProductInfo) =>
    dispatch({
      type: PRODUCT_INFO_UPDATED,
      payload: { productInfo: updatedProductInfo },
    });

  const dispatchUpdatedPlans = (updatedPlans) =>
    dispatch({
      type: PLAN_UPDATED,
      payload: {
        plans: updatedPlans,
      },
    });

  const handlePrivateOnlyChange = (private_only) => {
    let updatedPlans = _.cloneDeep(plans);
    updatedPlans.forEach(
      (plan) => (plan.plan_details.private_only = private_only),
    );
    dispatchUpdatedPlans(updatedPlans);
  };

  const handleMemberTypeChange = (memberType, actionType = 'add') => {
    let newValue = [];

    const planSubTypes = _.cloneDeep(subTypes);

    if (actionType === 'add') {
      newValue = [...planSubTypes, memberType];
    } else {
      newValue = planSubTypes.filter((memType) => memType !== memberType);
    }
    handleChange('member_types', newValue);
  };

  const handleDateChange = (selDate, name) => handleChange(name, selDate);

  const handleChange = (name, value) => {
    let dependentValues = {};

    if (name === 'plan_package_type' && value !== 'Base')
      dependentValues = {
        addon_allowed: false,
      };

    dispatchProductInfoChange({
      ...productInfo,
      ...dependentValues,
      [name]: value,
    });
  };

  const handleMultiSelectChange = (name, value) => {
    if (name === 'private_only') handlePrivateOnlyChange(value);
    else if (
      name.includes(SUBSCRIPTION_TYPE.CUSTOMER.value) ||
      name.includes(SUBSCRIPTION_TYPE.EMPLOYEE.value)
    ) {
      const actionType = value ? 'add' : 'delete';
      handleMemberTypeChange(name, actionType);
    } else {
      if (checkIfChangeAllowed(name)) {
        handleChange(name, value);
      } else {
        dispatchGlobalNotification(
          'error',
          'Allow Future Date value cannot be changed',
        );
      }
    }
  };

  const checkIfChangeAllowed = (name) => {
    if (name !== 'allow_future_dated') return true;

    return allowFutureDateToggle;
  };

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } },
    });

  const renderInputField = (
    value,
    name,
    label,
    textFieldProps = {},
    defaultValue = 0,
  ) => {
    const { inputProps, labelProps, ...restProps } = textFieldProps;

    return (
      <TextField
        fullWidth
        required
        name={name}
        id={name}
        label={label}
        value={value}
        error={Boolean(highlightErrors && !value)}
        helperText={`${label} is required`}
        variant="outlined"
        onChange={(event) => {
          let { name: eName, value: eValue = '' } = event?.target || {};
          inputProps?.type === 'number' &&
            (eValue = Math.round(parseFloat(eValue)) || defaultValue);

          handleChange(eName, eValue);
        }}
        inputProps={{
          'data-testid': `product-info-${name}`,
          ...inputProps,
        }}
        InputLabelProps={{
          ...labelProps,
        }}
        {...restProps}
      />
    );
  };

  const renderTitle = () => (
    <Grid container className={classes.tabsContainer}>
      <Tabs
        value={plan_package_type}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={(_event, newValue) =>
          handleChange('plan_package_type', newValue)
        }
        variant="scrollable"
        scrollButtons="auto"
        className={classes.planTitleTabs}
      >
        {Object.keys(PLAN_PACKAGE_TYPE).map((indKey) => (
          <Tab
            key={indKey}
            value={indKey}
            label={PLAN_PACKAGE_TYPE[indKey]}
            disabled={props?.planPackageId}
          />
        ))}
      </Tabs>
    </Grid>
  );

  const renderDropdownField = (
    value,
    name,
    label,
    selectList,
    textFieldProps = {},
  ) => {
    const { helperText, ...restProps } = textFieldProps;

    return (
      <>
        <FormControl fullWidth variant="outlined" {...restProps}>
          <InputLabel id="product-renewal-plan-label"> {label} </InputLabel>
          <Select
            id={`product-select-${name}`}
            name={name}
            value={value}
            onChange={(evt) => handleChange(name, evt?.target?.value)}
            label={`${label} ${restProps.required ? '*' : ''}`}
          >
            {selectList.map((indData, index) => (
              <MenuItem
                key={indData.key}
                value={indData.key}
                data-testid={`product-select-${name}-item-${index}`}
              >
                {indData.label}
              </MenuItem>
            ))}
          </Select>
          {helperText && <FormHelperText> {helperText} </FormHelperText>}
        </FormControl>

        {plansLoading && (
          <CircularProgress
            size={20}
            thickness={5}
            className="dropdown-loader"
          />
        )}
      </>
    );
  };

  const handleResponse = (response) => {
    const rows = Utils.getValue(response?.data?.rows, []);
    let filteredRows = [];

    rows.forEach((row) => {
      if (!plan_id || row.plan_package_id !== plan_id)
        filteredRows.push({
          key: row?.plan_package_id,
          label: row?.plan_name,
        });
    });

    setPlansList(filteredRows);
  };

  const fetchPlans = async (group_id) => {
    if (!group_id || group_id === 'ALL_GROUPS') return;

    setPlansLoading(true);

    try {
      const response = await PLAN_SERVICES.fetchGroupPlanPackages(group_id);

      Utils.checkIfSuccess(response) && handleResponse(response);
    } catch (err) {
      console.log(err);
    }

    setPlansLoading(false);
  };

  const showSelectedPlanAttributes = (selected) => {
    const valueSelected = selected.filter((attr) => attr.value);
    const selectedItems = valueSelected
      .map((value) => PLAN_ATTRIBUTES[value.name])
      .join(', ');
    const count = valueSelected.length;
    if (selectedItems.length <= 48) return selectedItems;

    const overflowCount = (
      <Typography
        component="span"
        variant="body1"
        className={classes.showMoreTextColor}
      >
        +{count - 2} more
      </Typography>
    );
    const firstCommaOccurance = selectedItems.indexOf(',');
    const SecondOccurance = selectedItems.indexOf(',', firstCommaOccurance + 1);
    const trimmedValues = selectedItems.slice(0, SecondOccurance);

    return (
      <>
        {trimmedValues} {overflowCount}
      </>
    );
  };

  useEffect(() => {
    selectedGroup?.group_id && fetchPlans(selectedGroup?.group_id);
  }, [selectedGroup?.group_id]);

  return (
    <>
      <Paper
        className={clsx('widget-wrapper', classes.widgetContainer)}
        elevation={0}
      >
        <SectionHeader
          title={renderTitle()}
          titleVariant="h5"
          titleComponent="h5"
          gutterBottom
          rightSection={renderInputField(
            display_order,
            'display_order',
            'Display Order',
            {
              inputProps: {
                type: 'number',
                min: 1,
              },
              error: false,
              required: false,
              helperText: '',
              color: 'secondary',
            },
            '',
          )}
        />
        <Divider />

        <Grid container spacing={3} id="product-info">
          <Grid item xs={12}>
            <Grid container spacing={3} className="gutter">
              <Grid item xs={12} md={6} lg={4}>
                {renderInputField(
                  statement_descriptor,
                  'statement_descriptor',
                  'Statement Description',
                  {
                    inputProps: {
                      maxLength: 22,
                    },
                    helperText: `Choose something your customers will recognize on a bank statement`,
                  },
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                {renderInputField(plan_name, 'plan_name', 'Product Name', {
                  helperText:
                    Boolean(highlightErrors && !plan_name) &&
                    'Product Name is required',
                })}
              </Grid>

              {vet_only && (
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="pet-type-label">Pet Type</InputLabel>
                    <Select
                      id="pet-type-select"
                      name="pet-type"
                      label="Pet Type *"
                    >
                      <MenuItem value={0}>Dog</MenuItem>
                      <MenuItem value={0}>Cat</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} md={6} lg={4}>
                {renderInputField(
                  description,
                  'description',
                  'Product Description',
                  {
                    helperText: Boolean(highlightErrors && !description)
                      ? 'Product Description is required'
                      : 'Product description appears at checkout, in the customer portal, and on quotes',
                  },
                )}
              </Grid>
              {plan_package_type === 'Tiered' && (
                <Grid item xs={12} md={6} lg={6}>
                  {renderInputField(
                    additional_members,
                    'additional_members',
                    'Allowed Additional Members',
                    {
                      inputProps: {
                        type: 'number',
                        min: 0,
                      },
                      helperText:'',
                      error: false,
                      required: false,
                    },
                  )}
                </Grid>
              )}

              {plan_package_type !== 'Tiered' && (
                <>
                  <Grid item xs={12} md={6} lg={4}>
                    {renderInputField(
                      allowed_age_min || 0,
                      'allowed_age_min',
                      'Allowed Minimum Age',
                      {
                        inputProps: {
                          type: 'number',
                          min: 0,
                        },
                        error: false,
                        required: false,
                        helperText:
                          'Allowed minimum age of the customer for this plan',
                        disabled: has_subscriptions,
                      },
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    {renderInputField(
                      allowed_age_max || 0,
                      'allowed_age_max',
                      'Allowed Maximum Age',
                      {
                        inputProps: {
                          type: 'number',
                          min: 0,
                        },
                        error: false,
                        required: false,
                        helperText:
                          'Allowed maximum age of the customer for this plan',
                        disabled: has_subscriptions,
                      },
                    )}
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={12}
                md={6}
                lg={plan_package_type === 'Tiered' ? 6 : 4}
              >
                {renderDropdownField(
                  plan_status,
                  'plan_status',
                  'Plan Status',
                  STATUS_LIST,
                  {
                    required: true,
                    helperText: 'Hide plans if expired',
                    // disabled: (plan_id && plan_status === 'Hidden')
                  },
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6} className="position-relative">
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="multiple-checkbox-label"
                    className={clsx(
                      highlightErrors && !memberTypeSelected
                        ? 'color_error'
                        : '',
                    )}
                  >
                    Plan Attributes
                  </InputLabel>
                  <Select
                    id="multiple-checkbox-label"
                    className={classes.multiSelect}
                    multiple
                    value={planAttributes}
                    label="Plan Attributes"
                    error={Boolean(highlightErrors && !memberTypeSelected)}
                    renderValue={showSelectedPlanAttributes}
                  >
                    {planAttributes.length &&
                      planAttributes.map(({ name, value }) => (
                        <MenuItem
                          key={name}
                          value={value}
                          onClick={() => {
                            handleMultiSelectChange(name, !value);
                          }}
                        >
                          <CheckBox checked={value} />
                          <ListItemText primary={PLAN_ATTRIBUTES[name]} />
                        </MenuItem>
                      ))}
                  </Select>
                  {highlightErrors && !memberTypeSelected ? (
                    <FormHelperText className="color_error">
                      One of the Member Type should be selected
                    </FormHelperText>
                  ) : (
                    ''
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className="position-relative">
                {renderDropdownField(
                  renewal_plan_pkg_id,
                  'renewal_plan_pkg_id',
                  'Renewal Product Name',
                  plansList,
                  {
                    helperText:
                      'Plan to upgrade after the current plan expires',
                    disabled:
                      plansLoading ||
                      (plan_id && renewalPlanId && has_subscriptions),
                  },
                )}
              </Grid>

              <Grid item xs={12}>
                {renderInputField(
                  disclosure_text,
                  'disclosure_text',
                  'Disclosure',
                  {
                    error: false,
                    required: false,
                    helperText: '',
                    label: 'Disclosure',
                    multiline: true,
                    minRows: 3,
                    maxRows: 10,
                  },
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        className={clsx('widget-wrapper', classes.widgetContainer)}
        elevation={0}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CPTValues />
          </Grid>
        </Grid>
      </Paper>
      {selectedGroup?.group_id &&
        selectedGroup?.industry === 'Dentistry' &&
        (plan_package_type === 'Base' || plan_package_type === 'Tiered') && (
          <Paper
            className={clsx('widget-wrapper', classes.widgetContainer)}
            elevation={0}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CategoryDiscounts
                  level={'group'}
                  setExecuteHandleSubmit={setExecuteHandleSubmit}
                  levelId={selectedGroup?.group_id}
                  planPackageId={props?.planPackageId}
                />
              </Grid>
            </Grid>
          </Paper>
        )}
    </>
  );
};

ProductInfo.propTypes = {
  planPackageId: PropTypes.string
};

export default ProductInfo;
