import React from 'react';
import PlansProvider from './PlansProvider';

const withPlansContext = (Component) => (props) => (
  <PlansProvider>
    <Component {...props} />
  </PlansProvider>
);

export default withPlansContext;
