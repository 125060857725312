const greenPalette = {
  alternate: {
    main: '#f5f6f7',
    dark: '#E8EAF6',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    light: '#F0F7FF',
    main: '#002855',
    contrastText: '#FFF',
    highlight: '#9CC841',
    highlight2: '#9CC841',
    highlight3: '#9CC841',
  },
  secondary: {
    light: '#9CC841',
    main: '#448486',
    contrastText: '#FFF',
    highlight: '#9CC841',
  },
  text: {
    primary: '#475670',
    secondary: '#6F6C99',
    disabled: '#CCC',
    highlight: '#FFF',
    light: '#EAF1F8',
    success: '#79A81A',
    errorBorder: '#F44336',
    levelOnboardColor: '#FFF',
    lightGrey: '#64748B',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#FFF',
    default: '#FFF',
    variant1: '#F2F8FA',
    level2: '#F5F5F5',
    level1: '#FFF',
    progressBG: '#E5EAF4',
    error: '#FFEAEA',
    loginGradiant:
      'linear-gradient(175.11deg, rgba(248, 249, 252, 0) 14.05%, #F0F3FC 96.06%)',
    levelOnboardGradiant:
      'linear-gradient(0.96deg, #1A3F7C 6.98%, #071328 125.99%)',
    levelOnboardBg: '#1A4DA5',
  },
  hover: 'rgba(245,244,246,.5)',
  table_head: '#f6f8fc',
};

export default greenPalette;
