import React from "react";
import { Tabs, Tab } from "@material-ui/core";

import useStyles from "./styles";

const DEFAULT_LIST = [
  {
    label: "$",
    value: "fixed",
  },
  {
    label: "%",
    value: "percent",
  },
];

const ToggleTab = (props) => {
  const {
    name = "value_type",
    value: tabValue = "percent",
    onChange: onTabChange = () => {},
    tabsList = [...DEFAULT_LIST],
    tabProps = {},
    ...otherProps
  } = props;

  const classes = useStyles();

  return (
    <Tabs
      name={name}
      value={tabValue}
      onChange={onTabChange}
      className={classes.tabsWrapper}
      {...otherProps}
    >
      {tabsList.map((tab, index) => (
        <Tab
          name={name}
          data-testid={`toggle-tab-${name}-${index}`}
          key={tab.value}
          label={tab.label}
          value={tab.value}
          className={"compact"}
          {...tabProps}
        />
      ))}
    </Tabs>
  );
};

export default ToggleTab;
