import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  categoryHeader: {
    width: '25%',
  },
  nameHeader: {
    width: '30%',
  },
  dateHeader: {
    width: '30%',
  },
}));

export default useStyles;
