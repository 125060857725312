import React, { Fragment, useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router";
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Popover,
  Grid,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  ExpandMore as ExpandIcon,
} from "@material-ui/icons";

// Context
import { Context } from "context";
import { MENU_CONFIG_UPDATE } from "context/actions";

// Services
import { USER_SERVICES } from "Services";

// Styles
import styles from "./SideNav.module.scss";

const SUB_ROUTES = {
  "/flex/packages": [
    "/flex/packages/list",
    "/flex/packages/info",
    "/flex/packages/create",
    "/flex/packages/edit",
  ],
  "/flex/invoices": [
    "/flex/invoices/list",
    "/flex/invoices/info",
    "/flex/invoices/order",
  ],
  "/subscriber": ["/subscriber/list", "/subscriber/info", "/subscriber/create", "/subscriber/import"],
  "/employee": ["/employee/list", "/employee/info", "/employee/create"],
  "/users/list": ["/users/create"],
  "/partner-plan": ["/partner-info", "/create-partner"],
  "/group-list": ["/group-info", "/create-group"],
  "/region-list": ["/region-info", "/create-region"],
  "/location-list": ["/location-info", "/create-location"],
  "/revshare-list": ["/revshare-info"],
  "/plans": ["/plans/list", "/plans/info", "/plans/create", "/plans/edit"],
  "/employer": ["/employer/list", "/employer/create"],
};

const SideNav = () => {
  const {
    state: {
      menu_config: {
        init: menuInit = false,
        menu_response = {},
        multi_product = false,
        selected_product = {},
        product_menu = [],
        sub_menu = [],
        available_routes = [],
      } = {},
    },
    dispatch,
  } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState([6, 7]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const defaultActiveSubmenu = [
    "subscription-and-materials",
    "help-and-resources",
  ];

  const history = useHistory();
  const { pathname } = useLocation();

  const PRODUCT_HOME_PAGES = {
    recur: {
      key: "recur",
      route: "/dashboard",
      title: "Recur",
      img: "/images/menu-icons/subscribili-logo.svg",
    },
    flex: {
      key: "flex",
      route: "/flex/invoices/list",
      title: "Flex",
      img: "/images/menu-icons/subscribili-logo.svg",
    },
  };

  useEffect(() => {
    if (sub_menu?.length) {
      const activeIndices = sub_menu.reduce((indices, item, index) => {
        if (defaultActiveSubmenu.includes(item.key)) {
          return [...indices, index];
        }

        return indices;
      }, []);

      setActiveSubMenu(activeIndices);
    }
  }, [sub_menu]);

  useEffect(() => {
    !isActive && handlePopoverClose();
  }, [isActive]);

  const getPageKey = () => {
    const pageKey = window.location.pathname.includes("/flex")
      ? "flex"
      : "recur";

    return pageKey;
  };

  useEffect(() => {
    const onPageLoad = async () => {
      try {
        const availableProducts = Object.keys(menu_response);

        if (
          !availableProducts.includes(selected_product?.key) ||
          (!available_routes.includes(pathname) &&
            !available_routes.includes('/' + pathname.split('/')?.[1]))
        ) {
          onProductChangeEvent(availableProducts?.[0]);
        }
      } catch (err) {
        console.log(err);
      }
    };

    menuInit && onPageLoad();
  }, [menuInit, pathname]);

  const getMenus = async () => {
    if (loading) return;

    setLoading(true);

    try {
      const response = await USER_SERVICES.getMenuInfo();

      if (response?.type === "success" && response?.data) {
        const { menu = {} } = response?.data || {};
        const currProduct = getPageKey();
        const availableProducts = Object.keys(menu);

        const availableRoutes = [];
        const commonRoutes = [
          '/settings',
          '/terms-of-service',
          '/privacy-policy',
          '/hipaa-notice',
        ];

        availableProducts.forEach((indProduct) => {
          const menuArray = menu?.[indProduct];

          menuArray?.length &&
            menuArray.forEach((menu) => {
              const { route = "", subMenu = {} } = menu;

              route && availableRoutes.push(route);
              SUB_ROUTES?.[route] &&
                availableRoutes.push(...(SUB_ROUTES?.[route] || []));

              subMenu.length &&
                subMenu.forEach((sMenu) => {
                  const { route = "" } = sMenu;

                  route && availableRoutes.push(route);
                  SUB_ROUTES?.[route] &&
                    availableRoutes.push(...(SUB_ROUTES?.[route] || []));
                });
            });
        });

        const helpAndResources =
          menu?.recur?.find(
            (indMenu) => indMenu?.key === "help-and-resources"
          ) || {};
        const {
          // Default link will be overriden from API response
          link: trainingLink = "https://calendly.com/subcribili-training",
        } =
          helpAndResources?.subMenu?.find(
            (indSubMenu) => indSubMenu?.key === "schedule-training"
          ) || {};

        dispatch({
          type: MENU_CONFIG_UPDATE,
          payload: {
            init: true,
            menu_response: menu,
            multi_product: availableProducts.length > 1,
            selected_product: PRODUCT_HOME_PAGES?.[currProduct] || {},
            product_menu: availableProducts.length
              ? availableProducts.map((indProduct) => ({
                  ...PRODUCT_HOME_PAGES[indProduct],
                }))
              : [],
            sub_menu: menu?.[currProduct],
            available_routes: [...availableRoutes, ...commonRoutes],
            trainingLink
          },
        });
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    getMenus();
  }, []);

  const activeTab = (routeName) =>
    routeName && window.location.pathname.includes(routeName);

  const onProductChangeEvent = (value) => {
    const selectedProduct =
      product_menu?.find((indProduct) => indProduct?.key === value) || {};

    dispatch({
      type: MENU_CONFIG_UPDATE,
      payload: {
        selected_product: selectedProduct,
        sub_menu: menu_response?.[value] || [],
      },
    });

    if (selectedProduct?.route)
      history.push({
        pathname: selectedProduct?.route,
      });

    handlePopoverClose();
  };

  const onRouteChangeEvent = ({ route, href }, routeIndex) => {
    if (route) {
      history.push({
        pathname: route,
      });
      return;
    }

    if (href) {
      window.open(href, "_blank", "noopener, noreferrer");
    }

    setActiveSubMenu((prevActiveSubMenu) => {
      if (prevActiveSubMenu.includes(routeIndex)) {
        return prevActiveSubMenu.filter((index) => index !== routeIndex);
      } else {
        return [...prevActiveSubMenu, routeIndex];
      }
    });
  };

  const renderMainList = (data, routeIndex) => {
    const { img, activeImg, route } = data;
    return (
      <>
        {!data?.subMenu && (
          <ListItemIcon
            className={styles.listAvatar}
            data-testid={`${data?.key}-icon`}
          >
            <img src={`${img}`} alt={route} className={styles.avatar} />
          </ListItemIcon>
        )}
        {data?.subMenu && !isActive && (
          <ListItemIcon
            className={styles.listAvatar}
            data-testid={`${data?.key}-icon`}
          >
            <img
              src={`${img}`}
              alt={route}
              className={styles.avatar}
              data-testid={`${data?.key}-image`}
            />
          </ListItemIcon>
        )}
        <ListItemIcon
          className={clsx(styles.listAvatarActive, styles.listAvatar)}
          data-testid={`${data?.key}-icon-active`}
        >
          <img
            src={`${activeImg}`}
            alt={route}
            className={styles.avatar}
            data-testid={`${data?.key}-image-active`}
          />
        </ListItemIcon>

        <ListItemText
          primary={data?.title}
          className={styles.listItemText}
          data-testid={data?.key}
        />

        {data.subMenu && (
          <>
            {activeSubMenu.includes(routeIndex) ? (
              isActive && <RemoveIcon />
            ) : (
              <AddIcon />
            )}
          </>
        )}
      </>
    );
  };

  const handlePopoverOpen = (evt) => setAnchorEl(evt.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  if (loading) return <></>;

  return (
    <Drawer
      anchor={"left"}
      variant="permanent"
      open={true}
      classes={{
        root: clsx(styles.drawer, { [styles.activeDrawer]: isActive }),
        paper: styles.drawerPaper,
      }}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <List className={styles.list}>
        {Boolean(multi_product) && (
          <>
            <ListItem
              button
              className={styles.productSelectDropdown}
              component="li"
              onClick={handlePopoverOpen}
              data-testid={"productnav-select-listitem"}
              aria-owns={anchorEl ? "product-menu-popover" : undefined}
              aria-haspopup="true"
            >
              <ListItemIcon className={styles.listAvatar}>
                <img
                  src={`${selected_product?.img}`}
                  alt={selected_product?.title}
                  className={styles.avatar}
                />
              </ListItemIcon>

              <ListItemText
                primary={selected_product?.title}
                className={styles.productItemText}
              />
              <ExpandIcon />
            </ListItem>

            <Popover
              keepMounted
              id={"product-menu-popover"}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handlePopoverClose}
              className={styles.popover}
              disableRestoreFocus
              PaperProps={{
                style: {
                  minWidth: 180,
                },
              }}
            >
              <Grid
                container
                spacing={0}
                direction="column"
                className={styles.popoverCont}
              >
                {product_menu?.map((indProduct, prodIndex) => (
                  <React.Fragment key={indProduct?.key}>
                    {Boolean(prodIndex) && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      className={clsx(styles.popoverItem, "cursor_pointer")}
                      onClick={() => onProductChangeEvent(indProduct?.key)}
                      data-testid={`sidenav-product-route-${prodIndex}`}
                    >
                      <Typography component="span" className={styles.imageItem}>
                        <img
                          src={"/images/menu-icons/subscribili-logo-red.svg"}
                          alt={indProduct?.title}
                          className={styles.avatar}
                        />
                      </Typography>
                      <Typography component="span">
                        {indProduct?.title}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Popover>

            <ListItem component="li">
              <Divider className={styles.productDivider} />
            </ListItem>
          </>
        )}

        {sub_menu?.map((route, routeIndex) => (
          <Fragment key={route?.key}>
            {route?.subMenu && <Divider className={styles.divider} />}
            <ListItem
              className={clsx(
                styles.listItem,
                `${activeTab(route.route) ? styles.activeItem : ""}`
              )}
              button
              component="li"
              onClick={() => onRouteChangeEvent(route, routeIndex)}
              data-testid={`sidenav-route-${routeIndex}`}
            >
              {renderMainList(route, routeIndex)}
            </ListItem>

            {route.subMenu && (
              <Collapse
                component="li"
                className={styles.subMenu}
                classes={{
                  wrapper: styles.subMenuWrapper,
                }}
                in={activeSubMenu.includes(routeIndex)}
                timeout="auto"
                unmountOnExit
              >
                <List className={styles.subList}>
                  {route?.subMenu?.map((submenu, index) => (
                    <ListItem
                      className={clsx(
                        styles.listItem,
                        `${activeTab(submenu.route) ? styles.activeItem : ""}`
                      )}
                      button
                      key={index}
                      component="li"
                      onClick={() => onRouteChangeEvent(submenu)}
                      data-testid={`sidenav-route-${routeIndex}-${index}`}
                    >
                      <ListItemIcon className={styles.listAvatar}>
                        <img
                          src={`${submenu?.img}`}
                          alt={route?.route}
                          className={styles.avatar}
                        />
                      </ListItemIcon>

                      <ListItemIcon
                        className={clsx(
                          styles.listAvatarActive,
                          styles.listAvatar
                        )}
                      >
                        <img
                          src={`${submenu?.activeImg}`}
                          alt={route?.route}
                          className={styles.avatar}
                        />
                      </ListItemIcon>
                      <ListItemText primary={submenu.title} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </Fragment>
        ))}
      </List>
    </Drawer>
  );
};

export default SideNav;
