import httpService from "../Api/http.service";

const { REACT_APP_API_BASE_URL } = process.env;

const triggerNotifyUser = (payload) => 
  httpService({
    url: REACT_APP_API_BASE_URL + `/notify/user`,
    method: "put",
    data: payload
  });

const emailService = {
  triggerNotifyUser
};

export default emailService;
