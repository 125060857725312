import useStyles from './styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as UploadIcon } from 'assets/images/upload-icon-2.svg';

const DragAndDropFiles = (props) => {
  const { fileTypes = [], maxFileSize = 0 } = props;
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.dropzone}>
        <Grid
          item
          xs={12}
          className="displayFlex justify-content-center mgBottom24"
        >
          <UploadIcon alt="upload icon" />
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h6" style={{ fontWeight: 400 }}>
            Drag and drop file here or <br />
            <Typography
              align="center"
              variant="h6"
              component="span"
              className={classes.highlightedText}
            >
              Select a file to Upload
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className="displayFlex justifyContentSpaceBetween">
          <Typography variant="body1">
            Supported format: {fileTypes.join(', ')}
          </Typography>
          <Typography variant="body1">
            Maximum Size: {maxFileSize} MB
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

DragAndDropFiles.propTypes = {
  fileTypes: PropTypes.array,
  maxFileSize: PropTypes.number,
};

export default DragAndDropFiles;
