import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

import { GridList, CompanyIdentityModal, Chip } from "components";
import { SUPPORT_TEXT } from "Views/Common/enum";
import { formatPhoneNumber } from "Shared/Utils";
import SectionWrapper from "components/SectionWrapper/SectionWrapper";

const Details = (props) => {
  const { name, data, onUpdate } = props;

  const {
    level_data = {},
    settings = {},
    account = {},
  } = data || {};

  const {
    company: {
      tax_id_provided = false
    } = {}
  } = account;

  const [isOpen, setIsOpen] = useState(false);

  const values = [
    {
      title: `${name} Name`,
      value: level_data?.name,
    },
    {
      title: "Region",
      value: level_data?.regionName,
      isHidden: !["Location"].includes(name),
    },
    {
      title: "Legal Name",
      value: level_data?.legal_name,
    },
    {
      title: "Billing NPI",
      value: level_data?.billing_npi,
      isHidden: !["Location"].includes(name),
    },
    {
      title: "Clearinghouse Facility ID",
      value: level_data?.ext_facility_id,
      isHidden: !["Location"].includes(name),
    },
    {
      title: "Website",
      value: level_data?.plan_website,
    },
    {
      title: "Email",
      value: level_data?.email,
      info: SUPPORT_TEXT?.email,
    },
    {
      title: "Patient Website",
      value: level_data?.patient_website,
      isHidden: !["Group"].includes(name),
    },
    {
      title: "Domain Prefix",
      value: level_data?.patient_url_part,
      isHidden: !["Group"].includes(name),
      info: SUPPORT_TEXT?.patient_url_part,
    },
    {
      title: "Phone Number",
      value: formatPhoneNumber(level_data?.phone),
      info: SUPPORT_TEXT?.phone,
    },
    {
      title: "Address",
      value: level_data?.display_address,
    },
    {
      title: "Industry",
      value: level_data?.industry,
      isHidden: ["Location", "Region"].includes(name),
    },
    {
      title: "Latitude",
      value: level_data?.latitude,
      isHidden: !["Location"].includes(name),
    },
    {
      title: "Longitude",
      value: level_data?.longitude,
      isHidden: !["Location"].includes(name),
    },
    {
      title: "EIN Number",
      value: tax_id_provided ? "Provided" : "Not Provided",
    },
    {
      title: "Savings Summary Description",
      value: settings?.fee_schedule_desc,
      isHidden: !["Group", "Location"].includes(name),
      info: SUPPORT_TEXT?.fee_schedule_desc
    },
    {
      title: "Plan Summary Description",
      value: settings?.plan_summary_desc,
      isHidden: !["Group"].includes(name),
      info: SUPPORT_TEXT?.plan_summary_desc
    },
    {
      title: "Location Status",
      value: <Chip label={level_data?.status} status={level_data?.status} />,
      isHidden: !["Location"].includes(name),
    },
  ];

  return (
    <SectionWrapper
      title={`${name} Details`}
      rightSection={
        <Button
          size="large"
          color="secondary"
          onClick={() => setIsOpen(true)}
          data-testid={`edit-${name}-details`}
        >
          Edit
        </Button>
      }
    >
      <GridList values={values} />

      {
        isOpen && (
          <CompanyIdentityModal
            data={data}
            isOpen={isOpen}
            closePopup={() => setIsOpen(false)}
            onUpdate={onUpdate}
          />
        )
      }
    </SectionWrapper>
  );
};

Details.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.object,
  onUpdate: PropTypes.func
}

export default Details;
