import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField, Typography, Grid } from '@material-ui/core';

import { PlansContext } from 'Views/Plans/context';
import {
  TIER_UPDATED,
  MEMBER_TIER_UPDATED,
  UPDATE_PRICE_VALIDITY,
  UPDATE_VALID_MEMBER_TO,
} from 'Views/Plans/context/actions';
import { SectionHeader } from 'components';
import { getTargetValue } from 'Shared/Utils';


const MemberTier = (props) => {
  const { plan = {}, planIndex } = props;

  const {
    state: { isValidPrice, highlightErrors, productInfo },
    dispatch,
  } = useContext(PlansContext);

  const { member_tier = {}, tiers = [] } = plan;
  const { member_from, member_to, price_name } = member_tier;
  const { unit_amount, upfront_amount = 0, signup_amount = 0 } = tiers?.[0] || {};
  const { additional_members } = productInfo;
  const totalMembers = additional_members + 1;
  // const tierId = `plan-tier-${planIndex + 1}`;

  //for validating the mandatory fields
  useEffect(() => {
    const isError =
      !price_name?.trim()?.length ||
      member_to < member_from ||
      member_to > totalMembers;

    const isCurrentValid = isValidPrice[planIndex]?.member_tier;

    if ((isError && !isCurrentValid) || (!isError && isCurrentValid)) return;

    dispatch({
      type: UPDATE_PRICE_VALIDITY,
      payload: {
        isValidPrice: {
          ...isValidPrice,
          [planIndex]: {
            ...isValidPrice[planIndex],
            member_tier: !isError,
          },
        },
      },
    });
  }, [price_name, unit_amount, member_to]);

  useEffect(() => {
    dispatch({
      type: UPDATE_VALID_MEMBER_TO,
      payload: {
        isValidMemberTo: !(
          member_to >= totalMembers || member_to < member_from
        ),
      },
    });
  }, [member_from, member_to, additional_members]);

  const fields = [
    {
      label: 'Tier Name',
      name: 'price_name',
      value: price_name,
      inputType: 'text',
      enableAdornment: false,
      required: true,
      disabled: false,
    },
    {
      label: 'First Unit',
      name: 'member_from',
      value: member_from,
      inputType: 'number',
      enableAdornment: false,
      required: true,
      disabled: false,
      inputProps: {
        type: 'number',
        min: 1,
        max: totalMembers
      },
    },
    {
      label: 'Last Unit',
      name: 'member_to',
      value: member_to,
      inputType: 'number',
      enableAdornment: false,
      required: true,
      disabled: false,
      inputProps: {
        type: 'number',
        min: 1,
        max: totalMembers
      },
    },
    {
      label: 'Unit Amount',
      name: 'unit_amount',
      value: unit_amount || 0,
      inputType: 'number',
      enableAdornment: true,
      required: true,
      disabled: false,
      inputProps: {
        type: 'number',
        min: 0,
      },
    },
    {
      label: 'Upfront Cost',
      name: 'upfront_amount',
      value: upfront_amount || 0,
      inputType: 'number',
      enableAdornment: true,
      disabled: false,
      inputProps: {
        type: 'number',
        min: 0,
      },
    },
    {
      label: 'Activation Cost',
      name: 'signup_amount',
      value: signup_amount || 0,
      inputType: 'number',
      enableAdornment: true,
      disabled: false,
      inputProps: {
        type: 'number',
        min: 0,
      },
    },
  ];

  const dispatchTierValues = (updated_tiers) => {
    dispatch({
      type: TIER_UPDATED,
      payload: { tiers: updated_tiers, planIndex },
    });
  };

  const dispatchMemberTierValue = (name, value) => {
    dispatch({
      type: MEMBER_TIER_UPDATED,
      payload: { member_tier: { ...member_tier, [name]: value }, planIndex },
    });
  };

  const handleOnValueChange = (evt) => {
    const { name } = evt?.target || {};
    const value = getTargetValue(evt);

    const tierElem = ['unit_amount', 'upfront_amount', 'signup_amount'];
    const numberElem = ['member_from', 'member_to'];

    if (tierElem.includes(name)) {
      const updatedTiers = tiers.map((tier) => {
        return {
          ...tier,
          [name]: value,
        };
      });

      dispatchTierValues(updatedTiers);
    } else if (numberElem.includes(name)) {
      let updatedValue = value;
      if (value > totalMembers) updatedValue = totalMembers;

      dispatchMemberTierValue(name, updatedValue);
    } else {
      dispatchMemberTierValue(name, value);
    }
  };

  const renderInputField = (
    name,
    label,
    value,
    inputType,
    enableAdornment = false,
    required = false,
    disabled = false,
    inputProps = {},
  ) => {
    return (
      <TextField
        required={required}
        variant="outlined"
        type={inputType}
        fullWidth
        disabled={disabled}
        error={Boolean(required && highlightErrors && !value)}
        label={label}
        name={name}
        value={value}
        onChange={handleOnValueChange}
        InputProps={{
          startAdornment: enableAdornment && (
            <InputAdornment position="start">$</InputAdornment>
          ),
        }}
        inputProps={{
          ...inputProps,
        }}
      />
    );
  };

  return (
    <>
      <SectionHeader
        title="Member Tier"
        titleClasses={['font-weight-400']}
        titleVariant="h6"
        titleComponent="h6"
      />

      <Grid container spacing={3} className="mg_top_24">
        {fields.map(
          ({
            label,
            name,
            value,
            inputType,
            enableAdornment,
            required,
            disabled,
            inputProps,
          }, index) => {
            return (
              <Grid item xs={12} md={6} lg={2} key={`member-tier-field-${index + 1}`}>
                {renderInputField(
                  name,
                  label,
                  value,
                  inputType,
                  enableAdornment,
                  required,
                  disabled,
                  inputProps,
                )}
              </Grid>
            );
          },
        )}
      </Grid>
      {Boolean(highlightErrors && !isValidPrice[planIndex]?.member_tier) && (
        <Typography color="error" align="right" className="mg_top_20">
          * Please fill the required fields correctly
        </Typography>
      )}
    </>
  );
};

MemberTier.propTypes = {
  plan: PropTypes.object.isRequired,
  planIndex: PropTypes.number.isRequired,
};

export default MemberTier;
