import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import {
  Grid,
  TextField,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import {
  Save as SaveIcon,
  Edit as EditIcon,
  Close as CloseIcon
} from '@material-ui/icons';
import { GROUP_SERVICES } from "Services";
import { getTargetValue } from "Shared/Utils";
import styles from './CommunicationTemplates.module.scss'
import { TableListView } from "components";
import { Context } from "context";
import { ADD_NOTIFICATION } from 'context/actions';
import { formatDate } from "utils";

const CommunicationTemplates = (props) => {
  const { event_payload, group_id, onUpdate } = props;

  const [eventsData, setEventsData] = useState([]);
  const [editedRow, setEditedRow] = useState('');
  const [loadingRow, setLoadingRow] = useState('');

  const { dispatch: globalDispatch } = useContext(Context);
  const setGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          severity,
          message
        }
      }
    });

  const getEventsData = (props) => {
    const all_events = [];

    for (const [key, value] of Object.entries(event_payload)) {
      const {
        template_id = "",
        updated_by = "",
        updated_at = ""
      } = value;

      all_events.push({
        event: key,
        sendgrid_id: template_id,
        updated_by,
        updated_at: updated_at ? formatDate(updated_at * 1000) : '-'
      });
    }

    setEventsData(all_events);
  };

  useEffect(() => {
    getEventsData();
  }, [event_payload]);

  const handleSubmitChanges = async (index) => {
    setLoadingRow(index);

    try {
      if (eventsData[index].sendgrid_id !== event_payload[eventsData[index].event]) {
        const updatedEvent = {
          event: eventsData[index].event,
          template_id: eventsData[index].sendgrid_id
        };

        const payload = {
          email_templates: [updatedEvent],
          email_state: 'enabled'
        };

        const response = await GROUP_SERVICES.updateSendgridId(group_id, payload);
        if (response?.type === "success") {
          setGlobalNotification('success', 'SendGrid Id Updated Successfully');

          setEditedRow('');
          onUpdate && onUpdate("Settings");
        }
        else {
          throw (response?.message || response?.error);
        }
      }
      else {
        setEditedRow('');
      }
    }
    catch (err) {
      setGlobalNotification("error", err || "Error occured while updating Sendgrid Id");
    }

    setLoadingRow("");
  };

  const updateValues = (id, key, value) => {
    const updatedEvents = _.cloneDeep(eventsData);
    const eventName = eventsData[id]?.event || "";
    const updatedValue = value 
      ? value
      : event_payload[eventName][key];

    updatedEvents[id] = {
      ...updatedEvents[id],
      [key]: updatedValue
    };

    setEventsData(updatedEvents);
  };

  const options = {
    filter: false,
    sort: false,
    selectableRows: 'none',
    responsive: 'vertical',
    print: false,
    pagination: true,
    search: false,
    download: false,
    viewColumns: false,
    elevation: 0
  };

  const renderTableInput = (value, name, rowId) => {
    return (
      <TextField
        required
        fullWidth
        variant="outlined"
        margin="dense"
        value={value}
        name={name}
        onChange={(event) => updateValues(rowId, name, getTargetValue(event))}
        inputProps={{
          'data-testid': `communication-template-${name}`
        }}
      />
    );
  };

  const renderActionButtons = (rowIndex) => {
    if(editedRow !== rowIndex) {
      return (
        <IconButton size="small" color="primary" disabled={Boolean(loadingRow !== "")} data-testid={`edit-communication-button-${rowIndex}`}>
          <EditIcon
            className={Boolean(loadingRow === "") && "theme_color_orange"}
            onClick={ () => setEditedRow(rowIndex) }
          />
        </IconButton>
      ) 
    }
    else if(loadingRow === rowIndex) {
      return (
        <IconButton size="small" color="primary" data-testid={`communication-loading-button-${rowIndex}`}>
          <CircularProgress
            className="theme_color_orange"
            size={20}
          />
        </IconButton>
      )
    }
    else {
      return (
      <>
        <IconButton size="small" color="primary" data-testid={`communication-submit-button-${rowIndex}`}>
          <SaveIcon
            className="theme_color_orange"
            onClick={async () => await handleSubmitChanges(rowIndex)}
          />
        </IconButton>

        <IconButton size="small" color="primary" data-testid={`communication-close-button-${rowIndex}`}>
          <CloseIcon
            className="theme_color_orange"
            onClick={ () => {
              setEditedRow('');
              updateValues(rowIndex, 'template_id');
            } }
          />
        </IconButton>
      </>
      )
    }
  };

  const commonCellProps = () => ({ className: styles.tableColumn });
  const tableColumns = [
    {
      name: "event",
      label: "Triggering Events",
      options: {
        setCellHeaderProps: () => ({ className: styles.trigger_events }),
        setCellProps: commonCellProps
      }
    },
    {
      name: "sendgrid_id",
      label: "Sendgrid ID",
      options: {
        setCellHeaderProps: () => ({ className: styles.sendgrid_ids }),
        setCellProps: () => ({ className: styles.sendgrid_data }),
        customBodyRender: (value, tableMetaData) => {
          const { rowIndex } = tableMetaData;

          return (editedRow === rowIndex && loadingRow !== rowIndex)
            ? renderTableInput(value, 'sendgrid_id', rowIndex)
            : value
        }
      }
    },
    {
      name: "updated_by",
      label: "Updated by",
      options: {
        setCellHeaderProps: () => ({ className: styles.updated_by }),
        setCellProps: commonCellProps,
      }
    },
    {
      name: "updated_at",
      label: "Updated at",
      options: {
        setCellHeaderProps: () => ({ className: styles.updated_at }),
        setCellProps: commonCellProps,
      }
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        setCellHeaderProps: () => ({ className: styles.icons }),
        setCellProps: commonCellProps,
        customBodyRender: (value, tableMetaData) => {
          const { rowIndex } = tableMetaData;

          return (
            <Grid container spacing={2}>
              <Grid item alignItems="center" justifyContent="center">
                {renderActionButtons(rowIndex)}
              </Grid>
            </Grid>
          )
        }
      }
    }
  ];

  return (
    <TableListView
      headerTitle="Email Templates"
      tableData={eventsData}
      tableColumns={tableColumns}
      tableOptions={options}
      headerSection={true}
      emptyPlaceHolderContent="No Email Template details found"
    />
  );
};

export default CommunicationTemplates;