import { USER_SERVICES } from "Services";
import {
  SUPER_ADMIN,
  SUBSCRIBILI_USER,
  GROUP_ADMIN,
  PARTNER_ADMIN,
  LOCATION_ADMIN,
  REGION_ADMIN,
  REVSHARE_ADMIN,
} from "global-constants/roles";

export const roleSpecificUserData = async (user) => {
  const userDetails = { ...user };
  const role = userDetails?.['custom:role'].split(':');

  if (role[1] !== SUPER_ADMIN.key && role[1] !== SUBSCRIBILI_USER.key) {
    const dbList = user['custom:dblist'].split(',');
    const levels = dbList[1].split(':');
    const levelIds = dbList[2].split(':');

    userDetails.level = levels[1];
    userDetails.levelId = levelIds[1];

    try {
      const userResponse = await USER_SERVICES.getUser();

      if (userResponse?.type === 'success' && userResponse.data) {
        userDetails.details = userResponse.data;

        const settingsResponse = await USER_SERVICES.getSettings(
          levels[1],
          levelIds[1],
        );

        if (settingsResponse?.type === 'success' && settingsResponse?.data) {
          const { logo } = settingsResponse?.data?.settings || {};

          if (logo) {
            const { data: logoData = {} } =
              (await USER_SERVICES.getAWSAccessURL(logo.key)) || {};
            logoData.url && (userDetails.logoURL = logoData.url);
          }

          userDetails.settings = settingsResponse.data;
        } else {
          throw settingsResponse;
        }
      } else {
        throw userResponse;
      }
    } catch (error) {
      console.log(error);
    }
  }

  localStorage.setItem("userDetail", JSON.stringify(userDetails));
  return userDetails;
};

export const checkIfAdmin = (userInfo) => {
  const role = userInfo?.["custom:role"];

  if (
    role &&
    (role.includes(GROUP_ADMIN.key) ||
      role.includes(PARTNER_ADMIN.key) ||
      role.includes(LOCATION_ADMIN.key) ||
      role.includes(REGION_ADMIN.key) ||
      role.includes(REVSHARE_ADMIN.key))
  ) {
    return true;
  }
  return false;
};
