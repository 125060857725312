import httpService from "../Api/http.service";

const { REACT_APP_API_BASE_URL } = process.env;
const getInvoiceURL = () => `${REACT_APP_API_BASE_URL}/invoice`;

const getInvoicePDF = (id) =>
  httpService({
    url: getInvoiceURL() + `/${id}/pdfinline`,
    method: "get",
  });

const getReimburseInfo = (subscription_id, patient_id) => {
  const params = `subscriptions/${subscription_id}/reimburse?patient_id=${patient_id}`;
  return httpService({
    url: getInvoiceURL() + `/${params}`,
    method: "get",
  });
};

const invoiceService = {
  getInvoicePDF, getReimburseInfo
};

export default invoiceService;