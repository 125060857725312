import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    border: `1px solid ${theme.palette.text.disabled}`,
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(4),
    boxSizing: 'content-box',
    width: 'fit-content',
    background: theme.palette.background.paper,
  },
  widgetContainer: {
    height: 'unset !important',
    marginBottom: theme.spacing(3),
  },
  multiSelect: {
    '& .MuiSelect-selectMenu': {
      textOverflow: 'unset',
      overflow: 'unset',
    },
  },
  showMoreTextColor: {
    color: theme.palette.text.lightBlue,
  },
  planTitleTabs: {
    minHeight: 42,

    '& .MuiTab-wrapper': {
      textTransform: 'none',
    },
    '& .MuiTabs-flexContainer': {
      '& .MuiTab-root': {
        fontSize: '0.84rem',
        minWidth: 100,
        minHeight: 42,
      },
      '& .Mui-selected': {
        background: `
            linear-gradient(95.69deg, 
            ${theme.palette.primary.highlight} 4.34%, 
            ${theme.palette.primary.highlight2} 55.62%, 
            ${theme.palette.primary.highlight3} 91.79%)
        `,
        color: theme.palette.primary.contrastText,
        borderRadius: theme.spacing(4),
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  errorForm: {
    borderColor: theme.palette.text.errorBorder,
  },
  memberTypeTitle: {
    marginBottom: `8px`,
  },
  deleteIcon: {
    height: `18px !important`,
    width: `18px !important`,
    color: `#292D32 !important`,
    marginRight: `10px !important`,
  },
  memberTypeChip: {
    fontSize: `14px`,
    margin: `0px 10px 0 0`,
    padding: `24px 8px`,
    backgroundColor: `transparent`,
    borderRadius: `24px`,
    border: `1px solid var(--theme-text-disabled) !important`,
  },
  selectedChip: {
    border: `1px solid var(--theme-highlight-color) !important`,
  },
}));

export default useStyles;
