import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {
  width: "100%",
  height: "100%",
};

const Maps = (props) => {
  const {
    google,
    setLocation,
    latitude,
    longitude,
    bounds: updatedBounds,
    disabled = false,
  } = props;

  const [bounds, setBounds] = useState();
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState(4);
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (latitude && longitude) {
      setMarker({
        position: {
          lat: latitude,
          lng: longitude,
        },
      });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (updatedBounds) {
      setCenter(updatedBounds);
      setZoom(14);
    }
  }, [updatedBounds]);

  const fetchPlaces = () => setCenter(new google.maps.LatLng(40, -100));

  const mapClicked = (mapProps, map, event) => {
    if (disabled) {
      return;
    }

    const { latLng } = event;

    setMarker({
      position: latLng,
    });

    setLocation({
      latitude: latLng.lat().toFixed(2),
      longitude: latLng.lng().toFixed(2),
    });
  };

  return (
    <Map
      google={google}
      zoom={zoom}
      style={mapStyles}
      initialCenter={center}
      center={center}
      onReady={fetchPlaces}
      onClick={mapClicked}
      bounds={bounds}
      fullscreenControl={false}
      draggable={!disabled}
      zoomControl={!disabled}
    >
      {marker && <Marker position={marker.position} />}
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
})(Maps);
