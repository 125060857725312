const {
  REACT_APP_AWS_REGION,
  REACT_APP_COGNITO_POOL_ID,
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_COGNITO_WEB_CLIENT_ID,
} = process.env;

const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: REACT_APP_COGNITO_POOL_ID,
      userPoolClientId: REACT_APP_COGNITO_WEB_CLIENT_ID,
      identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
      region: REACT_APP_AWS_REGION,
      mfa: {
        status: 'optional'
      },
    },
  },
};

export default amplifyConfig;
