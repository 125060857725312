import httpService from "../Api/http.service";

const fetchRegionByQueryParams = (partner_id, group_id) => {
  let api = group_id
    ? `/regions?partner_id=${partner_id}&group_id=${group_id}`
    : `/regions?partner_id=${partner_id}`;

  return httpService({
    url: api,
    method: "get",
  });
};

const createRegion = (payload) =>
  httpService({
    url: `/region`,
    method: "post",
    data: payload,
  });

  const updateRegion = (payload) =>
    httpService({
      url: `/region`,
      method: "put",
      data: payload,
    });

const deleteRegion = (payload) =>
  httpService({
    url: `/region`,
    method: "delete",
    data: payload,
  });

const regionService = {
  createRegion,
  updateRegion,
  deleteRegion,
  fetchRegionByQueryParams
};

export default regionService;
