import React, { useState, useEffect } from "react";
import { TableListView } from "components";
import { UploadMaterialModal, DeleteMaterialModal } from "../../components";
import { IconButton, Button, Grid, Typography  } from "@material-ui/core";
import {
  Visibility as VisibilityIcon,
  Delete as DeleteIcon
} from "@material-ui/icons";
import { USER_SERVICES } from "Services";
import { formatDate } from 'utils';
import styles from "./UploadMarketingMaterial.module.scss";

const UploadMarketingMaterial = (props) => {
  const {
    group_id,
    data: marketingMaterial = [],
    onUpdate
  } = props;

  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteKey, setDeleteKey] = useState("");
  const [urlKey, setUrlKey] = useState({});

  const tableData = marketingMaterial.map(material => {
    const fileSize = ((material?.size / 1024) / 1024).toFixed(3);

    return {
      ...material,
      size: `${fileSize} MB`,
      last_edited: formatDate(material?.last_edited * 1000) || '-',
      key: {isDefault: material?.is_default, key: material?.key}
    };
  });

  const tableColumns = [
    {
      name: 'name',
      label: 'File Name',
    }, {
      name: 'size',
      label: 'File Size'
    },
    {
      name: 'last_edited',
      label: 'Last Edited'
    },
    {
      name: 'key',
      label: 'Actions',
      options: {
        customBodyRender: (value, { rowIndex }) => (
          <>
            <IconButton disableRipple size="small" className="mr-10" aria-label="edit" onClick={() => handleViewPdf(value)} data-testid={`view-material-button-${rowIndex}`}>
              <VisibilityIcon className="theme_color_orange" fontSize="inherit" />
            </IconButton>
            <IconButton disableRipple size="small" aria-label="delete" onClick={() => handleDeletePdf(value)} data-testid={`delete-material-button-${rowIndex}`}>
              <DeleteIcon className="theme_color_orange" fontSize="inherit" />
            </IconButton>
          </>
        )
      },
    }
  ]

  const openUploadModal = () => setUploadModalOpen(true);
  const closeUploadModal = () => setUploadModalOpen(false);

  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  
  useEffect(() => {
    const getURLs = async (keys) => {
      try {
        const { data, type } = await USER_SERVICES.getAWSAccessURLs(keys);
        
        if (type === 'success' && data) {
          setUrlKey(data);
        }
        else {
          throw new Error();
        }
      }
      catch(err) {
        console.log(err);
      }
    };

    const keyURLs = marketingMaterial?.map(value => value.key);

    keyURLs.length && getURLs(keyURLs);
  }, [marketingMaterial]);

  const handleViewPdf = (value) => {
    if(value?.isDefault) {
      window.open(value?.key, "_blank", "noopener noreferrer");
    } else {
      window.open(urlKey[value?.key], "_blank", "noopener noreferrer");
    }
  };

  const handleDeletePdf = (value) => {
    setDeleteKey(value?.key);
    openDeleteModal();
  };

  const btnProps = {
    otherButtons: (
      <Button
        onClick={openUploadModal}
        color="secondary"
        size="large"
        data-testid='add-new-material'
      >
        Add New Material
      </Button>
    )
  };

  const renderEmptyContent = () => (
    <Grid container alignItems="stretch">
      <Grid item xs={12} className={styles.upload_section}>
        <Grid continer alignment="stretch">
          <Grid item xs={12} className={styles.upload_content}>
            <Typography variant='h5'>
              You don't have any associatied marketing materials yet
            </Typography>
            <Typography>
              Once you upload a marketing material, you'll see it here
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.upload_button}>
        <Button
          variant="contained"
          onClick={openUploadModal}
          color="secondary"
          size="large"
          data-testid='upload-new-material'
        >
          Upload Marketing Material
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <>
      <TableListView
        headerTitle={`Marketing Materials`}
        tableData={tableData}
        tableOptions={{}}
        tableColumns={tableColumns}
        pagination={false}
        headerSection={true}
        emptyPlaceHolderDom={renderEmptyContent()}
        {...btnProps}
      />

      {
        uploadModalOpen && 
          <UploadMaterialModal
            handleClose={closeUploadModal}
            groupId={group_id}
            onUpdate={onUpdate}
          />
      }

      {
        deleteModalOpen &&
          <DeleteMaterialModal
            handleClose={closeDeleteModal}
            group_id={group_id}
            pdfKey={deleteKey}
            onUpdate={onUpdate}
          />
      }
    </>
  );
};

export default UploadMarketingMaterial;
