import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tabsWrapper: {
    minHeight: 'unset',
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: '2rem',
    position: 'relative',
    overflow: 'visible',
    padding: 4,

    '& .compact': {
      height: '100%',
      minHeight: 'unset',
      padding: '2px 10px',
      borderRadius: '2rem',
      color: theme.palette.text.secondary,
    },
    '& .compact.Mui-selected': {
      backgroundColor: `var(--theme-highlight-color-bg)`,
      color: theme.palette.text.highlight,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-wrapper': {
      textTransform: 'none',
      fontSize: 14,
    }
  },
}));

export default useStyles;
