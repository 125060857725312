import React from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import { Chip, SectionWrapper } from "components";

const AssociatedPartner = ({ accountDetails, infoDetail }) => {
  const getStatus = (status) =>
    status ? "Enabled" : "Disabled";

  const getStatusColor = (status) =>
    status ? "color_success" : "color_error";

  return (
    <SectionWrapper title={"Balance Details"}>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} className="d-flex">
          <Typography variant="h4">{infoDetail?.display_name}</Typography>
          <Typography variant="h4" className="mg_left_8">
            <Chip
              status={accountDetails?.charges_enabled ? "success" : "error"}
              label={
                accountDetails?.charges_enabled
                  ? "TRANSFERS ENABLED"
                  : "TRANSFERS DISABLED"
              }
            />
          </Typography>
        </Grid>

        <Grid item xs={12} lg={2}>
          <Typography variant="h5" className="bold">
            -
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Lifetime Value
          </Typography>
        </Grid>

        <Grid item xs={12} lg={1}>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={12} lg={2}>
          <Typography variant="h5" className="bold">
            -
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Current Balance
          </Typography>
        </Grid>

        <Grid item xs={12} lg={1}>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={12} lg={2}>
          <Typography
            variant="h5"
            className={`bold ${getStatusColor(
              accountDetails?.charges_enabled
            )}`}
          >
            {getStatus(accountDetails?.charges_enabled)}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Payouts
          </Typography>
        </Grid>

        <Grid item xs={12} lg={1}>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={12} lg={2}>
          <Typography
            variant="h5"
            className={`bold ${getStatusColor(
              accountDetails?.payouts_enabled
            )}`}
          >
            {getStatus(accountDetails?.payouts_enabled)}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Transfer
          </Typography>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default AssociatedPartner;
