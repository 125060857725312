import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  discountContainer: {
    marginTop: theme.spacing(1.5),
  },
  subtitle: {
    marginRight: 8,
    marginTop: 8,
  },
  infoIcon: {
    margin: '5px 10px 0 0'
  },
  flatDiscountInput: {
    width: 60,
    padding: 10,
    marginRight: 5,
  },
  percentLabel: {
    margin: '0 0.5rem',
  },
  xsmallColumn: {
    width: '5%',
  },
  smallColumn: {
    width: '10%',
  },
  mediumColumn: {
    width: '15%',
  },
  largeColumn: {
    width: '30%',
  },
  tabsContainer: {
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: theme.spacing(1.5),
    boxSizing: 'content-box',
    width: 'fit-content',
    background: theme.palette.background.paper,
    margin: theme.spacing(0, 1.5, 0, 0.75),
  },
  discountTabs: {
    minHeight: 28,

    '& .MuiTab-wrapper': {
      textTransform: 'none',
    },
    '& .MuiTabs-flexContainer': {
      padding: '2px',

      '& .MuiTab-root': {
        fontSize: '0.84rem',
        minWidth: 50,
        minHeight: 28,
        padding: '2px',
      },
      '& .Mui-selected': {
        background: `
            linear-gradient(95.69deg, 
            ${theme.palette.primary.highlight} 4.34%, 
            ${theme.palette.primary.highlight2} 55.62%, 
            ${theme.palette.primary.highlight3} 91.79%)
        `,
        color: theme.palette.primary.contrastText,
        borderRadius: theme.spacing(1.5),
      }
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    }
  },
  serviceDataHeader: {
    marginTop: 20
  },
  flatChargeForm: {
    marginRight: 0,
    alignItems: 'flex-start',

    '& .MuiFormControlLabel-label': {
      width: '100%'
    }
  },
}));

export default useStyles;
