import httpService from '../Api/http.service';

const { REACT_APP_API_BASE_URL } = process.env;
const getReportsBaseUrl = () => `${REACT_APP_API_BASE_URL}/report`;

const generateReport = (data, type = 'transfer_payout') =>
  httpService({
    url: getReportsBaseUrl() + `/${type}/excel`,
    method: 'post',
    data,
    headers: {
      Accept: 'application/ms-excel',
    },
    responseType: 'arraybuffer',
  });

const generateCommonReport = (data, endpoint) =>
  httpService({
    url: getReportsBaseUrl() + `/${endpoint}`,
    method: 'post',
    data,
    headers: {
      Accept: 'application/ms-excel',
    },
    responseType: 'arraybuffer',
  });

const getReportSummary = (data) =>
  httpService({
    url: getReportsBaseUrl() + `/dashboard/summary`,
    method: 'post',
    data,
  });

const getHistory = (data) =>
  httpService({
    url: getReportsBaseUrl() + `/payout/history`,
    method: 'post',
    data,
  });

const getTransferSummary = (group_id, data) =>
  httpService({
    url: getReportsBaseUrl() + `/group/${group_id}/transfer`,
    method: 'post',
    data,
  });

const generateTransfersReport = (group_id, data) =>
  httpService({
    url: getReportsBaseUrl() + `/group/${group_id}/transfer/excel`,
    method: 'post',
    data,
    headers: {
      Accept: 'application/ms-excel',
    },
    responseType: 'arraybuffer',
  });

const getCustomReport = (params, type = 'new-subscribers') =>
  httpService({
    url: getReportsBaseUrl() + `/custom/${type}/excel?${params}`,
    method: 'get',
    headers: {
      Accept: 'application/ms-excel',
    },
    responseType: 'arraybuffer',
  });

const getReports = () =>
  httpService({
    url: 'reports/custom',
    method: 'get',
  });

const getSampleImportFile = () =>
  httpService({
    url: getReportsBaseUrl() + '/subscriptions/migrate',
    method: 'get',
    headers: {
      Accept: 'application/ms-excel',
    },
    responseType: 'arraybuffer',
  });

const postImportFile = (data) =>
  httpService({
    url: getReportsBaseUrl() + '/subscriptions/migrate',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

const reportService = {
  generateReport,
  getReportSummary,
  getHistory,
  getTransferSummary,
  generateTransfersReport,
  getCustomReport,
  getReports,
  generateCommonReport,

  getSampleImportFile,
  postImportFile,
};

export default reportService;
