export const STRIPE_ERRORS = {
  CHARGE_DECLINED: "card_declined",
  CHARGE_DECLINED_INSUFFICIENT_FUNDS: "insufficient_funds",
  CHARGE_DECLINED_LOST_CARD: "lost_card",
  CHARGE_DECLINED_EXPIRED_CARD: "expired_card",
  CHARGE_DECLINED_STOLEN_CARD: "stolen_card",
  CHARGE_DECLINED_PROCESSING_ERROR: "processing_error",
  CHARGE_DECLINED_INCORRECT_NUMBER: "incorrect_number",
};

export const STRIPE_ERRORS_MESSAGES = {
  CHARGE_DECLINED:
    "Unable to process the enrollment; The card  has been declined for an unknown reason. Please try again using another card.",
  CHARGE_DECLINED_LOST_CARD:
    "Unable to process the enrollment; The card has been declined for an unknown reason. Please try again using another card.",
  CHARGE_DECLINED_STOLEN_CARD:
    "Unable to process the enrollment; The card has been declined for an unknown reason. Please try again using another card.",
  CHARGE_DECLINED_INSUFFICIENT_FUNDS:
    "Card entered has insufficient funds. Please try again using another card.",
  CHARGE_DECLINED_EXPIRED_CARD:
    "Card entered is expired. Please try again using another card.",
  CHARGE_DECLINED_PROCESSING_ERROR:
    "An error occurred while processing the card. Try again in a little bit.",
  CHARGE_DECLINED_INCORRECT_NUMBER:
    "The card number you entered is not valid. Please double-check the number and try again.",
};

export const CPT_STATUS = {
  completed: "Completed",
  Complete: "Completed",
  Scheduled: "Scheduled",
  scheduled: "Scheduled",
};

export const TERMS_DETAILS_REPLACE_MACROS = {
  group_name: "{{GROUP_NAME}}",
  group_url: "{{GROUP_URL}}",
  group_email: "{{GROUP_EMAIL}}",
  group_members: "{{GROUP_MEMBERS}}",
  group_patient_portal: "{{GROUP_PATIENT_PORTAL}}",
  group_industry: "{{GROUP_INDUSTRY}}",
  subscribili_support_email: "{{SUBSCRIBILI_SUPPORT_EMAIL}}",
};

export const ALL_PARTNERS = {
  partner_name: "All Partners",
  partner_id: "ALL_PARTNERS",
};

export const ALL_GROUPS = {
  group_name: "All Groups",
  group_id: "ALL_GROUPS",
  partner_id: "ALL_PARTNERS",
};

export const ALL_REGIONS = {
  region_name: "All Regions",
  region_id: "ALL_REGIONS",
  group_id: "ALL_GROUPS",
};

export const ALL_LOCATIONS = {
  location_name: "All Locations",
  location_id: "ALL_LOCATIONS",
  group_id: "ALL_GROUPS",
};

export const ALL_PLANS = {
  plan_name: "All Plans",
  plan_package_id: "ALL_PLANS",
  group_id: "ALL_GROUPS",
};
export const SUBSCRIBILI_PARTNER = {
  partner_id: "subscribili",
  partner_name: "Subscribili",
};

export const PAYMENT_TYPES = {
  Card: 'Card',
  ACH: 'ACH',
  HSA_FSA: 'HSA_FSA'
}
