import React from "react";
import clsx from "clsx";
import { Typography, Grid, Link, List, ListItem } from "@material-ui/core";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Label,
  Tooltip,
} from "recharts";
import { MAX_TOOLTIP_CPT_ROWS } from 'Views/Common/enum';
import styles from './BarLineCombinedChart.module.scss';

const BarLineCombinedChart = (props) => {
  const {
    data,
    barDataKey,
    lineDataKey,
    barColor,
    lineColor,
    referenceFrequency = [],
    tooltipText = "active users",
    xOrientation = 'top'
  } = props;
  const barSize = (data.length > 4) ? 50 : 75;

  const renderBenefitsTooltip = (services) => {
    const slicedData = [...services].slice(0, MAX_TOOLTIP_CPT_ROWS);

    if(!slicedData.length) return <></>;

    return (
      <Grid container spacing={2} alignItems="center" justifyContent="center" className={styles.customTooltip}>
        {
          slicedData.map((service, index) => {
            const { name, value } = service;

            if(!name) return <></>;

            return (
              <Grid item key={name} className={styles.toolTipItem}>
                <Typography align="left" variant="body1" component="h4" className={styles.toolTipValue}>
                  <Typography component="span" className={styles.toolTipName}>{name}</Typography>
                  {` x ${value}`}
                </Typography>
              </Grid>
            )
          })
        }
        {
          Boolean(services.length > MAX_TOOLTIP_CPT_ROWS) &&
          <Grid item xs={12} key={'view-more'} className={styles.toolTipViewMore}>
            <Typography align="center" variant="body1" component="h4" className={styles.toolTipValue}>
              <Link>
                View more details in Appointment history
              </Link>
            </Typography>
          </Grid>
        }
      </Grid>
    );
  };

  const renderRenewalTooltip = (services) => {
    if(!services.length) return <></>;

    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="column"
        className={clsx(styles.customTooltip, styles.customRenewalTooltip, 'renewal-tooltip')}
      >
        {services.map((service) => {
          const { name, value } = service;

          if(!name) return <></>;

          return (
            <List key={name} className={styles.renewalTooltipList}>
              <ListItem
                align="left"
                variant="body1"
                component="h4"
                className={styles.renewalTooltipItem}
              >
                <Typography
                  component="span"
                  className={styles.renewalTooltipText}
                >
                  <Grid container spacing={0} justifyContent="space-between">
                    <Typography className={styles.toolTipText}>
                      {name}
                    </Typography>
                    <Typography className={styles.toolTipText}>
                      {value}
                    </Typography>
                  </Grid>
                </Typography>
              </ListItem>
            </List>
          );
        })}
      </Grid>
    );
  };

  const CustomTooltip = ({ active, payload = [] }) => {
    const [pData = {}] = payload;
    const {
      payload: {
        type: tType,
        services = []
      } = {},
      value
    } = pData;

    if(!active) return <></>;

    return (
      <>
        {
          Boolean(tType) ? (
            <>
              { tType === 'benefits' && renderBenefitsTooltip(services) }
              { tType === 'renewal' && renderRenewalTooltip(services) }
            </>
          ) : (
            Boolean(value) && (
              <div className={styles.customTooltip}>
                <Typography align="center" variant="h4" component="h4">{value}</Typography>
                <Typography align="center" variant="body2" color="textSecondary">{tooltipText}</Typography>
              </div>
            )
          )
        }
      </>
    );
  };

  const tickStyle = { fill: '#a8a6c1', fontSize: '0.8rem' };

  return (
    <div className={styles.barContainer}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 0,
            left: 0
          }}
        >
          <XAxis
            dataKey="name"
            orientation={xOrientation}
            axisLine={false}
            tick={tickStyle}
            tickLine={false}
            tickMargin={10}
          />
          <YAxis
            allowDecimals={false}
            axisLine={false}
            tick={tickStyle}
            tickLine={false}
            tickMargin={10}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey={barDataKey} barSize={barSize} fill={barColor} />
          <Line type="monotone" dataKey={lineDataKey} stroke={lineColor} />
          <CartesianGrid stroke="#eeeff7" vertical={false} />
          {
            referenceFrequency.map((referenceItem, index) => (
              <ReferenceLine
                key={referenceItem.name}
                x={referenceItem.name}
                stroke="var(--theme-secondary-main)"
              >
                <Label position="top" fontSize="11" fill="#6F6C99" >
                  {referenceItem.label}
                </Label>
              </ReferenceLine>
            ))
          }
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}



export default BarLineCombinedChart;
