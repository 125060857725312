import {
  SUPER_ADMIN,
  SUBSCRIBILI_USER,
  PARTNER_ADMIN,
  PARTNER_USER,
  GROUP_ADMIN,
  GROUP_USER,
  REGION_ADMIN,
  REGION_USER,
} from 'global-constants/roles';

export const PARTNER_FILTER_ROLES = [SUPER_ADMIN, SUBSCRIBILI_USER];

export const GROUP_FILTER_ROLES = [
  SUPER_ADMIN,
  SUBSCRIBILI_USER,
  PARTNER_ADMIN,
  PARTNER_USER,
];

export const REGION_FILTER_ROLES = [
  SUPER_ADMIN,
  SUBSCRIBILI_USER,
  PARTNER_ADMIN,
  PARTNER_USER,
  GROUP_ADMIN,
  GROUP_USER,
];

export const LOCATION_FACILITY_FILTER_ROLES = [
  SUPER_ADMIN,
  SUBSCRIBILI_USER,
  PARTNER_ADMIN,
  PARTNER_USER,
  GROUP_ADMIN,
  GROUP_USER,
  REGION_ADMIN,
  REGION_USER,
];
