import { Chip as MuiChip } from "@material-ui/core";
import clsx from 'clsx';
import React from "react";
import styles from "./Chip.module.scss";

const STATUS = {
  COMPLETE: "Complete",
  PAID: "Paid",
  WAIT: "Pending",
  SCHEDULED: "Scheduled",
  PENDING: "Pending",
  SUCCESS: "success",
  ERROR: "error",
  DEFAULT: "default",
  ONE_TIME: "Onetime",
  CANCELLED: "Cancelled",
  CANCEL: "Cancel",
  PAYMENT_PENDING: "payment_pending",
  ON_HOLD: "onhold",
  ACTIVE: "Active",
  OVERDUE: 'Overdue',
  EXPIRED: 'Expired',
  PAUSE: 'Pause',
  PAUSE_PENDING: 'Pause Pending',
  DISPUTE:'Dispute',
  OVERDUE_EXPIRED: 'Overdue Expired',
  FAIL: 'Fail',
  ALL: 'All',
  PLAN_CARD: 'Plan Card',
  FEES_SUMMARY: 'Fees Summary',
  SUBSCRIBED: 'subscribed',
  NOT_SUBSCRIBED: 'Not subscribed',
  PREPAID: 'Prepaid',
};

const Chip = (props) => {
  const { status } = props;

  const getColorClass = () => {
    switch (status) {
      case STATUS.COMPLETE:
      case STATUS.PAID:
      case STATUS.SUCCESS:
      case STATUS.ACTIVE:
      case STATUS.ALL:
      case STATUS.PLAN_CARD:
      case STATUS.FEES_SUMMARY:
      case STATUS.NOT_SUBSCRIBED:
      case STATUS.PREPAID:
        return styles.green;
      case STATUS.ERROR:
      case STATUS.ON_HOLD:
      case STATUS.CANCEL:
      case STATUS.CANCELLED:
      case STATUS.EXPIRED:
      case STATUS.OVERDUE:
      case STATUS.DISPUTE:
      case STATUS.OVERDUE_EXPIRED:
      case STATUS.FAIL:
      case STATUS.SUBSCRIBED:
        return styles.red;
      case STATUS.SCHEDULED:
      case STATUS.PENDING:
      case STATUS.WAIT:
      case STATUS.PAYMENT_PENDING:
      case STATUS.PAUSE:
      case STATUS.PAUSE_PENDING:
      case STATUS.ONE_TIME:
        return styles.blue; 
      case STATUS.DEFAULT:
      default:
        return styles.grey;
    }
  };

  return (
    <MuiChip
      {...props}
      classes={{ root: styles.chipContainer }}
      className={clsx(getColorClass(), props.className || '')}
    />
  );
};

export default Chip;
