const SUBSCRIBER_INFO_LOADING = 'SUBSCRIBER_INFO_LOADING';
const UPDATE_SUBSCRIBER_INFO = 'UPDATE_SUBSCRIBER_INFO';
const RESET_SUBSCRIBER_INFO = 'RESET_SUBSCRIBER_INFO';

const UPDATE_COMMUNICATION_DETAILS = 'UPDATE_COMMUNICATION_DETAILS';
const UPDATE_VALID_ADDRESS = 'UPDATE_VALID_ADDRESS';
const UPDATE_SUBSCRIBER_DETAILS = 'UPDATE_SUBSCRIBER_DETAILS';
const RESET_SUBSCRIBER_DETAILS = 'RESET_SUBSCRIBER_DETAILS';
const OPEN_EHR_LINK_POPUP = 'OPEN_EHR_LINK_POPUP';
const CLOSE_EHR_LINK_POPUP = 'CLOSE_EHR_LINK_POPUP';

const APPT_ACTIVE_STEP = 'APPT_ACTIVE_STEP';
const APPT_PATIENTS_LIST = 'APPT_PATIENTS_LIST';
const APPT_SELECT_PATIENT = 'APPT_SELECT_PATIENT';
const APPT_SERVICES_LOADING = 'APPT_SERVICES_LOADING';
const APPT_SERVICES_LIST = 'APPT_SERVICES_LIST';
const APPT_SELECT_SERVICE = 'APPT_SELECT_SERVICE';
const APPT_SELECT_PROVIDER = 'APPT_SELECT_PROVIDER';
const APPT_SLOTS_LOADING = 'APPT_SLOTS_LOADING';
const APPT_SLOTS_LIST = 'APPT_SLOTS_LIST';
const APPT_SELECT_SLOT = 'APPT_SELECT_SLOT';
const APPT_SELECT_INTERVAL = 'APPT_SELECT_INTERVAL';
const APPT_SELECT_DATE = 'APPT_SELECT_DATE';
const APPT_CHANGE_MONTH = 'APPT_CHANGE_MONTH';
const APPT_CREATE_SUCCESS = 'APPT_CREATE_SUCCESS';
const BOOK_ANOTHER_APPOINTMENT = 'BOOK_ANOTHER_APPOINTMENT';

/* Create Subscriber */
const UPDATE_PRIMARY_INFO = 'UPDATE_PRIMARY_INFO';
const ADD_ADDITIONAL_MEMBER = 'ADD_ADDITIONAL_MEMBER';
const UPDATE_ADDITIONAL_MEMBER = 'UPDATE_ADDITIONAL_MEMBER';
const RESET_ADDITIONAL_MEMBERS = 'RESET_ADDITIONAL_MEMBERS';

/* Coupon code */
const UPDATE_COUPON_CODE = 'UPDATE_COUPON_CODE';
const RESET_COUPON_CODE = 'RESET_COUPON_CODE';
const VALIDATING_COUPON_CODE = 'VALIDATING_COUPON_CODE';
const SET_COUPON_CODE_DETAIL = 'SET_COUPON_CODE_DETAIL';

/* Monetary - Lifetime & Savings */
const MONETARY_LOADING = 'MONETARY_LOADING';
const MONETARY_DATA_UPDATE = 'MONETARY_DATA_UPDATE';

/* Invoice */
const INVOICE_LOADING = 'INVOICE_LOADING';
const INVOICE_DATA_UPDATE = 'INVOICE_DATA_UPDATE';

/* Group settings */
const GROUP_SETTINGS_LOADING = 'GROUP_SETTINGS_LOADING';
const GROUP_SETTINGS_UPDATE = 'GROUP_SETTINGS_UPDATE';
const LOCATION_SETTINGS_LOADING = 'LOCATION_SETTINGS_LOADING';
const LOCATION_SETTINGS_UPDATE = 'LOCATION_SETTINGS_UPDATE';

/* Subscriber import */
const UPDATE_SUBSCRIBER_IMPORT_DATA = 'UPDATE_SUBSCRIBER_IMPORT_DATA';
const RESET_SUBSCRIBER_IMPORT_DATA = 'RESET_SUBSCRIBER_IMPORT_DATA';

export {
  SUBSCRIBER_INFO_LOADING,
  UPDATE_SUBSCRIBER_INFO,
  RESET_SUBSCRIBER_INFO,
  UPDATE_COMMUNICATION_DETAILS,
  UPDATE_SUBSCRIBER_DETAILS,
  RESET_SUBSCRIBER_DETAILS,
  OPEN_EHR_LINK_POPUP,
  CLOSE_EHR_LINK_POPUP,
  APPT_ACTIVE_STEP,
  APPT_PATIENTS_LIST,
  APPT_SELECT_PATIENT,
  APPT_SERVICES_LOADING,
  APPT_SERVICES_LIST,
  APPT_SELECT_SERVICE,
  APPT_SELECT_PROVIDER,
  APPT_SLOTS_LOADING,
  APPT_SLOTS_LIST,
  APPT_SELECT_SLOT,
  APPT_SELECT_INTERVAL,
  APPT_SELECT_DATE,
  APPT_CHANGE_MONTH,
  APPT_CREATE_SUCCESS,
  BOOK_ANOTHER_APPOINTMENT,
  UPDATE_PRIMARY_INFO,
  ADD_ADDITIONAL_MEMBER,
  UPDATE_ADDITIONAL_MEMBER,
  RESET_ADDITIONAL_MEMBERS,
  UPDATE_COUPON_CODE,
  RESET_COUPON_CODE,
  VALIDATING_COUPON_CODE,
  SET_COUPON_CODE_DETAIL,
  MONETARY_LOADING,
  MONETARY_DATA_UPDATE,
  INVOICE_LOADING,
  INVOICE_DATA_UPDATE,
  GROUP_SETTINGS_LOADING,
  GROUP_SETTINGS_UPDATE,
  LOCATION_SETTINGS_LOADING,
  LOCATION_SETTINGS_UPDATE,
  UPDATE_SUBSCRIBER_IMPORT_DATA,
  RESET_SUBSCRIBER_IMPORT_DATA,
  UPDATE_VALID_ADDRESS,
};
