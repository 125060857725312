import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { TableListView, ContactDetailsModal } from "components";
import { formatPhoneNumber } from "Shared/Utils";

const ContactDetails = (props) => {
  const {
    name = '',
    contactMembers,
    level_data = {},
    settings = {},
    onUpdate,
    subHeading
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [memberId, setMemberId] = useState("");

  const contactMembersData = contactMembers?.map((members) => ({
    ...members,
    is_internal: members?.is_internal === false ? "Stripe Contact" : "-",
  }));

  const tableColumns = [
    {
      name: "first_name",
      label: "First Name",
    },
    {
      name: "last_name",
      label: "Last Name",
    },
    {
      name: "title",
      label: "Contact Title",
    },
    {
      name: "contact_email",
      label: "Email",
    },
    {
      name: "phone",
      label: "Phone Number",
      options: {
        customBodyRender: (value) => formatPhoneNumber(value),
      },
    },
    {
      name: "is_internal",
      label: "Permissions",
    },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRender: (_value, { rowIndex }) => (
          <>
            <Typography
              component="span"
              className="theme_color_orange cursor_pointer"
              onClick={() => handleActionClick("edit", rowIndex)}
              aria-label="edit"
              data-testid={`edit-contact-details-button-${rowIndex}`}
            >
              Edit
            </Typography>

            <Typography component="span" className="separator">
              |
            </Typography>

            <Typography
              component="span"
              className="theme_color_orange cursor_pointer"
              onClick={() => handleActionClick("delete", rowIndex)}
              aria-label="delete"
              data-testid={`delete-contact-details-button-${rowIndex}`}
            >
              Remove
            </Typography>
          </>
        ),
      },
    },
  ];

  const handleActionClick = (type, id = "", value = {}) => {
    setIsOpen(type);
    setMemberId(id);
  };

  const handleCloseAction = () => {
    setIsOpen(false);
    setMemberId("");
  };

  const btnProps = {
    otherButtons: (
      <Button
        onClick={() => handleActionClick("add")}
        color="secondary"
        size="large"
        data-testid="conatct-header-add-member"
      >
        Add Member
      </Button>
    ),
  };

  return (
    <>
      <TableListView
        headerTitle={`${name} Contact Details`}
        headerSubtitle={subHeading}
        tableData={contactMembersData}
        tableOptions={{}}
        tableColumns={tableColumns}
        pagination={false}
        headerSection={true}
        emptyPlaceHolderContent="No contact details found"
        {...btnProps}
      />

      {Boolean(isOpen) && (
        <ContactDetailsModal
          type={isOpen}
          closePopup={handleCloseAction}
          data={contactMembers}
          levelData={level_data}
          settingsData={settings}
          memberId={memberId}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

ContactDetails.propTypes = {
  contactMembers: PropTypes.object,
  subHeading: PropTypes.string,
};
export default ContactDetails;
