import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const ToggleSwitch = withStyles((theme) => ({
  switchBase: {
    color: 'var(--theme-highlight-color)',

    "&$checked": {
      transform: "translateX(26px)",
      color: '#fff',

      "& + $track": {
        backgroundColor: 'var(--theme-highlight-color)',
        opacity: 1,
        border: "none",
      },
    },

    "&$focusVisible $thumb": {
      color: "var(--theme-highlight-color)",
      border: "6px solid #fff",
    },
  },
  track: {
    border: `1px solid var(--theme-highlight-color)`,
    backgroundColor: 'var(--theme-cotrast-color)',
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default ToggleSwitch