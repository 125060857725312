import httpService from "../Api/http.service";

const { REACT_APP_API_BASE_URL } = process.env;
const getCareBaseUrl = () => `${REACT_APP_API_BASE_URL}/care`;

const getCategoryList = (params) =>
  httpService({
    url: getCareBaseUrl() + `/categories${params}`,
    method: "get",
  });

const createPackageData = (payload) =>
  httpService({
    url: getCareBaseUrl() + "/offerings",
    method: "post",
    data: payload,
  });

const updatePackageData = (payload, offering_id) =>
  httpService({
    url: getCareBaseUrl() + `/offerings/${offering_id}`,
    method: "put",
    data: payload,
  });

const getPackageList = (params) =>
  httpService({
    url: getCareBaseUrl() + `/offerings${params}`,
    method: "get",
  });

const getPackageInfo = (offering_id) =>
  httpService({
    url: getCareBaseUrl() + `/offerings/${offering_id}`,
    method: "get",
  });

const postCheckoutData = (payload) =>
  httpService({
    url: getCareBaseUrl() + '/orders',
    method: "post",
    data: payload,
  });

const updateOrderData = (order_id, payload) =>
  httpService({
    url: getCareBaseUrl() + `/orders/${order_id}`,
    method: "put",
    data: payload,
  });

const getCustomersByQueryParams = (params) =>
  httpService({
    url: getCareBaseUrl() + `/patients?${params || ""}`,
    method: "get",
  });

const getCustomerDetails = (patient_id) =>
  httpService({
    url: getCareBaseUrl() + `/patients/${patient_id}/orders`,
    method: "get"
  });

const updateCustomerDetails = (patient_id, payload) =>
  httpService({
    url: getCareBaseUrl() + `/patients/${patient_id}`,
    method: "put",
    data: payload,
  });

const cancelRefundOrder = (order_id, payload) =>
  httpService({
    url: getCareBaseUrl() + `/orders/${order_id}/cancel`,
    method: "put",
    data: payload,
  });

const careService = {
  getCategoryList,
  createPackageData,
  updatePackageData,
  getPackageList,
  getPackageInfo,
  postCheckoutData,
  updateOrderData,
  getCustomersByQueryParams,
  getCustomerDetails,
  updateCustomerDetails,
  cancelRefundOrder
};

export default careService;
