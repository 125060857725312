import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import wpServices from 'Services/wpService';
import CommonLoader from 'Views/Common/CommonLoader';
import { CommonDialog } from "components";

const PaperlessModal = (props) => {
  const {
    isOpen,
    closePopup
  } = props;

  const [noticeContent, setContent] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getPaperlessNotice = async () => {
      const response = await wpServices.getPaperlessNotice();

      setContent(response.data);
      setLoading(false);
    };

    getPaperlessNotice();
  }, []);

  return (
    <CommonDialog
      open={isOpen}
      handleClose={closePopup}
      dialogTitle="Notice of Electronic Communication"
      secondaryBtnTxt="Close"
      maxWidth="md"
    >
      {
        isLoading ?
          <CommonLoader />
          :
          parse(noticeContent?.content?.rendered || '')
      }
    </CommonDialog>
  )
};

export default PaperlessModal;