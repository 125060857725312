import React, { useState } from "react";
import clsx from "clsx";
import { Divider, Grid, Paper, Tab, Tabs, Typography, Box } from "@material-ui/core";
import SectionHeader from "components/SectionHeader/SectionHeader";
import CheckBox from "components/CheckBox";
import styles from "./PlanCard.module.scss";

const PlanCard = (props) => {
  const { singlePlan, checkbox = false } = props;
  const {
    plan_name = "",
    pricing_tiers_list = [],
    cpt_codes = [],
  } = singlePlan;
  
  const [planType, setPlanType] = useState(0);
  const isTiered = pricing_tiers_list[0].plan_type === "Tiered" || false;
  const billingFrequencies = [];
  pricing_tiers_list?.forEach(sPlan => {
    const billing_frequency = sPlan.pricing_tiers.billing_frequency;

    if(!billingFrequencies.includes(billing_frequency))
        billingFrequencies.push(billing_frequency);
  });
  const billingFreqTiers = pricing_tiers_list?.filter(indTier => indTier?.pricing_tiers?.billing_frequency === billingFrequencies[planType]) || [];

  const handleChange = (_event, newValue) => setPlanType(newValue);

  const getTiers = () => {

    if(billingFreqTiers?.length === 1) {
      return billingFreqTiers?.[0]?.pricing_tiers?.tiers;
    }
    else {
      const ordering = {};
      const sortOrder = ['General', 'Primary', 'Dependent'];

      for (let i=0; i < sortOrder.length; i++)
        ordering[sortOrder[i]] = i;

      billingFreqTiers?.sort((a, b) => {
        return (ordering[a.plan_type] - ordering[b.plan_type]);
      });

      let updatedTiers = [];
      billingFreqTiers?.forEach(indTier => {
        updatedTiers = [...updatedTiers, ...(indTier?.pricing_tiers?.tiers || [])];
      });

      return updatedTiers;
    }
  };

  const gTiers = getTiers() || [];

  const getIndividualOfferAmount = () => {
    const [ tierOne = {}, tierTwo = {} ] = gTiers;
    const { unit_amount: primaryUnitAmount = 0 } = tierOne;
    const { unit_amount: dependentUnitAmount = 0 } = tierTwo;

    return {
      primary: primaryUnitAmount,
      dependent: dependentUnitAmount
    };
  };

  const getUpfrontAmount = () => {
    const [ tierOne = {}, tierTwo = {} ] = gTiers;
    const { upfront_amount: primaryUpfrontAmt = 0 } = tierOne;
    const { upfront_amount: dependentUpfrontAmt = 0 } = tierTwo;

    return {
      primary: primaryUpfrontAmt,
      dependent: dependentUpfrontAmt
    };
  };

  const getSingleTieredDependents = (searchKey) => {
    if (!isTiered) return;

    const singleDependents = pricing_tiers_list.find(
      (elem) =>
        elem?.member_from === searchKey && elem?.member_to === searchKey,
    );
    return singleDependents;
  };

  const getAllMemberTiers = () => {
    if (!isTiered) return;
    const filterElem = [1, 2];
    const allMemberTiers = billingFreqTiers?.filter(
      (elem) => !filterElem.includes(elem?.member_to),
    );
    return allMemberTiers;
  };

  const getActivationAmount = () => {
    const [ tierOne = {}, tierTwo = {} ] = gTiers;
    const { signup_amount: primaryAmt = 0 } = tierOne;
    const { signup_amount: dependentAmt = 0 } = tierTwo;

    return {
      primary: primaryAmt,
      dependent: dependentAmt
    };
  };

  const renderSingleBenifit = (singleBenifit) => (
    <Grid key={singleBenifit.code} container spacing={1}>
      <Grid item xs={1}>
        <img src="/images/feature_icon.svg" alt="" />
      </Grid>
      <Grid item xs={11}>
        <Typography className="mg_left_8" variant="body2">
          {singleBenifit.code} - {singleBenifit.description} x{" "}
          {singleBenifit.total_unit}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderCodes = (title, codes) => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      <Grid item xs={12}>
        {
          codes?.map((singleBenifit) =>
            renderSingleBenifit(singleBenifit)
          )
        }
      </Grid>
    </Grid>
  );

  const getFeatures = () => {
    const uniqueLists = cpt_codes ? [...new Set(cpt_codes.map(code => code.list))] : [];

    if (uniqueLists.length === cpt_codes.length && cpt_codes.length) {
      const frequency = cpt_codes[0].frequency === 'monthly' ? 'Monthly' : 'Annually';

      return renderCodes(`Included ${frequency}`, cpt_codes);
    }
    else if (uniqueLists.length === 1) {
      const frequency = cpt_codes[0].frequency === 'monthly' ? 'Monthly' : 'Annual';
      const count = cpt_codes[0].quantity;

      return renderCodes(`${count} ${frequency} Choice of`, cpt_codes);
    }
    else {
      let individualTitle;
      const listCounts = uniqueLists.map(list => {
        const filteredList = cpt_codes.filter(code => code.list === list);
        const frequency = filteredList[0].frequency === 'monthly' ? 'Monthly' : 'Annually';

        return {
          filteredList,
          count: filteredList.length,
          title: filteredList.length === 1 ? `Included ${frequency}` : `${filteredList.length} Additional Service choices`
        };
      }).sort((list1, list2) => list1.count > list2.count ? 1 : -1);

      const singleList = listCounts
        .filter(list => list.count === 1)
        .reduce((consolidatedList, list) => {
          individualTitle = list.title;
          return consolidatedList.concat(list.filteredList)
        }, []);

      const finalList = [{
        filteredList: singleList,
        title: individualTitle
      }, ...listCounts.filter(list => list.count > 1)];

      return finalList.map(list => renderCodes(list.title, list.filteredList));
    }
  };

  const unitAmount = getIndividualOfferAmount();
  const upfrontAmount = getUpfrontAmount();
  const dependentTier = getSingleTieredDependents(2) || {};
  const tieredElements = getAllMemberTiers() || [];

  const activationAmount = getActivationAmount();

  const getTitle = () => {
    if(checkbox)
      return (
        <>
          <CheckBox
            name="selectPlan"
            inputProps={{ "data-testid": `select-plan-checkbox` }}
          />
          {plan_name}
        </>
      );

    return plan_name;
  };

  return (
    <Paper className={clsx('widget-wrapper')} elevation={1}>
      <SectionHeader
        title={getTitle()}
        titleVariant="h5"
        titleComponent="h5"
        gutterBottom
      />

      <Tabs
        value={planType}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        className="mg_bottom_24"
      >
        {billingFrequencies.map((frequency) => (
          <Tab key={frequency} label={frequency} className="compact" />
        ))}
      </Tabs>
      <Grid
        container
        spacing={3}
        alignItems="stretch"
        className="position-relative"
      >
        <Grid item xs={12} sm={6}>
          <div className="displayFlex alignItemsBaseline">
            <Typography className="bold" variant="h4">
              ${unitAmount?.primary / 100}
            </Typography>
          </div>
          <Typography variant="body1">Primary Subscriber</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          {(isTiered
            ? Object.keys(dependentTier)
            : Boolean(unitAmount?.dependent)) && (
            <>
              <div className="displayFlex alignItemsBaseline">
                <Typography className="bold" variant="h4">
                  ${unitAmount?.dependent / 100}
                </Typography>
              </div>
              <Typography variant="body1">Per Dependent</Typography>
            </>
          )}
        </Grid>
        {Boolean(isTiered && tieredElements.length) && (
          <Grid item xs={12} sm={12}>
            <Typography
              variant="body2"
              className={clsx(styles.pricingContainerHeading, 'bolder')}
            >
              OTHERS PRICING
            </Typography>
            <Box className="padd-y-14">
              {tieredElements.map((elem, index) => {
                return (
                  <Box
                    key={`pricing-tier-row-${index + 1}`}
                    className="d-flex justify-content-between padd-top-5 padd-btm-5"
                  >
                    <Typography variant="body1">{elem?.price_name}</Typography>
                    <Typography variant="body1">
                      ${elem?.pricing_tiers?.tiers[0]?.unit_amount / 100}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Divider />
          </Grid>
        )}

        <Grid item xs={12}>
          {getFeatures()}
        </Grid>

        {Boolean(activationAmount?.primary || activationAmount?.dependent) && (
          <>
            <Grid item xs={12} className={styles.upfrontCopy}>
              <Typography variant="body1">
                *A one-time activation cost will be charged at signup
              </Typography>
            </Grid>
            <Grid item xs={12} className={styles.upfrontWrapper}>
              {Boolean(activationAmount?.primary) && (
                <Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    color="secondary"
                    className="bold"
                  >
                    ${activationAmount?.primary / 100}
                  </Typography>
                  /Primary
                </Typography>
              )}
              {Boolean(activationAmount?.dependent) && (
                <Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    color="secondary"
                    className="bold"
                  >
                    ${activationAmount?.dependent / 100}
                  </Typography>
                  /Dependent
                </Typography>
              )}
            </Grid>
          </>
        )}

        {Boolean(upfrontAmount?.primary || upfrontAmount?.dependent) && (
          <>
            <Grid item xs={12} className={styles.upfrontCopy}>
              <Typography variant="body1">
                *Upfront cost will be charged on the 1st month's bill
              </Typography>
            </Grid>
            <Grid item xs={12} className={styles.upfrontWrapper}>
              {Boolean(upfrontAmount?.primary) && (
                <Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    color="secondary"
                    className="bold"
                  >
                    ${upfrontAmount?.primary / 100}
                  </Typography>
                  /Primary
                </Typography>
              )}
              {Boolean(upfrontAmount?.dependent) && (
                <Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    color="secondary"
                    className="bold"
                  >
                    ${upfrontAmount?.dependent / 100}
                  </Typography>
                  /Dependent
                </Typography>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}

export default PlanCard;
