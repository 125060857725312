import httpService from "../Api/http.service";

const createAgreement = (payload) =>
  httpService({
    url: `/provider-agreement`,
    method: "post",
    data: payload,
  });

const getAgreementLink = (payload) =>
  httpService({
    url: `/provider-agreement/link`,
    method: "put",
    data: payload,
  });

const getAgreementStatus = (level, levelId) =>
  httpService({
    url: `/provider-agreement/status?level=${level}&level_id=${levelId}`,
    method: "get",
  });

const getDocumentContent = (level, levelId) =>
  httpService({
    url: `/provider-agreement/download?level=${level}&level_id=${levelId}`,
    method: "get",
  }); 

const userService = {
  createAgreement,
  getAgreementLink,
  getAgreementStatus,
  getDocumentContent
};

export default userService;
