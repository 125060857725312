import {
  AUTHENTICATION_COMPLETE,
  LOGOUT,
  RESET_SITE_DATA,
  FRESHCHAT_INIT,
  MENU_CONFIG_UPDATE,
  DASHBOARD_VIEWS_UPDATED,
  DASHBOARD_FILTER_UPDATED,
  GROUPS_DATA_RECEIVED,
  SUBSCRIBERS_VIEW_UPDATED,
  SUBSCRIBERS_COMPLETE_UPDATED,
  SUBSCRIBERS_FILTERS_UPDATED,
  SUBSCRIBERS_FILTERS_RESET,
  LEVEL_LIST_DATA_UPDATED,
  LEVEL_LIST_DATA_LOADING,
  PATIENTS_FILTERS_UPDATED,
  PATIENTS_FILTERS_RESET,
  TRENDS_FILTERS_UPDATED,
  TRENDS_FILTERS_RESET,
  TRENDS_FILTERS_STATUS_UPDATED,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  THEME_UPDATED,
  DOMAIN_CONFIG_UPDATED,
  UPDATE_PAYMENT_ID,
  UPDATE_PAYMENT_TYPES,
  UPDATE_PAYMENT_DETAILS,
  RESET_PAYMENT_DETAILS,
  UPDATE_COUPON_CODE,
  RESET_COUPON_CODE,
  VALIDATING_COUPON_CODE,
  SET_COUPON_CODE_DETAIL,
  SET_COUPON_CODE_ERROR,
  CONDITIONS_APPLY_UPDATE,
  CONDITIONS_APPLY_RESET,
  UPDATE_STRIPE_ONB_STATUS,
  UPDATE_ONBOARD_LOCATION,
  REFRESH_ONBOARD_LOCATION,
  REFRESH_USER_DETAILS,
  ADD_ONBOARD_GROUP_PLANS,
  ADD_ONBOARD_LOCS_PLANS,
  ADD_ONBOARD_LOCS_PLAN_DISCOUNTS,
  RESET_ONBOARD_LOCS_PLANS,
  REFRESH_ONBOARD_LOCS_PLANS,
  ADD_ONBOARD_DEFAULT_PLAN_DISCOUNTS,
  SAVINGS_SUMMARY_LOADING,
  REFRESH_SAVINGS_SUMMARY,
  SAVINGS_SUMMARY_UPDATED,
  UPDATE_ONBOARD_AGREEMENT_VALUES,
  REFRESH_ONBOARD_AGREEMENT_STATUS,
  RESET_ONBOARD_VALUES,
  OPEN_EHR_LINK_POPUP,
  CLOSE_EHR_LINK_POPUP,
} from './actions';

const initDasboardFilters = {
  activeMemberFrequency: 'monthly',
  locationFrequency: 'monthly',
  partnerId: '',
  groupId: '',
  regionId: '',
  dashboardData: {},
  create_start: null,
  create_end: null,
  selectedLocations: [],
};

const initFilterData = {
  partners: [],
  groups: [],
  regions: [],
  locations: [],
  plans: [],
};

const initSubscriberFilters = {
  filterState: {
    text: '',
    partner_id: '',
    group_id: '',
    region_id: '',
    location_id: '',
    facility_id: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    patient_id: '',
    date_of_birth: '',
    subscription_id: '',
    created_start: null,
    created_end: null,
    statStatus: '',
    has_payment_method: '',
    overdue_days: [],
  },
  pageProps: {
    page_number: 0,
    rowsPerPage: 100,
  },
  allStatsResponse: '',
  isFilterActive: false,
  isFilterUpdated: false,
};

const initPatientFilters = {
  filterState: {
    flexpay: 'all',
    partner_id: '',
    group_id: '',
    location_id: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    patient_id: '',
    date_of_birth: '',
    created_start: null,
    created_end: null,
    year: null,
  },
  pageProps: {
    page_number: 0,
    rowsPerPage: 100,
  },
  isFilterActive: false,
  isFilterUpdated: false,
};

const initTrendFilters = {
  values: {
    year: new Date(),
    partner_id: '',
    group_id: '',
    region_id: '',
    location_id: '',
    plan_package_id: '',
    billing_frequency: '',
  },
  isFilterUpdated: false,
};

const initPaymentTypes = {
  loading: true,
  list: [],
};

const initPaymentDetails = {
  values: {
    same_address: true,
    payment_type: 'Card',
    isValid: false,
    error: '',
    full_name: '',
    phone: '',
    email: '',
    line1: '',
    city: '',
    state: '',
    zipcode: '',
  },
  errors: {
    full_name: false,
    phone: false,
    line1: false,
    city: false,
    state: false,
    zipcode: false,
  },
  blurred: {
    full_name: false,
    phone: false,
    line1: false,
    city: false,
    state: false,
    zipcode: false,
  },
};

const initPromoCode = {
  loading: false,
  couponCode: '',
  couponError: '',
  couponDetails: [],
};

const initConditionsApply = {
  termsConditions: false,
  cancellationPolicy: false,
};

const initStripeOnboard = {
  refresh: false,
  init: false,
  loading: false,
  stats: {},
  partners: [],
  groups: [],
  regions: [],
  locations: [],
};

const initLevelOnboard = {
  location: {},
  groupPlans: [],
  locationsPlans: [],
  locationsPlanDiscounts: [],
  defaultPlanDiscounts: [],
  agreement: {
    loading: true,
    status: '',
    url: '',
  },
  refreshLocationDetails: false,
  refreshLocationPlans: false,
  refreshUserDetails: false,
  refreshAgreementStatus: false,
};

const initLinkPatientData = {
  isOpen: false,
  patient_id: '',
  pms_id: '',
};

const initMenuConfig = {
  init: false,
  menu_response: {},
  multi_product: false,
  selected_product: {},
  product_menu: [],
  sub_menu: [],
  available_routes: [],
  trainingLink: '',
};

const initSavingData = {
  headers: [],
  stickyHeaders: [],
  list: [],
  categoryHeaders: [],
  loading: false,
  refresh: false,
};

const initialState = {
  user: null,
  isUserValidating: true,
  freshchat_initialised: false,
  logoutUser: false,
  logoutLoading: false,
  menu_config: { ...initMenuConfig },
  dashboardColumns: {},
  dashboardFilters: {
    ...initDasboardFilters,
  },
  groupsData: [],
  filterDataLoading: {
    partners: false,
    groups: false,
    regions: false,
    locations: false,
    plans: false,
  },
  filterData: {
    ...initFilterData,
  },
  selectedColumns: [],
  visibleColumns: {},
  completedSubscribers: [],
  subscriberFilters: {
    ...initSubscriberFilters,
  },
  patientFilters: {
    ...initPatientFilters,
  },
  trendFilters: {
    ...initTrendFilters,
  },
  notifications: [],
  activeTheme: 'default',
  domainConfig: {},
  paymentTypes: {
    ...initPaymentTypes,
  },
  paymentDetails: {
    ...initPaymentDetails,
  },
  paymentMethodIdInfo: {
    paymentMethodId: ''
  },
  promoCode: {
    ...initPromoCode,
  },
  conditionsApply: {
    ...initConditionsApply,
  },
  stripeOnboard: {
    ...initStripeOnboard,
  },
  levelOnboard: {
    ...initLevelOnboard,
  },
  linkPatientModal: {
    ...initLinkPatientData,
  },
  feesSummary: {
    ...initSavingData,
  },
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case AUTHENTICATION_COMPLETE: {
      return {
        ...state,
        user: payload.user,
        isUserValidating: false,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        logoutUser: payload?.logout ?? true,
        logoutLoading: payload?.loading ?? true,
      };
    }
    case RESET_SITE_DATA: {
      return {
        ...state,
        user: null,
        logoutUser: false,
        logoutLoading: false,
        freshchat_initialised: false,
        menu_config: { ...initMenuConfig },
        dashboardFilters: { ...initDasboardFilters },
        subscriberFilters: { ...initSubscriberFilters },
        trendFilters: { ...initTrendFilters },
        filterData: { ...initFilterData },
        paymentDetails: { ...initPaymentDetails },
      };
    }
    case FRESHCHAT_INIT: {
      return {
        ...state,
        freshchat_initialised: payload?.init || true,
      };
    }
    case MENU_CONFIG_UPDATE: {
      return {
        ...state,
        menu_config: {
          ...state.menu_config,
          ...payload,
        },
      };
    }
    case DASHBOARD_VIEWS_UPDATED: {
      return {
        ...state,
        dashboardColumns: { ...payload.columns },
      };
    }
    case DASHBOARD_FILTER_UPDATED: {
      return {
        ...state,
        dashboardFilters: { ...payload.dashboardFilters },
      };
    }
    case GROUPS_DATA_RECEIVED: {
      return {
        ...state,
        groupsData: payload.groupsData,
      };
    }
    case SUBSCRIBERS_VIEW_UPDATED: {
      return {
        ...state,
        ...payload,
      };
    }
    case SUBSCRIBERS_COMPLETE_UPDATED: {
      return {
        ...state,
        completedSubscribers: payload.complete,
      };
    }
    case SUBSCRIBERS_FILTERS_UPDATED: {
      return {
        ...state,
        subscriberFilters: {
          ...state.subscriberFilters,
          ...payload.subscriberFilters,
        },
      };
    }
    case SUBSCRIBERS_FILTERS_RESET: {
      return {
        ...state,
        subscriberFilters: {
          ...initialState.subscriberFilters,
          filterState: {
            ...initialState.subscriberFilters.filterState,
            ...(payload?.filterState || {}),
          },
          pageProps: {
            ...initialState.subscriberFilters.pageProps,
            ...(payload?.pageProps || {}),
          },
          isFilterUpdated: true,
        },
      };
    }
    case LEVEL_LIST_DATA_LOADING: {
      return {
        ...state,
        filterDataLoading: {
          ...state.filterDataLoading,
          [payload.key]: payload.value,
        },
      };
    }
    case LEVEL_LIST_DATA_UPDATED: {
      return {
        ...state,
        filterData: payload,
      };
    }
    case PATIENTS_FILTERS_UPDATED: {
      return {
        ...state,
        patientFilters: {
          ...state.patientFilters,
          ...payload.filters,
        },
      };
    }
    case PATIENTS_FILTERS_RESET: {
      return {
        ...state,
        patientFilters: {
          ...initialState.patientFilters,
          filterState: {
            ...initialState.patientFilters.filterState,
            ...(payload?.filterState || {}),
          },
          pageProps: {
            ...initialState.patientFilters.pageProps,
            ...(payload?.pageProps || {}),
          },
          isFilterUpdated: true,
        },
      };
    }
    case TRENDS_FILTERS_UPDATED: {
      return {
        ...state,
        trendFilters: {
          ...state.trendFilters,
          values: payload,
          isFilterUpdated: true,
        },
      };
    }
    case TRENDS_FILTERS_RESET: {
      return {
        ...state,
        trendFilters: {
          ...initTrendFilters,
        },
      };
    }
    case TRENDS_FILTERS_STATUS_UPDATED: {
      return {
        ...state,
        trendFilters: {
          ...state.trendFilters,
          isFilterUpdated: payload.updated,
        },
      };
    }
    case ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications, payload.notification],
      };
    }
    case DELETE_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.slice(1),
      };
    }
    case THEME_UPDATED: {
      return {
        ...state,
        activeTheme: payload.activeTheme,
      };
    }
    case DOMAIN_CONFIG_UPDATED: {
      return {
        ...state,
        domainConfig: payload.domainConfig,
        activeTheme: payload.activeTheme,
      };
    }

    /* Get payment types, label and options */
    case UPDATE_PAYMENT_TYPES: {
      return {
        ...state,
        paymentTypes: {
          ...payload,
        },
      };
    }

    case UPDATE_PAYMENT_ID: {
      return {
        ...state,
        paymentMethodIdInfo: {
          paymentMethodId: payload.paymentMethodId,
        },
      };
    }

    /* Payment & billing details */
    case UPDATE_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          ...payload,
        },
      };
    case RESET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: {
          ...initPaymentDetails,
        },
      };

    /* Coupon Code */
    case UPDATE_COUPON_CODE:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          couponCode: payload.value,
          couponError: '',
        },
      };
    case RESET_COUPON_CODE:
      return {
        ...state,
        promoCode: {
          ...initPromoCode,
          ...(payload || {}),
        },
      };
    case VALIDATING_COUPON_CODE:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          loading: payload.loading,
        },
      };
    case SET_COUPON_CODE_DETAIL:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          couponDetails: [
            ...(state?.promoCode?.couponDetails || {}),
            payload.detail,
          ],
          loading: false,
          couponCode: '',
          couponError: '',
        },
      };
    case SET_COUPON_CODE_ERROR:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          loading: false,
          couponError: payload.error,
        },
      };

    /* Cancellation Policy and Terms & Conditions */
    case CONDITIONS_APPLY_UPDATE: {
      return {
        ...state,
        conditionsApply: {
          ...state.conditionsApply,
          [payload.name]: payload.value,
        },
      };
    }
    case CONDITIONS_APPLY_RESET: {
      return {
        ...state,
        conditionsApply: {
          ...initConditionsApply,
        },
      };
    }

    /* Stripe - Onbaording */
    case UPDATE_STRIPE_ONB_STATUS: {
      return {
        ...state,
        stripeOnboard: {
          ...state.stripeOnboard,
          ...payload,
        },
      };
    }

    /* Vyne - Location onboarding */
    case UPDATE_ONBOARD_LOCATION: {
      return {
        ...state,
        levelOnboard: {
          ...state.levelOnboard,
          location: payload.data,
        },
      };
    }

    case REFRESH_ONBOARD_LOCATION: {
      return {
        ...state,
        levelOnboard: {
          ...state.levelOnboard,
          refreshLocationDetails: payload.refresh,
        },
      };
    }

    case REFRESH_USER_DETAILS: {
      return {
        ...state,
        levelOnboard: {
          ...state.levelOnboard,
          refreshUserDetails: payload.refresh,
        },
      };
    }

    case ADD_ONBOARD_GROUP_PLANS: {
      return {
        ...state,
        levelOnboard: {
          ...state.levelOnboard,
          groupPlans: payload.planPackages,
        },
      };
    }

    case ADD_ONBOARD_LOCS_PLANS: {
      return {
        ...state,
        levelOnboard: {
          ...state.levelOnboard,
          locationsPlans: payload.plans,
        },
      };
    }

    case ADD_ONBOARD_LOCS_PLAN_DISCOUNTS: {
      return {
        ...state,
        levelOnboard: {
          ...state.levelOnboard,
          locationsPlanDiscounts: payload.discounts,
        },
      };
    }

    case RESET_ONBOARD_LOCS_PLANS: {
      return {
        ...state,
        levelOnboard: {
          ...state.levelOnboard,
          locationsPlans: [],
          locationsPlanDiscounts: [],
        },
      };
    }

    case REFRESH_ONBOARD_LOCS_PLANS: {
      return {
        ...state,
        levelOnboard: {
          ...state.levelOnboard,
          refreshLocationPlans: payload.refresh,
        },
      };
    }

    case ADD_ONBOARD_DEFAULT_PLAN_DISCOUNTS: {
      return {
        ...state,
        levelOnboard: {
          ...state.levelOnboard,
          defaultPlanDiscounts: payload.discounts,
        },
      };
    }

    /* Savings Summary */
    case SAVINGS_SUMMARY_LOADING: {
      return {
        ...state,
        feesSummary: {
          ...state.feesSummary,
          loading: payload.loading,
          refresh:
            payload.refresh !== undefined
              ? payload.refresh
              : state.feesSummary.refresh,
        },
      };
    }

    case REFRESH_SAVINGS_SUMMARY: {
      return {
        ...state,
        feesSummary: {
          ...initSavingData,
          refresh: payload.refresh,
        },
      };
    }

    case SAVINGS_SUMMARY_UPDATED: {
      return {
        ...state,
        feesSummary: {
          ...state.feesSummary,
          headers: payload.headers,
          stickyHeaders: payload.stickyHeaders,
          list: payload.list,
          categoryHeaders: payload.categoryHeaders,
          loading: payload.loading,
          refresh: payload.refresh,
        },
      };
    }

    case UPDATE_ONBOARD_AGREEMENT_VALUES: {
      return {
        ...state,
        levelOnboard: {
          ...state.levelOnboard,
          agreement: {
            ...state.levelOnboard.agreement,
            ...payload,
          },
        },
      };
    }

    case REFRESH_ONBOARD_AGREEMENT_STATUS: {
      return {
        ...state,
        levelOnboard: {
          ...state.levelOnboard,
          refreshAgreementStatus: payload.refresh,
        },
      };
    }

    case RESET_ONBOARD_VALUES: {
      return {
        ...state,
        levelOnboard: {
          ...initLevelOnboard,
        },
        feesSummary: {
          ...initSavingData,
        },
      };
    }

    /* Link patient to EHR */
    case OPEN_EHR_LINK_POPUP:
      return {
        ...state,
        linkPatientModal: payload.data,
      };

    case CLOSE_EHR_LINK_POPUP:
      return {
        ...state,
        linkPatientModal: { ...initLinkPatientData },
      };

    default:
      throw new Error();
  }
};

export { reducer, initialState };
