import React, { useState, useEffect, useContext } from 'react';
import { FormControl, Grid, InputLabel, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

// Components
import { CheckBox, CommonDialog, Select } from 'components';

// Context
import { Context } from 'context';
import { ADD_NOTIFICATION } from 'context/actions';

// Services
import { LOCATION_SERVICES } from 'Services';

const AddPlansPopup = (props) => {
  const {
    data: { location_id = '' } = {},
    onClose,
    onUpdate,
    groupPlans = [],
    locationPlans = [],
  } = props;

  const { dispatch: globalDispatch } = useContext(Context);

  const [filteredPlans, setFilteredPlans] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const setGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: {
        notification: {
          severity,
          message,
        },
      },
    });

  const handleConfirmClick = async () => {
    if (!location_id) return;

    setIsLoading(true);

    try {
      const payload = {
        plan_package_ids: selectedPlans,
      };
      const response = await LOCATION_SERVICES.addLocationPlans(
        location_id,
        payload,
      );

      if (response?.type === 'success' && response?.data) {
        setGlobalNotification(
          'success',
          'The plan(s) has been successfully added to this location.',
        );

        onClose?.();
        onUpdate?.();
      } else {
        throw response?.message || response?.error;
      }
    } catch (err) {
      setGlobalNotification(
        'error',
        err || 'An error occurred while adding the plan(s) to this location.',
      );
    } finally {
      onClose?.();
      onUpdate?.();
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { value = '' } = event?.target || {};

    setSelectedPlans(value);
  };

  useEffect(() => {
    const locPlansList = locationPlans.map((plan) => plan?.plan_package_id);
    const filterdGroupPlans = groupPlans.filter((plan) =>
      !locPlansList.includes(plan?.plan_package_id),
    );

    setFilteredPlans(filterdGroupPlans);
  }, []);

  return (
    <CommonDialog
      open={true}
      handleClose={onClose}
      dialogTitle="Add Plans"
      primaryBtnTxt="Confirm"
      handlePrimaryButtonClick={handleConfirmClick}
      disabledPrimaryButton={!selectedPlans.length || isLoading}
      secondaryBtnTxt="Cancel"
      isLoading={isLoading}
      maxWidth={'xs'}
    >
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="plans-multi-select">Select Plans *</InputLabel>
            <Select
              autoWidth
              required
              labelId="plans-multi-select"
              id="plans-multiple-checkbox"
              multiple
              value={selectedPlans}
              label="Select Plans *"
              onChange={handleChange}
              renderValue={(selected) =>
                selected
                  .map((item) => {
                    const { plan_name = '' } =
                      filteredPlans?.find(
                        (plan) => plan.plan_package_id === item,
                      ) || {};

                    return plan_name;
                  })
                  .join(', ')
              }
            >
              {filteredPlans.map((plan) => (
                <MenuItem
                  key={plan?.plan_package_id}
                  value={plan?.plan_package_id}
                  style={{ backgroundColor: '#FFF' }}
                >
                  <CheckBox
                    checked={selectedPlans?.includes(plan?.plan_package_id)}
                  />
                  {plan?.plan_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </CommonDialog>
  );
};

AddPlansPopup.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  groupPlans: PropTypes.array,
  locationPlans: PropTypes.array,
};

export default AddPlansPopup;
