import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import CustomSwitch from "./CustomSwitch";

const AntSwitch = ({
  leftLabel,
  rightLabel,
  name,
  checked,
  onChange,
  disabled,
  className
}) => {
  const handleChange = (event) => onChange && onChange(event);

  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="body1">{leftLabel}</Typography>
        </Grid>
        <Grid item>
          <CustomSwitch disabled={disabled} checked={checked} onChange={handleChange} name={name} className={className} />
        </Grid>
        <Grid item>
          <Typography variant="body1">{rightLabel}</Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};

AntSwitch.propTypes = {
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default AntSwitch;
