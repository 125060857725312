import httpService from "../Api/http.service";

const { REACT_APP_API_BASE_URL } = process.env;
const getTrendsBaseUrl = () => `${REACT_APP_API_BASE_URL}/subv2/subscriptions/trends`;

const getUpcomingRenewals = (payload) =>
  httpService({
    url: getTrendsBaseUrl() + `/renewal-upcoming?${payload}`,
    method: "get",
  });

const getRetentionDetails = (payload) =>
  httpService({
    url: getTrendsBaseUrl() + `/retention?${payload}`,
    method: "get",
  });

const getStatsDetails = (payload) =>
  httpService({
    url: getTrendsBaseUrl() + `/stats?${payload}`,
    method: "get",
  });

const trendService = {
  getUpcomingRenewals,
  getRetentionDetails,
  getStatsDetails,
};

export default trendService;
