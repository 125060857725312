import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Grid,
  FormHelperText, 
  IconButton,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import {
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon
} from "@material-ui/icons";
import { Select } from "components";

const PmsDropdown = (props) => {
  const {
    isLoading,
    data: vendorsList = [],
    vendorName,
    onVendorSelect,
    vendorFirmname,
    onFirmnameChange,
    handleValidateFirmname,
    validateLoading,
    captureLocationId = false,
    pmsLocationId,
    onLocIdChange,
    handleValidateLocationId,
    validateLocationLoading,
    reqFields,
    disabled = false,
    children
  } = props;

  const renderEndAdornment = () => {

    if(validateLoading) {
      return (
        <IconButton size="small" data-testid="firm-loading-button">
          <CircularProgress size={20} color="primary" />
        </IconButton>
      )
    }
    else if(reqFields["vendor_firmname"]?.error) {
      return (
        <Tooltip title="Not a valid firm">
          <IconButton size="small" data-testid="firm-error-button">
            <ErrorIcon className="color_error" />
          </IconButton>
        </Tooltip>
      );
    }
    else if(reqFields["vendor_firmname"]?.isValid) {
      return (
        <Tooltip title="Successfully validated">
          <IconButton size="small" data-testid="firm-success-button">
            <CheckCircleIcon className="color_success" />
          </IconButton>
        </Tooltip>
      );
    }
    else {
      return (
        <Tooltip title="Click to validate">
          <IconButton size="small" onClick={handleValidateFirmname} data-testid="firm-validate-button">
            <CheckCircleIcon />
          </IconButton>
        </Tooltip>
      );
    }

  };

  const renderLocationEndAdornment = () => {

    if(validateLocationLoading) {
      return (
        <IconButton size="small" data-testid="location-loading-button">
          <CircularProgress size={20} color="primary" />
        </IconButton>
      )
    }
    else if(reqFields["pms_location_id"]?.error) {
      return (
        <Tooltip title="Not a valid location id">
          <IconButton size="small" data-testid="location-error-button">
            <ErrorIcon className="color_error" />
          </IconButton>
        </Tooltip>
      );
    }
    else if(reqFields["pms_location_id"]?.isValid) {
      return (
        <Tooltip title="Successfully validated">
          <IconButton size="small" data-testid="location-success-button">
            <CheckCircleIcon className="color_success" />
          </IconButton>
        </Tooltip>
      );
    }
    else {
      return (
        <Tooltip title="Click to validate">
          <IconButton size="small" onClick={handleValidateLocationId} data-testid="location-validate-button">
            <CheckCircleIcon />
          </IconButton>
        </Tooltip>
      );
    }

  };

  return (
    <Grid container spacing={2} alignItems="baseline">
      <Grid item xs={12} lg={4} className="d-flex">
        <FormControl
          fullWidth
          required
          variant="outlined"
          disabled={isLoading || disabled}
        >
          <InputLabel htmlFor="outlined-Group-native-simple">
            Vendors
          </InputLabel>
          <Select
            id="pms-select-vendor"
            label="Vendors"
            name="vendor_id"
            value={vendorName}
            onChange={onVendorSelect}
          >
            <MenuItem value="">Select Vendor</MenuItem>
            {
              vendorsList?.map((vendor, i) => (
                <MenuItem value={vendor} key={`${i}_${vendor}`} data-testid={`pms-state-select-item-${i}`}>
                  {vendor}
                </MenuItem>
              ))
            }
          </Select>
          {
            reqFields && reqFields["vendor_name"]?.text && reqFields["vendor_name"]?.error && (
              <FormHelperText className="color_error">
                {reqFields["vendor_name"]?.text}
              </FormHelperText>
            )
          }
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={4} className="d-flex">
      {
        vendorName &&
          <TextField
            fullWidth
            required
            name="firm_name"
            label="Firm Name"
            value={vendorFirmname}
            variant="outlined"
            placeholder="Firm name"
            onChange={onFirmnameChange}
            error={reqFields && reqFields["vendor_firmname"]?.error}
            helperText={reqFields && reqFields["vendor_firmname"]?.text}
            disabled={disabled || validateLoading}
            InputProps={{
              endAdornment: vendorFirmname && !disabled && renderEndAdornment()
            }}
            inputProps={{
              'data-testid': 'firm-name-input'
            }}
          />
      }
      </Grid>
      {
        captureLocationId && vendorName &&
        <Grid item xs={10} lg={3} className="d-flex">
          <TextField
            fullWidth
            required
            name="pms_location_id"
            label="Location Id"
            value={pmsLocationId}
            variant="outlined"
            placeholder="Location Id"
            onChange={onLocIdChange}
            error={reqFields && reqFields["pms_location_id"]?.error}
            helperText={reqFields && reqFields["pms_location_id"]?.text}
            disabled={disabled || isLoading}
            InputProps={{
              endAdornment: pmsLocationId && !disabled && renderLocationEndAdornment()
            }}
            inputProps={{
              'data-testid': 'nexhealth-location-id'
            }}
            InputLabelProps={{
              shrink: pmsLocationId ? true : false
            }}
          />
        </Grid>
      }
      { children }
    </Grid>
  );
};

export default PmsDropdown;
