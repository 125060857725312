import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dropzone: {
    backgroundColor: '#FFFFFF',
    border: '2px dashed rgb(179, 177, 177)',
    borderRadius: '5px',
    padding: '40px',
  },

  highlightedText: {
    color: '#2fb6f9',
    cursor: 'pointer',
  },
}));

export default useStyles;
