import httpService from "../Api/http.service";

const getFacilityList = (group_id, location_id) =>
  httpService({
    url: `/facilities?group_id=${group_id}&location_id=${location_id}`,
    method: "get",
  });

const facilityService = {
  getFacilityList,
};

export default facilityService;
