import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import {
  Visibility as VisibilityIcon,
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Clear as ClearIcon
} from "@material-ui/icons";
import { USER_SERVICES } from "Services";
import { TableListView } from "components";
import { Context } from "context";
import { ADD_NOTIFICATION } from "context/actions";
import { formatDate, getFileExtension, downloadFile } from "utils";
import ConfirmModal from "Views/Common/ConfirmModal";
import styles from './Documents.module.scss';

const Documents = (props) => {
  const { dispatch: globalDispatch } = useContext(Context);
  const {
    levelData: {
      partner_id,
      group_id,
      region_id,
      location_id
    } = {},
    settings: {
      setting_level,
      level_id,
      fee_structure: feeStructureKey,
      fee_structure_emp: feeStructureEmpKey,
      fees_summary: feesSummaryKey,
      fees_summary_display: feesSummaryDisplayKey
    } = {},
    maxFileSize = 6000000,
    onUpdate
  } = props;

  const [saveLoading, setSaveLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState({});
  const [pdfUrls, setPdfUrls] = useState({});
  const [xlUrls, setXlUrls] = useState({});
  const [feeFile, setFeeFile] = useState();
  const [feeEmpFile, setFeeEmpFile] = useState();
  const [feesSummaryFile, setFeesSummaryFile] = useState();
  const [feesSummaryDisplayFile, setFeesSummaryDisplayFile] = useState();
  const [uploadedFile, setUploadedFile] = useState({});
  const [levelDocument, setLevelDocument] = useState([]);

  const [deleteKey, setDeleteKey] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const feesSummaryDefaultKey = "feesSummaryDefault";

  const onDeleteAsset = async () => {
    if (!deleteKey) return;

    setDeleteLoading(true);
    const payload = { location_id };

    try {
      const response = await USER_SERVICES.deleteFile(group_id, deleteKey, payload);

      if (response?.type === 'success') {
        dispatchGlobalNotification("success", "File deleted successfully");
        onUpdate?.('Settings');
      } else {
        throw (response?.message || response?.error || "Error in deleting file");
      }
    } catch (err) {
      dispatchGlobalNotification("error", err);
    } finally {
      setDeleteKey();
      setDeleteLoading(false);
    }
  };

  const renderActionButtons = (actionData, rowIndex) => {
    const { key, fileKey, uploadFile, allowDelete = true } = actionData;

    // No existing file
    if (!{ ...pdfUrls, ...xlUrls }?.[fileKey]) {
      return (
        <>
          {renderUploadIcons(actionData, rowIndex)}
          {uploadFile && renderClearIcons(key, rowIndex)}
        </>
      );
    }

    return (
      <>
        <Tooltip arrow title="View">
          <IconButton
            size="small"
            className="mr-10"
            data-testid={`view-document-button-${rowIndex}`}
          >
            {viewLoading[key] ? (
              <CircularProgress size={16} className="theme_color_orange" />
            ) : (
              <VisibilityIcon
                className="theme_color_orange"
                fontSize="inherit"
              />
            )}
          </IconButton>
        </Tooltip>

        {uploadFile && renderClearIcons(key, rowIndex)}
        {!uploadFile && (
          <>
            {renderUploadIcons(actionData, rowIndex)}
            {allowDelete ? renderDeleteIcons(key, rowIndex) : <></>}
          </>
        )}
      </>
    );
  };

  const renderClearIcons = (key, rowId) => {
    return (
      <>
        <Tooltip arrow title="Clear">
          <IconButton className="mr-10" size="small" onClick={(evt) => {
              evt && evt.stopPropagation();
              clearUpload(key)
            }}
            data-testid={`clear-document-upload-button-${rowId}`}
          >
            <ClearIcon className="theme_color_orange" fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Save">
          <IconButton size="small" onClick={(evt) => {
              evt && evt.stopPropagation();
              !saveLoading && saveUpload();
            }}
            data-testid={`save-document-button-${rowId}`}
          >
            {
              saveLoading
                ? <CircularProgress size={16} className="theme_color_orange" />
                : <SaveIcon className="theme_color_orange" fontSize="inherit" />
            }
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const renderUploadIcons = (actionData, rowId) => {
    return (
      <Tooltip arrow title="Upload">
        <IconButton className="mr-10" size="small" component="label" onClick={(evt) => evt && evt.stopPropagation()} data-testid={`upload-document-button-${rowId}`}>
          <CloudUploadIcon className="theme_color_orange" fontSize="inherit" />
          <input
            type="file"
            hidden
            onChange={(e) => handleUpload(e, actionData)}
            onClick={(e) => {
              e.target.value = null;
            }}
            accept={actionData?.acceptedFormats}
          />
        </IconButton>
      </Tooltip>
    )
  };

  const renderDeleteIcons = (key, rowId) => {
    return (
      <Tooltip arrow title="Delete">
        <IconButton size="small" onClick={(evt) => {
            evt && evt.stopPropagation();

            setDeleteKey(key);
          }}
          data-testid={`delete-document-button-${rowId}`}
        >
          <DeleteIcon className="theme_color_orange" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    );
  };

  const commonCellProps = () => ({ className: styles.tableColumn });
  const tableColumns = [
    {
      name: "category",
      label: "Category",
      options: {
        sort: false,
        setCellHeaderProps: () => ({ className: styles.categoryHeader }),
        setCellProps: commonCellProps
      },
    },
    {
      name: "fileName",
      label: "File Name",
      options: {
        sort: false,
        setCellHeaderProps: () => ({ className: styles.nameHeader }),
        setCellProps: commonCellProps
      },
    },
    {
      name: "uploaded_on",
      label: "Uploaded on",
      options: {
        sort: false,
        setCellHeaderProps: () => ({ className: styles.dateHeader }),
        setCellProps: commonCellProps
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        sort: false,
        setCellProps: commonCellProps,
        customBodyRender: (actionData, { rowIndex }) => {
          const { fileKey } = actionData;

          return (
            <Grid
              container
              alignItems="center"
              className={styles.buttonContainer}
              key={fileKey}
            >
              {renderActionButtons(actionData, rowIndex)}
            </Grid>
          );
        }
      },
    },
  ];

  const FILE_NAME_MAPPING = {
    fee_structure: "Savings Summary PDF",
    fee_structure_emp: "Savings Summary PDF - Employee",
    fees_summary: "Backend Fee Schedule",
    fees_summary_display: "Savings Summary UI"
  };

  const initialTableData = [
    {
      category: FILE_NAME_MAPPING.fee_structure,
      fileName: feeStructureKey?.name || '-',
      uploaded_on: feeStructureKey?.updated_on
        ? formatDate(feeStructureKey?.updated_on * 1000)
        : '-',
      actions: {
        key: 'fee_structure',
        fileKey: feeStructureKey?.key,
        uploadFile: feeFile,
        acceptedFormats: ['.pdf']
      },
    },
    {
      category: FILE_NAME_MAPPING.fee_structure_emp,
      fileName: feeStructureEmpKey?.name || '-',
      uploaded_on: feeStructureEmpKey?.updated_on
        ? formatDate(feeStructureEmpKey?.updated_on * 1000)
        : '-',
      actions: {
        key: 'fee_structure_emp',
        fileKey: feeStructureEmpKey?.key,
        uploadFile: feeEmpFile,
        acceptedFormats: ['.pdf']
      },
    },
    {
      category: FILE_NAME_MAPPING.fees_summary,
      fileName: feesSummaryKey?.name || '-',
      uploaded_on: feesSummaryKey?.updated_on
        ? formatDate(feesSummaryKey?.updated_on * 1000)
        : '-',
      actions: {
        key: 'fees_summary',
        fileKey: feesSummaryKey?.key || feesSummaryDefaultKey,
        uploadFile: feesSummaryFile,
        allowDelete: false,
        acceptedFormats: ['.xlsm']
      },
    },
    {
      category: FILE_NAME_MAPPING.fees_summary_display,
      fileName: feesSummaryDisplayKey?.name || '-',
      uploaded_on: feesSummaryDisplayKey?.updated_on
        ? formatDate(feesSummaryDisplayKey?.updated_on * 1000)
        : '-',
      actions: {
        key: 'fees_summary_display',
        fileKey: feesSummaryDisplayKey?.key,
        uploadFile: feesSummaryDisplayFile,
        allowDelete: false,
        acceptedFormats: ['.xlsm']
      },
    },
  ];

  const tableData = initialTableData?.filter((item) =>
    levelDocument?.some((document) => document?.name === item?.actions?.key),
  );

  const getDocumentsList = async (setting_level, levelId) => {
    try {
      const response = await USER_SERVICES.getDocumentList(
        setting_level,
        levelId,
      );

      if (response?.type === 'success' && response?.data) {
        setLevelDocument(response?.data);
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpload = (event, data) => {
    let supportedFileFormat = 'XLSM';
    if (['fee_structure_emp', 'fee_structure'].includes(data?.key)) {
      supportedFileFormat = 'PDF';
    }
    const file = event.target.files[0];
    const fileExtension = getFileExtension(file.name);

    if(!data?.acceptedFormats.includes(fileExtension)) {
      dispatchGlobalNotification("error", `Unsupported file format. Supported format: ${supportedFileFormat}`);
      return;
    }

    if(file.size > maxFileSize) {
      dispatchGlobalNotification("error", 'File size should not be more than 6MB');
      return;
    }

    setUploadedFile({
      ...uploadedFile,
      [data.key]: file
    });

    onFileUpload(file, data.key);
  };

  const onFileUpload = (file, type) => {
    switch (type) {
      case "fee_structure":
        setFeeFile(file);
        break;
      case "fee_structure_emp":
        setFeeEmpFile(file);
        break;
      case "fees_summary":
        setFeesSummaryFile(file);
        break;
      case "fees_summary_display":
        setFeesSummaryDisplayFile(file);
        break;
      default:
        break;
    }
  };

  const clearUpload = (key) => {
    if (key) {
      key === "fee_structure" && setFeeFile();
      key === "fee_structure_emp" && setFeeEmpFile();
      key === "fees_summary" && setFeesSummaryFile();
      key === "fees_summary_display" && setFeesSummaryDisplayFile();
    }
    else {
      setFeeFile();
      setFeeEmpFile();
      setFeesSummaryFile();
      setFeesSummaryDisplayFile();
    }
  };

  const replaceFilename = (errorText) => {
    let replacedText = errorText;

    Object.keys(FILE_NAME_MAPPING).forEach((key) => {
      const regex = new RegExp('\\b' + key + '\\b', "g");

      replacedText = replacedText?.replace(regex, FILE_NAME_MAPPING?.[key]);
    });

    return replacedText;
  };

  const saveUpload = async () => {
    setSaveLoading(true);

    try {
      const fdata = new FormData();
      const payload = {
        location_id,
        region_id,
        group_id,
        partner_id
      };

      fdata.append(setting_level, JSON.stringify(payload));

      feeFile && fdata.append("fee_structure", feeFile);
      feeEmpFile && fdata.append("fee_structure_emp", feeEmpFile);
      feesSummaryFile && fdata.append("fees_summary", feesSummaryFile);
      feesSummaryDisplayFile && fdata.append("fees_summary_display", feesSummaryDisplayFile);

      const response = await USER_SERVICES.uploadFile(fdata, group_id);

      if (response?.type === 'success') {
        dispatchGlobalNotification('success', "File uploaded successfully");
        clearUpload();
        onUpdate && onUpdate('Settings');
      }
      else {
        throw (response?.message || response?.error);
      }
    }
    catch (error) {
      if (typeof error === "object") {
        let errorFiles = Object.values(error).reduce((errFiles, fileError) => {
          errFiles.push(replaceFilename(fileError) || "");

          return errFiles;
        }, []);

        let message = errorFiles.join(", ");
        if (errorFiles?.length > 1) {
          message = `Error while uploading one or more files - [${message}]`
        }

        dispatchGlobalNotification(
          "error",
          message
        );
      } else {
        dispatchGlobalNotification(
          "error",
          error || "Error while uploading the file"
        );
      }
    } finally {
      setSaveLoading(false);
    }
  };

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } }
    });

  const getPdfUrls = async () => {
    const getUrls = [];

    feeStructureKey?.key && getUrls.push(feeStructureKey?.key);
    feeStructureEmpKey?.key && getUrls.push(feeStructureEmpKey?.key);    

    if (getUrls.length) {
      const { data, type } = await USER_SERVICES.getAWSAccessURLs(getUrls);

      if (type === 'success') {
        setPdfUrls(data);
      }
    }
  };

  const setFeesSummaryUrl = () => {
    let fileKey = feesSummaryDefaultKey;
    let urlPath = `/groups/${group_id}/files/fees-summary`;

    if (feesSummaryKey) {
      fileKey = feesSummaryKey?.key;
    }
    setXlUrls((prevUrls) => ({ ...prevUrls, [fileKey]: urlPath }));
  }

  const setFeesSummaryDisplayUrl = () => {
    let fileKey = feesSummaryDefaultKey;
    let urlPath = `/groups/${group_id}/files/fees-summary-display`;

    if (feesSummaryDisplayKey) {
      fileKey = feesSummaryDisplayKey?.key;
    }
    setXlUrls((prevUrls) => ({ ...prevUrls, [fileKey]: urlPath }));
  }

  const onRowClick = (rowData, metaData) => {
    const { dataIndex = 0 } = metaData;
    let redirectUrl = "";

    if (dataIndex === 0 && feeStructureKey) {
      redirectUrl = pdfUrls[feeStructureKey?.key]
    }
    else if (dataIndex === 1 && feeStructureEmpKey) {
      redirectUrl = pdfUrls[feeStructureEmpKey?.key]
    }
    else if (dataIndex === 2) {
      downloadFeesSummary(feesSummaryKey, "fees_summary");
    } else if (dataIndex === 3) {
      downloadFeesSummary(feesSummaryDisplayKey, "fees_summary_display");
    }

    redirectUrl && window.open(redirectUrl, "_blank", "noopener noreferrer");
  };

  const downloadFeesSummary = async (data, type) => {
    if (viewLoading?.[type]) return;

    let feesSummaryUrlPath = xlUrls[data?.key || feesSummaryDefaultKey];
    let fName = data?.name || "fees-summary.xlsm";

    setViewLoading({ ...viewLoading, [type]: true });

    try {
      const response = await USER_SERVICES.getTemplate(feesSummaryUrlPath, { "partner_id": partner_id, [`${setting_level}_id`]: level_id });
      if (response?.type === "success" && response.data) {
        downloadFile(response.data, "application/octet-stream", fName);
      }
      else {
        console.log("Error fetching fees summary template");
      }
    }
    catch(e) {
      console.log( e || "Error fetching fees summary template");
    }
    finally {
      setViewLoading({ ...viewLoading, [type]: false });
    }
  }

  useEffect(() => {
    if (setting_level && level_id) {
      getDocumentsList(setting_level, level_id);
    }
  }, [setting_level, level_id]);

  useEffect(() => {
    getPdfUrls();
  }, [feeStructureKey, feeStructureEmpKey]);

  useEffect(() => {
    setFeesSummaryUrl();
  }, [feesSummaryKey]);

  useEffect(() => {
    setFeesSummaryDisplayUrl();
  }, [feesSummaryDisplayKey]);

  return (
    <div className={saveLoading ? 'disable-form' : ''}>
      <TableListView
        headerTitle="Documents"
        headerSection={true}
        tableData={tableData}
        tableOptions={{
          pagination: false,
          onRowClick
        }}
        tableColumns={tableColumns}
      />

      {
        Boolean(deleteKey) &&
        <ConfirmModal
          isOpen={true}
          closeModal={() => setDeleteKey()}
          emitConfirmEvent={onDeleteAsset}
          isLoading={deleteLoading}
          options={{
            title: `Delete ${FILE_NAME_MAPPING?.[deleteKey]}`,
            description: "Are you sure you want to delete?",
            confirmText: "Confirm",
            cancelText: "Cancel"
          }}
          customClass={deleteKey === 'fee_structure_emp' ? styles.smPaper : ''}
        />
      }
    </div>
  );
};

Documents.propTypes = {
  levelData: PropTypes.object,
  settings: PropTypes.object,
  maxFileSize: PropTypes.number,
  onUpdate: PropTypes.func,
};

export default Documents;
