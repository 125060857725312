import React from 'react';
import { Select as MuiSelect } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const Select = (props) => {
  const {
    inputProps,
    ...restProps
  } = props;

  return <MuiSelect
    {...restProps}
    IconComponent={KeyboardArrowDownIcon}
    inputProps={{
      ...inputProps,
      'data-testid': props?.id || props?.name || (props?.label)?.replace(/ /g,'-').toLowerCase() ||'select-dropdown'
    }}
  />
};

export default Select;
