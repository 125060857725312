import httpService from "../Api/http.service";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const getGeocode = (address) => {
  return httpService({
    url: `${baseURL}/mgt/geocode?address='${address}'`,
    method: "get",
  });
};
const userService = {
  getGeocode,
};

export default userService;
