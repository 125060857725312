import { makeStyles } from '@material-ui/core/styles';

const minHeight = 14;

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: theme.spacing(3),
    width: '66px',
    background: theme.palette.background.paper,
  },
  discountTabs: {
    minHeight,

    '& .MuiTabs-flexContainer': {
      padding: '1px',

      '& .MuiTab-root': {
        fontSize: '0.84rem',
        minWidth: 30,
        minHeight,
        padding: '0px',
      },
      '& .Mui-selected': {
        background: `
          linear-gradient(95.69deg, 
          ${theme.palette.primary.highlight} 4.34%, 
          ${theme.palette.primary.highlight2} 55.62%, 
          ${theme.palette.primary.highlight3} 91.79%)
        `,
        color: theme.palette.primary.contrastText,
        borderRadius: theme.spacing(3),
      },
      '& .MuiTab-wrapper': {
        textTransform: 'none',
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
}));

export default useStyles;
