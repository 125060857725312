import React, { useState, useEffect, useContext } from 'react';
import { fetchAuthSession } from "aws-amplify/auth";
import { Grid, Snackbar, Button, Typography } from "@material-ui/core";
import { LOGOUT } from 'context/actions';
import { Context } from "context";
import styles from './LogoutSession.module.scss';

let intervalCheck;

const LogoutSession = () => {
  const { dispatch } = useContext(Context);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    intervalCheck = setInterval(() => {
      const currentTime = new Date().getTime();
      const tokenValidity = localStorage.getItem('tokenValidity');

      if (((tokenValidity - (1000 * 60 * 5)) < currentTime)) { //5 minutes

        if (tokenValidity <= currentTime)
          handleLogOut();
        else
          handleOnIdle();
      }
    }, 1000);

    return () => {
      clearInterval(intervalCheck);
    }
  }, []);

  const handleOnIdle = () => {
    if (!showModal)
      setShowModal(true)
  };

  const handleLogOut = () => dispatch({ type: LOGOUT });

  const retainSession = () => {
    setShowModal(false);

    localStorage.setItem('tokenValidity', new Date().getTime() + (1000 * 60 * 60 * 5)); // 5 hours

    refreshToken();
  };

  const refreshToken = async () => {
    try {
      const authSession = await fetchAuthSession({ forceRefresh: true });
      const { idToken = {} } = authSession?.tokens ?? {};
      const jwtToken = idToken.toString() || '';

      if (jwtToken)
        localStorage.setItem(
          "subscribiliToken",
          jwtToken
        );
      else
        throw new Error();
    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  };

  return (
    <>
      {showModal && (
        <Snackbar open={showModal} className={styles.snackbar}>
          <Grid container className={styles.container} >
            <Grid item xs={12} sm={12} md={5}>
              <Typography variant="h6">
                Session Time Out
              </Typography>

              <Typography variant="body2">
                Your session will expire in 5 minutes
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={7} className={styles.buttons}>
              <Grid container justify="flex-end">
                <Grid item xs={4}>
                  <Button
                    size="small"
                    variant="contained"
                    disableElevation
                    className={styles.secondarybutton}
                    onClick={handleLogOut}
                    data-testid='session-logout-button'
                  >
                    Logout
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    className={styles.primarybutton}
                    onClick={retainSession}
                    data-testid='session-restore-button'
                  >
                    Restore Session
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Snackbar>
      )}
    </>
  );
}

export default LogoutSession;