import React from "react";
import { Link } from "@material-ui/core";

import { GridList, SectionWrapper } from "components";
import { formatDate } from "utils";

const OnboardingDetails = ({ name, details = {}, onUpdate }) => {
  const {
    account = {},
    settings = {}
  } = details;

  const values = [{
    title: 'Bank Details',
    value: account?.external_accounts?.data[account?.external_accounts?.data?.length - 1]?.bank_name
  },
  {
    title: 'Payout Schedule',
    value: account?.frequency
  },
  {
    title: 'Service Agreement Link',
    value: settings?.agreement_copy ? <Link href={settings?.agreement_copy} className='theme_color_orange' data-testid="service-agreement-link"> Click Here </Link> : '-'
  },
  {
    title: 'Service Agreement Date',
    value: account?.tos_acceptance?.date ? formatDate(account?.tos_acceptance?.date * 1000) : '-'
  }];
  
  return (
    <SectionWrapper
      title={`${name} Onboarding Details`}
    >
      <GridList values={values} />
    </SectionWrapper>
  );
};

export default OnboardingDetails;
