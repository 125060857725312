import React from "react";
import { Grid, Typography, Tooltip } from '@material-ui/core';
import InfoIcon from "@material-ui/icons/Info";
import PropTypes from "prop-types";

const GridList = ({ values, className = '' }) => {
  return (
    <Grid container spacing={3} alignItems="stretch" className={className}>
      {values.map(
        ({ title, value, isHidden, info = '' }, index) =>
          !isHidden && (
            <Grid key={index} item xs={12} sm={6}>
              <Grid container spacing={1} alignItems="center">
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  alignItems="center"
                  className="d-flex align-center"
                >
                  <Typography variant="body1" color="textSecondary">
                    {title}
                  </Typography>
                  {info && (
                    <Tooltip title={info}>
                      <InfoIcon
                        color="primary"
                        data-testid="heading-info-button"
                        fontSize="small"
                        className="ml-5 cursor-pointer"
                      />
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                  <Typography variant="body1">{value || '-'}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ),
      )}
    </Grid>
  );
};

GridList.propTypes = {
  values: PropTypes.array,
  className: PropTypes.string,
}

export default GridList;
