import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  IconButton,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import {
  Visibility as VisibilityIcon,
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Clear as ClearIcon
} from "@material-ui/icons";
import { USER_SERVICES } from "Services";
import { TableListView } from "components";
import { Context } from "context";
import { ADD_NOTIFICATION } from "context/actions";
import { formatDate, getFileExtension, getImageProperties } from "utils";
import ConfirmModal from "Views/Common/ConfirmModal";
import styles from './LogoDetails.module.scss';

const LogoDetails = (props) => {
  const { dispatch: globalDispatch } = useContext(Context);
  const {
    levelData: {
      partner_id,
      group_id,
      region_id,
      location_id
    } = {},
    settings: {
      setting_level,
      logo,
    } = {},
    acceptedFormats = ['.png'],
    maxFileSize = 1000000,
    onUpdate
  } = props;

  const [saveLoading, setSaveLoading] = useState(false);
  const [pdfUrls, setPdfUrls] = useState({});
  const [invoiceFile, setInvoiceFile] = useState();
  const [uploadedFile, setUploadedFile] = useState({});

  const [showPopup, setShowPopup] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onDeleteAsset = (key) => {
    setDeleteLoading(true);
    try {
      // Add API to remove the key
      setShowPopup(false);
    }
    catch (err) {
      console.log(err);
    }
    setDeleteLoading(false);
  };

  const renderClearIcons = (key, rowId) => {
    return (
      <>
        <Tooltip arrow title="Clear">
          <IconButton className="mr-10" size="small" onClick={(evt) => {
              evt && evt.stopPropagation();
              clearUpload(key);
            }}
            data-testid={`clear-upload-button-${rowId}`}
          >
            <ClearIcon fontSize="inherit" className="theme_color_orange" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Save">
          <IconButton size="small" onClick={(evt) => {
              evt && evt.stopPropagation()
              saveLoading !== key && saveUpload(key);
            }}
            data-testid={`save-upload-button-${rowId}`}
          >
            {
              saveLoading === key
                ? <CircularProgress size={16} className="theme_color_orange" />
                : <SaveIcon fontSize="inherit" className="theme_color_orange" />
            }
          </IconButton>
        </Tooltip>
      </>
    );
  };
  

  const renderUploadIcons = (actionData, rowId) => {
    return (
      <Tooltip arrow title="Upload logo in PNG format and resolution should be 200px X 64px">
        <IconButton className="mr-10" size="small" component="label" onClick={(evt) => evt && evt.stopPropagation()} data-testid={`upload-logo-button-${rowId}`}>
          <CloudUploadIcon fontSize="inherit" className="theme_color_orange" />
          <input
            type="file"
            hidden
            onChange={(e) => handleUpload(e, actionData)}
            onClick={(e) => {
              e.target.value = null;
            }}
            accept={acceptedFormats}
          />
        </IconButton>
      </Tooltip>
    )
  };

  const renderDeleteIcons = (key, rowId) => {
    return (
      <Tooltip arrow title="Delete">
        <IconButton size="small" onClick={(evt) => {
            evt && evt.stopPropagation()
            setShowPopup(key);
          }}
          data-testid={`delete-logo-button-${rowId}`}
        >
          <DeleteIcon className="theme_color_orange" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    );
  };

  const commonCellProps = () => ({ className: styles.tableColumn });
  const tableColumns = [
    {
      name: "category",
      label: "Category",
      options: {
        sort: false,
        setCellHeaderProps: () => ({ className: styles.categoryHeader }),
        setCellProps: commonCellProps
      },
    },
    {
      name: "fileName",
      label: "File Name",
      options: {
        sort: false,
        setCellHeaderProps: () => ({ className: styles.nameHeader }),
        setCellProps: commonCellProps
      },
    },
    {
      name: "uploaded_on",
      label: "Uploaded on",
      options: {
        sort: false,
        setCellHeaderProps: () => ({ className: styles.dateHeader }),
        setCellProps: commonCellProps
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        sort: false,
        setCellProps: commonCellProps,
        customBodyRender: (actionData, { rowIndex }) => {
          const { key, pdfKey, uploadFile } = actionData;

          return (
            <Grid container className={styles.buttonContainer} >
              {
                pdfUrls[pdfKey] ? (
                  <>
                    <Tooltip arrow title="View">
                      <IconButton className="mr-10" size="small" data-testid={`view-logo-button-${rowIndex}`}>
                        <VisibilityIcon fontSize="inherit" className="theme_color_orange" />
                      </IconButton>
                    </Tooltip>

                    {
                      uploadFile
                        ? renderClearIcons(key, rowIndex)
                        : (
                          <>
                            {renderUploadIcons(actionData, rowIndex)}
                            {renderDeleteIcons(key, rowIndex)}
                          </>
                        )
                    }
                  </>
                ) : (
                    <>
                      {renderUploadIcons(actionData, rowIndex)}
                      {uploadFile && renderClearIcons(key, rowIndex)}
                    </>
                  )
              }
            </Grid>
          )
        }
      },
    },
  ];

  const tableData = [
    {
      category: "Logo",
      fileName: logo?.name || '',
      uploaded_on: logo?.updated_on ? formatDate(logo?.updated_on * 1000) : '-',
      actions: {
        name: "Logo",
        key: "logo",
        pdfKey: logo?.key,
        uploadFile: invoiceFile
      }
    }
  ];

  const handleUpload = async (event, data) => {
    const file = event.target.files[0];
    const fileExtension = getFileExtension(file.name);
    try {
      const { height } = await getImageProperties(file);
      if (height !== 64)
        throw new Error(
          'Please upload a logo with a height restricted to 64px',
        );
    } catch (error) {
      dispatchGlobalNotification('error', error);
      return;
    }

    if (!acceptedFormats.includes(fileExtension)) {
      dispatchGlobalNotification('error', 'Unsupported file format');
      return;
    }

    if (file.size > maxFileSize) {
      dispatchGlobalNotification(
        'error',
        'File size should not be more than 1MB',
      );
      return;
    }

    setUploadedFile({
      ...uploadedFile,
      [data.key]: file,
    });

    onFileUpload(file, data.key);
  };

  const onFileUpload = (file, type) => {
    switch (type) {
      case "logo":
        setInvoiceFile(file);
        break;
      default:
        break;
    }
  };

  const clearUpload = (key) => {
    key === "logo" && setInvoiceFile();
  };

  const saveUpload = async (key) => {
    setSaveLoading(key);
    try {
      const fdata = new FormData();
      const payload = {
        location_id,
        region_id,
        group_id,
        partner_id
      };

      const settingsData = {
        logo: ""
      };

      fdata.append(setting_level, JSON.stringify(payload));
      fdata.append("setting", JSON.stringify(settingsData));

      invoiceFile && fdata.append("logo", invoiceFile);

      const response = await USER_SERVICES.uploadFile(fdata, group_id);

      if (response?.type === 'success') {
        dispatchGlobalNotification('success', "File uploaded successfully");
        clearUpload("logo");
        onUpdate && onUpdate('Settings');
      }
      else {
        throw (response?.message || response?.error);
      }
    }
    catch (error) {
      let message = error;
      typeof error !== 'string' && (message = '');

      dispatchGlobalNotification("error", message || `Error while uploading the file`);
    }
    setSaveLoading(false);
  };

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } }
    });

  const getPdfUrls = async () => {
    const getUrls = [];
    logo?.key && getUrls.push(logo?.key);

    if (getUrls.length) {
      const { data, type } = await USER_SERVICES.getAWSAccessURLs(getUrls);

      if (type === 'success') {
        setPdfUrls(data);
      }
    }
  };

  const onRowClick = (rowData, metaData) => {
    const { dataIndex = 0 } = metaData;
    let redirectUrl = "";

    if (dataIndex === 0 && logo) {
      redirectUrl = pdfUrls[logo?.key]
    }

    redirectUrl && window.open(redirectUrl, "_blank", "noopener noreferrer");
  };

  useEffect(() => {
    if (logo)
      getPdfUrls();
  }, [logo]);

  return (
    <>
      <TableListView
        headerTitle="Logo"
        headerSection={true}
        tableData={tableData}
        tableOptions={{
          pagination: false,
          onRowClick
        }}
        tableColumns={tableColumns}
      />

      {
        showPopup &&
        <ConfirmModal
          isOpen={Boolean(showPopup)}
          closeModal={() => setShowPopup(false)}
          emitConfirmEvent={() => onDeleteAsset(showPopup)}
          isLoading={deleteLoading}
          options={{
            title: "Delete Logo",
            description: "Are you sure you want to delete?",
            confirmText: "Confirm",
            cancelText: "Cancel"
          }}
        />
      }
    </>
  );
};

export default LogoDetails;
