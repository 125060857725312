import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Legend,
  Bar,
  XAxis,
  YAxis
} from "recharts";
import "./styles.scss";

const VerticalBarChart = (props) => {
  const { data, options = [] } = props;

  return (
    <ResponsiveContainer height={120}>
      <BarChart
        layout="vertical"
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        }}
        stackOffset="expand"
      >
        <XAxis hide type="number" />
        <YAxis hide type="category" />
        <Legend verticalAlign="top" height={50} align="right" iconType="circle"/>
        {
          options.length && options.map((barData) => {
            return <Bar key={barData.label} dataKey={barData.label} fill={barData.fill} stackId="a"></Bar>
          })
        }
      </BarChart>
    </ResponsiveContainer>
  );
}



export default VerticalBarChart;
