import React, { useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import {
  InputAdornment,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteIcon from '@material-ui/icons/Delete';

import { SectionHeader } from 'components';
import { PlansContext } from 'Views/Plans/context';
import { VENDOR_PAYOUT_UPDATED } from 'Views/Plans/context/actions';
import { MONTHS } from 'Views/Common/enum';
import styles from './VendorPayout.module.scss';

const VendorPayout = (props) => {
  const { plan = {}, planIndex } = props;
  const {
    dispatch,
  } = useContext(PlansContext);

  const { vendor_payout = {} } = plan;
  const { values: vendor_payout_values = [] } = vendor_payout;
  const tierId = `vendor-payout-grid-${planIndex + 1}`;

  const paymentData = {
    month: '',
    primary: 0,
    dependent: 0,
    cpt_code: '',
  };

  const dispatchCommissionChange = (updated_commission) =>
    dispatch({
      type: VENDOR_PAYOUT_UPDATED,
      payload: {
        vendor_payout: updated_commission,
        planIndex,
      },
    });

  const handleChange = (event, rowIndex, value) => {
    const clonedValues = _.cloneDeep(vendor_payout_values);
    clonedValues[rowIndex][event.target.name] = value || event.target.value;

    dispatchCommissionChange({
      values: clonedValues,
    });
  };

  const addPaymentInterval = () => {
    const clonedValues = _.cloneDeep(vendor_payout_values);
    clonedValues.push(paymentData);

    dispatchCommissionChange({
      values: clonedValues,
    });
  };

  const deletePaymentInterval = (evt, rowId) => {
    evt && evt.stopPropagation();

    const clonedValues = _.cloneDeep(vendor_payout_values);
    clonedValues.splice(rowId, 1);

    dispatchCommissionChange({
      values: clonedValues,
    });
  };

  const onBlur = (event, rowIndex) => {
    let value = Math.abs(+parseFloat(event.target.value).toFixed(2)) || 0;

    if (event.target.value != value) handleChange(event, rowIndex, value);
  };

  const handleFocus = (event) => event.target.select();

  const renderInputField =
    (name, disable = false) =>
    (value, { rowData, rowIndex }) =>
      (
        <TextField
          className={styles.inputField}
          variant="outlined"
          margin="dense"
          value={value || 0}
          name={name}
          disabled={disable} //|| Boolean(rowData[1])
          onChange={(event) => handleChange(event, rowIndex)}
          onFocus={handleFocus}
          onBlur={(event) => onBlur(event, rowIndex)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          inputProps={{
            type: 'number',
            min: 0,
            'data-testid': `vendor-payout-${rowIndex}-${name}`,
          }}
        />
      );

  const renderMonthSelect = (value, { rowIndex }) => (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel> Select Month </InputLabel>
      <Select
        id={`vendor-payout-select-month-${rowIndex}`}
        className={styles.selectField}
        name="month"
        label="Select Month"
        value={value}
        onChange={(event) => handleChange(event, rowIndex)}
      >
        {MONTHS?.map((_item, index) => (
          <MenuItem
            key={index}
            value={index + 1}
            data-testid={`vendor-payout-select-month-item-${rowIndex}-${index}`}
          >
            {`Month ${index + 1}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderActions = (_value, { rowIndex }) => {
    return (
      <IconButton
        aria-label="delete"
        onClick={(evt) => deletePaymentInterval(evt, rowIndex)}
        data-testid={`delete-cpt-button-${rowIndex}`}
        classes={{
          root: styles.deleteIconColor,
        }}
      >
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    );
  };

  const commonCellProps = () => ({className: styles.inputColumn});
  const tableColumns = [
    {
      name: 'month',
      label: 'Month',
      options: {
        customBodyRender: renderMonthSelect,
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'primary',
      label: 'Member Payout',
      options: {
        customBodyRender: renderInputField('primary', false),
        setCellProps: commonCellProps,
      },
    },
    {
      name: 'actions',
      label: ' Action ',
      options: {
        customBodyRender: renderActions,

      },
    },
  ];

  const options = {
    filter: false,
    sort: false,
    selectableRows: 'none',
    responsive: 'vertical',
    print: false,
    pagination: false,
    search: false,
    download: false,
    viewColumns: false,
    elevation: 0,
  };

  return (
    <>
      <SectionHeader
        title="Provider Payout Schedule"
        titleVariant="h6"
        titleClasses={["font-weight-400"]}
        titleComponent="h6"
        rightSection={
          <>
            {vendor_payout_values.length < 12 && (
              <Button
                variant="text"
                disableElevation
                color="secondary"
                onClick={addPaymentInterval}
                startIcon={<AddRoundedIcon />}
                data-testid="add-payment-interval"
              >
                Add Payment Interval
              </Button>
            )}
          </>
        }
      />

      <div id={tierId}>
        <MUIDataTable
          data={vendor_payout_values}
          columns={tableColumns}
          options={options}
        />
      </div>
    </>
  );
};

VendorPayout.propTypes = {
  plan: PropTypes.object.isRequired,
  planIndex: PropTypes.number.isRequired,
};

export default VendorPayout;
