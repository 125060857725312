const GROUP_CHANGED = 'GROUP_CHANGED';
const PLAN_UPDATED = 'PLAN_UPDATED';
const PRODUCT_INFO_UPDATED = 'PRODUCT_INFO_UPDATED';
const CPT_UPDATED = 'CPT_UPDATED';
const PLAN_DETAILS_UPDATED = 'PLAN_DETAILS_UPDATED';
const TIER_UPDATED = 'TIER_UPDATED';
const REVSHARE_UPDATED = 'REVSHARE_UPDATED';
const SUBSCRIBILI_COMMISSION_UPDATED = 'SUBSCRIBILI_COMMISSION_UPDATED';
const COMMISSION_UPDATED = 'COMMISSION_UPDATED';
const VENDOR_PAYOUT_UPDATED = 'VENDOR_PAYOUT_UPDATED';
const HIGHLIGHT_ERRORS = 'HIGHLIGHT_ERRORS';
const UPDATE_FIELDS_VALIDITY = 'UPDATE_FIELDS_VALIDITY';
const UPDATE_PRICE_VALIDITY = 'UPDATE_PRICE_VALIDITY';
const SAVING_PLAN = 'SAVING_PLAN';
const SAVE_ERROR = 'SAVE_ERROR';
const RESET_PLAN = 'RESET_PLAN';
const MEMBER_TIER_UPDATED = 'MEMBER_TIER_UPDATED';
const UPDATE_VALID_MEMBER_TO = 'UPDATE_VALID_MEMBER_TO';

// Plan Info
const PLAN_INFO_RECEIVED = 'PLAN_INFO_RECEIVED';
const PLANS_BY_EXCEL = 'PLANS_BY_EXCEL';

// Procedure Category Discounts
const UPDATE_CATEGORY_DISCOUNTS = 'UPDATE_CATEGORY_DISCOUNTS';
const RESET_CATEGORY_DISCOUNTS = 'RESET_CATEGORY_DISCOUNTS';

export {
  GROUP_CHANGED,
  PLAN_UPDATED,
  PRODUCT_INFO_UPDATED,
  PLANS_BY_EXCEL,
  CPT_UPDATED,
  PLAN_DETAILS_UPDATED,
  TIER_UPDATED,
  REVSHARE_UPDATED,
  SUBSCRIBILI_COMMISSION_UPDATED,
  COMMISSION_UPDATED,
  VENDOR_PAYOUT_UPDATED,
  HIGHLIGHT_ERRORS,
  UPDATE_FIELDS_VALIDITY,
  UPDATE_PRICE_VALIDITY,
  SAVING_PLAN,
  SAVE_ERROR,
  PLAN_INFO_RECEIVED,
  RESET_PLAN,
  UPDATE_CATEGORY_DISCOUNTS,
  RESET_CATEGORY_DISCOUNTS,
  MEMBER_TIER_UPDATED,
  UPDATE_VALID_MEMBER_TO,
};
