import httpService from "../Api/http.service";

const getPrivacyPolicy = () =>
  httpService({
    url: process.env.REACT_APP_WP_URL + "/pages/1166",
    method: "get",
  });

const getTermsConditions = () =>
  httpService({
    url: process.env.REACT_APP_WP_URL + "/pages/1185",
    method: "get",
  });

const getHipaaNotice = () =>
  httpService({
    url: process.env.REACT_APP_WP_URL + "/pages/1625",
    method: "get",
  });

const getDisclosureStatement = (type = 'recur') => {
  const getPageId = () => {
    switch (type) {
      case 'recur':
        return '1899';
      case 'flex':
        return '2063';
      default:
        return '1899';
    };
  };

  const pageId = getPageId();
 
  return httpService({
    url: process.env.REACT_APP_WP_URL + `/pages/${pageId}`,
    method: "get",
  });
}

const getPaperlessNotice = () =>
  httpService({
    url: process.env.REACT_APP_WP_URL + "/pages/1836",
    method: "get",
  });

const wpServices = {
  getPrivacyPolicy,
  getTermsConditions,
  getHipaaNotice,
  getDisclosureStatement,
  getPaperlessNotice,
};

export default wpServices;
