const getUserKeys = () => ({
  first_name: "",
  last_name: "",
  employee_id: "",
  country_code: "+1",
  phone: "",
  email: "",
  role: "",
  partner_id: "",
  group_id: "",
  region_id: "",
  location_id: "",
  cognito_username: "",
  user_level: "",
  permissions: [],
});

const requiredFields = {
  required: true,
  isValid: true,
  value: "",
  text: "",
  error: false,
  touched: false,
};

const getReqFields = () => ({
  first_name: { ...requiredFields },
  last_name: { ...requiredFields },
  phone: { ...requiredFields },
  email: { ...requiredFields },
  role: { ...requiredFields },
  // "employee_id": { required: true, isValid: true, value: '', text: '', error: false, touched: false },
  // cognito_username: {
  //   required: true,
  //   isValid: true,
  //   value: "",
  //   text: "",
  //   error: false,
  //   touched: false,
  // },
});

const getEditReqFields = () => ({
  full_name: {
    ...requiredFields,
    label: "Full Name",
  },
  employee_id: {
    ...requiredFields,
    label: "Employee ID",
    required: false,
  },
  phone: {
    ...requiredFields,
    label: "Phone",
  },
  email: {
    ...requiredFields,
    label: "Email",
  },
  role: {
    ...requiredFields,
    label: "Role",
  },
  partner_id: {
    ...requiredFields,
    label: "Partner",
  },
  group_id: {
    ...requiredFields,
    label: "Group",
  },
  region_id: {
    ...requiredFields,
    label: "Region",
  },
  location_id: {
    ...requiredFields,
    label: "Location",
  },
  permissions: {
    ...requiredFields,
    label: "Permissions",
    required: false,
  },
});

const getContactKeys = () => ({
  first_name: "",
  last_name: "",
  title: "",
  phone: "",
  contact_email: "",
  country_code: "+1",
  is_internal: true,
  date_of_birth: "",
  ssn_last_4: "",
  is_representative: false,
  is_ssn_provided: false,
  verification_document: {},
});

const UserUtils = {
  getUserKeys,
  requiredFields,
  getReqFields,
  getEditReqFields,
  getContactKeys,
};

export default UserUtils;
