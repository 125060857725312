import React from "react";
import clsx from "clsx";
import { Chip as MuiChip, Typography } from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";

import { FILTER_CHIPS_KEYMAPPING } from 'Views/Common/enum';
import styles from "./FilterChip.module.scss";

const FilterChip = (props) => {
  const { type, title, onDelete, className, chipProps = {} } = props;
  const chipType = FILTER_CHIPS_KEYMAPPING[type] || type;

  const display_label = (
    <Typography variant="body1">
      {type && <span className="text-secondary"> {chipType} | </span>}
      {title}
    </Typography>
  );

  return (
    <MuiChip
      variant="outlined"
      label={display_label}
      onDelete={onDelete}
      className={clsx(styles.filterChip, className)}
      deleteIcon={<ClearIcon className={styles.deleteIcon} />}
      {...chipProps}
    />
  );
};

export default FilterChip;
