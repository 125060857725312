import React from 'react';
import { Checkbox as MuiCheckBox } from '@material-ui/core';

const CheckBox = (props) => (
  <MuiCheckBox
    color='default'
    {...props}
  />
)

export default CheckBox;
