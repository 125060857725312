import React from 'react';
import PropTypes from "prop-types";
import {
  Grid,
  Tooltip,
  Typography,
  IconButton,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const Heading = (props) => {
  const { title, info, subTitle, titleClass = '' } = props;

  return (
    <Grid container spacing={0} alignItems="stretch">
      <Grid item xs={12} className="d-flex align-center">
        <Typography variant="h5" className={titleClass}> {title} </Typography>
        {info && (
          <Tooltip title={info}>
            <IconButton size='medium'>
              <InfoIcon color="primary" fontSize='small' data-testid="heading-info-button" />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          {subTitle}
        </Typography>
      </Grid>
    </Grid>
  )
};

Heading.propTypes = {
  title: PropTypes.string,
  titleClass: PropTypes.string,
  info: PropTypes.string,
  subTitle: PropTypes.string,
};

export default Heading;
