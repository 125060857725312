import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid, FormControlLabel, Typography, Link } from '@material-ui/core';

import { PaperlessModal, DisclosureModal } from "./components";
import { CheckBox } from "components";
import { TERMS_DETAILS_REPLACE_MACROS } from 'Views/Common/constants';
import wpServices from 'Services/wpService';
import { GROUP_SERVICES } from 'Services';
import { Context } from "context";
import { CONDITIONS_APPLY_UPDATE, CONDITIONS_APPLY_RESET } from "context/actions";

const SubscriberTerms = (props) => {
  const {
    type: termsType = 'recur',
    dataValues: {
      group_id: groupId
    } = {},
    modalView = false,
    showCancellationPolicy = true,
  } = props;

  const {
    state: {
      conditionsApply: {
        termsConditions = false,
        cancellationPolicy = false,
      } = {}
    },
    dispatch: globalDispatch,
  } = useContext(Context);

  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [disclosureLoading, setDisclosureLoading] = useState(true);
  const [disclosureContent, setDisclosureContent] = useState();
  const [updatedContent, setUpdatedContent] = useState();
  const [detailsLoading, setDetailsLoading] = useState(true);

  const isRecurProduct = termsType === 'recur';

  const openPopup = (type) => setIsOpenPopup(type);
  const closePopup = () => setIsOpenPopup(false);

  const setConditionsApply = (name, value) =>
    globalDispatch({
      type: CONDITIONS_APPLY_UPDATE,
      payload: {
        name,
        value,
      },
    });
  
  const resetConditionsApply = () =>
    globalDispatch({
      type: CONDITIONS_APPLY_RESET,
    }); 

  const updateDisclosureContent = (disclosureDetails) => {
    const {
      group_name = '',
      group_url = '',
      email: group_email,
      max_additional_members: group_members = 0,
      patient_website = '',
      industry = '',
      patient_support_email = ''
    } = disclosureDetails;

    let clonedContent = _.cloneDeep(disclosureContent);

    if (clonedContent?.title?.rendered) {
      clonedContent.title.rendered = clonedContent.title.rendered.replace(new RegExp(TERMS_DETAILS_REPLACE_MACROS.group_name, "g"), group_name);
    }

    if (clonedContent?.content?.rendered) {
      clonedContent.content.rendered = clonedContent.content.rendered
        .replace(new RegExp(TERMS_DETAILS_REPLACE_MACROS.group_name, "g"), group_name)
        .replace(new RegExp(TERMS_DETAILS_REPLACE_MACROS.group_url, "g"), group_url)
        .replace(new RegExp(TERMS_DETAILS_REPLACE_MACROS.group_email, "g"), group_email)
        .replace(new RegExp(TERMS_DETAILS_REPLACE_MACROS.group_members, "g"), group_members)
        .replace(new RegExp(TERMS_DETAILS_REPLACE_MACROS.group_patient_portal, "g"), patient_website)
        .replace(new RegExp(TERMS_DETAILS_REPLACE_MACROS.group_industry, "g"), industry.toLowerCase())
        .replace(new RegExp(TERMS_DETAILS_REPLACE_MACROS.subscribili_support_email, "g"), patient_support_email);
    }

    setUpdatedContent(clonedContent);
  };

  const getDisclosureDetails = async () => {
    if (!groupId || groupId === 'ALL_GROUPS') return;

    try {
      const response = await GROUP_SERVICES.getDisclosureDetails(groupId);
      if (response?.type === 'success' && response?.data) {
        updateDisclosureContent(response.data);
      }
      else {
        throw response;
      }
    }
    catch (err) {
      console.log(err);
    }
    setDetailsLoading(false);
  };

  useEffect(() => {
    disclosureContent && groupId && getDisclosureDetails();
  }, [groupId, disclosureContent]);

  const getDisclosureStatement = async () => {
    try {
      const response = await wpServices.getDisclosureStatement(termsType);
      if (response?.type === 'success' && response?.data) {
        setDisclosureContent(response.data);
      }
      else {
        throw response;
      }
    }
    catch (err) {
      console.log(err);
    }
    setDisclosureLoading(false);
  };

  useEffect(() => {
    getDisclosureStatement();

    return () => resetConditionsApply();
  }, []);

  const getLinkText = () => (
    <Link
      onClick={() => groupId && openPopup("View_Disclosure")}
      data-testid="checkout-disclosure-link"
    >
      <Typography
        className="cursor_pointer"
        component="span"
        color="secondary"
      >
        T&C
      </Typography>
    </Link>
  );

  const getTermsConditionsText = () => {
    if (isRecurProduct)
      return (
        <>
          Primary member reviewed and agreed to the subscription service’s{" "}
          {getLinkText()}, including the automatic renewal and cancellation
          policies.
        </>
      );

    return (
      <>
        Member has reviewed and accepted the {getLinkText()} as well as the
        cancellation policies.
      </>
    );
  };

  const paperlessText = isRecurProduct
    ? "Paperless communication has been enabled for your subscription."
    : "Paperless communication has been enabled for your order(s).";

  return (
    <>
      {showCancellationPolicy && (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <CheckBox
                checked={cancellationPolicy}
                onClick={() => setConditionsApply('cancellationPolicy', !cancellationPolicy)}
                name="cancellationPolicy"
                inputProps={{ "data-testid": `checkout-cancellation-checkbox` }}
                disabled={!groupId}
              />
            }
            label={
              <Typography variant="h6">
                Cancellation Policy
              </Typography>
            }
          />
          <Typography variant="body2">
            Services will be charged at full rates
            if subscription is cancelled within the subscription term. Refunds
            will be granted if the cancellation is made within the specified
            refund window and no services have been provided. Restrictions and
            exceptions may apply, please review{" "}
            <Link
              onClick={() => groupId && openPopup("View_Disclosure")}
              data-testid="checkout-disclosure-link"
            >
              <Typography
                className="cursor_pointer"
                component="span"
                color="secondary"
              >
                T&C
              </Typography>
            </Link>{" "}
            for details.
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <CheckBox
              checked={termsConditions}
              onClick={() => {
                termsConditions ? setConditionsApply('termsConditions', false) : openPopup("Disclosure");
              }}
              name="termsConditions"
              inputProps={{ "data-testid": `checkout-terms-checkbox` }}
              disabled={!groupId}
            />
          }
          label={
            <Typography variant="h6">
              Terms & Conditions
            </Typography>
          }
        />
        <Typography variant="body2">
          {getTermsConditionsText()}
        </Typography>
      </Grid>

      {!modalView && (
        <Typography variant="body2" component="span" className="mt-10 color_success">
          {/* <Link onClick={() => openPopup('Paperless')} className={clsx(style.cursor_pointer, "color_success")} data-testid='checkout-paperless-link'>
            Paperless {" "}
          </Link>{" "} */}
            🌱 {paperlessText}
        </Typography>
      )}

      {isOpenPopup === "Paperless" && (
        <PaperlessModal isOpen={true} closePopup={closePopup} />
      )}

      {(isOpenPopup === "Disclosure" || isOpenPopup === "View_Disclosure") && (
        <DisclosureModal
          isOpen={true}
          showButton={isOpenPopup === "Disclosure"}
          closePopup={closePopup}
          emitSuccessEvent={() => {
            closePopup();
            setConditionsApply('termsConditions', true);
          }}
          isLoading={disclosureLoading || detailsLoading}
          disclosureContent={updatedContent}
        />
      )}
    </>
  );
};

SubscriberTerms.propTypes = {
  type: PropTypes.string,
  dataValues: PropTypes.object,
  modalView: PropTypes.bool,
  showCancellationPolicy: PropTypes.bool,
};

export default SubscriberTerms;