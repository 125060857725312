import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { ReactComponent as UploadIcon } from "assets/images/upload-icon.svg";
import style from "./DragAndDropFiles.module.scss"

const DragAndDropFiles = ({maxSize = 2}) => (
  <Grid container spacing={2} className={style.dropzone}>
    <Grid item xs={12} className="d-flex align-center justify-content-center">
      <UploadIcon alt="upload icon"/>
    </Grid>
    <Grid item xs={12}>
      <Typography align="center" variant="h6">
        Drag and drop here or
      </Typography>
      <Typography align="center" variant="h6" className={style.highlightedText}>
        Browse
      </Typography>
    </Grid>
    <Grid item xs={12} className={style.guidelineText} >
      <Typography><span>&#42;</span>Max File Size: {maxSize}MB</Typography>
      <Typography><span>&#42;</span>To import marketing material, select a PDF File</Typography>
    </Grid>
  </Grid>
);

export default DragAndDropFiles;